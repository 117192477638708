.intro {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: height 1s 0.3s;
    min-height: 530px;
}

.intro_title,
.intro_subtitle {
    color: #fff;
    position: relative;
}

.intro_title {
    @include font-proximanova-light;
    font-size: 35px;
    margin-bottom: 5px;
}

.intro_subtitle {
    @include font-proximanova-semibold;
    margin-bottom: em(14.7px);
    font-size: em(30.7px);
}

.intro_content {
    box-sizing: border-box;
    padding-top: em(188px);
    padding-bottom: em(146px);
    text-align: center;
}

.intro_imgCredit {
    display: none;

    img {
        position: absolute;
        bottom: 30px;
        max-width: 165px;
        margin-left: 15px;
    }
}

.coverPhoto-formWrapper {
    display: inline-block;
    width: calc-percent(715px, 750px);
    max-width: 550px;
    border-radius: $formWrapper-borderRadius;
    box-shadow: 0em -14em 7.57143em 0.1em rgba(29, 30, 35, .4);
}

.coverPhoto-formContainer {
    box-sizing: border-box;
    padding: 2.8em 2.85714em;
    margin-bottom: 25px;
    border-radius: $formWrapper-borderRadius;
    background: #fff;
    text-align: left;
}

.coverPhoto-formErrors,
.coverPhoto-formSuccess {
    @include font-proximanova-regular;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    font-size: 13px;
}

.coverPhoto-formErrors {
    border: 1px solid #eba19f;
    color: #a74945;
    background-color: #EED9D8;
}

.coverPhoto-formSuccess {
    border: 1px solid #79b941;
    color: #466a00;
    background-color: #d3eac1;
}

// Breakpoints
@include breakpoint(phablet) {
    .intro {
        background-position: center center;
    }
    .intro_content {
        padding-top: 7.91429em;
        padding-bottom: em(146px);
    }
    .intro_title {
        // font-size: em(42.3px);
        font-size: 54px;
        margin-bottom: em(3px, 47px);
    }
    .intro_subtitle {
        font-size: em(24.3px);
        margin-bottom: em(44px, 27px);
    }
    .coverPhoto-formContainer {
        padding: 1.65714em 2.55714em;
    }
}
@include breakpoint(tablet) {
    .intro_subtitle {
        margin-bottom: 0.92963em;
        font-size: 24px;
    }
    .intro_imgCredit {
        display: block;
    }
}
@include breakpoint(desktop-hd) {
    .intro_content {
        padding-right: 0;
    }
}