.text-control {
    padding-bottom: 0;

    input {
        z-index: 1;
    }
}
.page_section_header .h1 {
    text-align: center;
}
.shop_name_link:hover {
    text-decoration: none;
}

.hero {
    background-size: cover !important;
    background-repeat: no-repeat;

    &__content {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
        -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
        text-align: center;
        background-size: cover;
    }
}


@media screen and (max-width: 450px) {
    .hero {
        background-position: -168px;
    }
}
