.page_header {
    background: #efefef;
    border: none;
    padding: 0px;
}

.tabs-content {
  padding: 0 !important;
  border: 0 !important;
}

.mobile-filter-overlay,
.mobile-filter-container {
	display: none;
}