/*====== Path Variables ======*/
$font-path-base: "../../../fonts/";


/*====== Global Variables ======*/
$base-width: 1683px;
$max-width: 1040px;

$base-font-size: 14px;


/*====== Colors ======*/

// Validation
$validationFailure-red: #d0021b;

// Buttons
$btnSuccess-color: #7ebd1e;
$btnFb-color: #435A94;
$btnNoFill-color: #f1f1f1;

// Footer
$footer-bgColor: #F0F0F0;
$footerNav-color: #333;

// BloomNation Style Guide 2016
$lightYellow: #fffbe8;
$darkYellow: #ddc237;
$darkPink: #d8247f;
$darkPink--hover: #b51f6d;
$teal: #37A0A5;
$teal--hover: #2B7176;
$success-dark: #92bd29;
$success-light: #f1ffc9;
$error-dark: #e75f0b;
$error-light: #ffebe5;
$logo-lime: #66bd66;
$logo-teal: #9bcfd2;
$logo-magenta: #c44c8b;

/*====== Elements ======*/

// Typography
$header_grey: #464646;

// Select
$select-width: 150px;
$select-font-size: 14px;
$select-color: #3d3d3d;
$select-color-border: #999999;
$select-border-radius: 4px;
$select-arrow-size: 12px;
$select-arrow-color: #333333;
$select-arrow-offset: 15px;

// Email Subscribe
$subscribe-bgColor: #d8227d;
$subscribeEmail-borderColor: transparent;
$subscribeBtn-color: #d8227d;
$subscribeForm-height: em(42px);

// Switcher
$switcher-bgColor: #fff;
$switcher-bgColor--active: #e56284;
$switcher-bgColor--hover: #fff;
$switcher-borderColor: #aaa;
$switcher-borderColor--active: #df4661;
$switcher-borderColor--hover: #df4661;
$switcher-textColor: #999;
$switcher-textColor--active: #fff;
$switcher-textColor--hover: #df4661;

// Form Wrapper
$formWrapper-borderRadius: 4px;
$account-form-button-color: #f36faf;
$account-form-button-color--hover: #f15fa6;

// Intro Elements
$intro_content-grey: #464646;
$intro_divider-lightgrey: #d7d7d7;

// Inputs
$input_border-lightgrey: #c7c7c7;
$input_text-grey: #9b9b9b;

// Top City
$topCityCard-height: 200px;
$topCityCard-marginBottom: 20px;


/* Homepage Elements */
// Default colors
$searchBtn-bgColor: #00b551;
$searchBtn-bgColor--hover: #03A24A;
// Valentine's Day colors
$searchBtn-valentinesBgColor: #e56284;
$searchBtn-valentinesBgColor--hover: #df4661;
// Mother's Day colors
$searchBtn-mothersBgColor: #B51F62;
$searchBtn-mothersBgColor--hover: #761341;
// Summer colors
$searchBtn-summerBgColor: #FA30A0;
$searchBtn-summerBgColor--hover: #FA30A0;
// Fall colors
$searchBtn-fallBgColor: #ed3557;
$searchBtn-fallBgColor--hover: #ed3557;
// Winter colors
$winterHomepage-pink: #d8247f;
$winterHomepage-pink--hover: #c91f75;
// Spring color
$searchBtn-springBgColor: #f57a6e;
$searchBtn-springBgColor-hover: #FB6C5E;
// Mothers Day Colors
$searchBtn-mothersBgColor: #EE5583;
$searchBtn-mothersBgColor-hover: #F64B7F;
// Summer colors 2016
$searchBtn-summer2016BgColor: #F370B0;
$searchBtn-summer2016BgColor-hover: #f263a9;
// Fall colors 2016
$searchBtn-fall2016BgColor: #C12E60;
$searchBtn-fall2016BgColor-hover: #bd275a;
// Valentine's Day 2017
$vday-2017-CTA-Color: #D31723;
$vday-2017-CTA-Color--hover: #b90713;
// Spring colors 2017
$searchBtn-spring2017BgColor: #D8247F;
$searchBtn-spring2017BgColor--hover: #b51f6d;
