.text-control input.error,
input.error {
    border: 1px solid $validationFailure-red !important;
    color: $validationFailure-red !important;
}

.text-control input.success,
input.success {
	// box-shadow: 0px 0px 5px 2px rgb(172, 244, 193);
}

.visuallyhidden {
    display: none;
}