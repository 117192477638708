#search-shop-form {
    position: relative;
}

.search-formContainer {
    display: inline-block;
    width: 100%;
    float: left;
    margin-bottom: 10px;

    #pac-input {
        font-size: 13px;
    }

    @include breakpoint(phablet) {
        width: 75%;
        margin: 0;
    }
}

.search-form_row {
    margin-bottom: em(34px);

    label {
        @include font-proximanova-regular;
        color: rgb(73, 80, 76);
        font-size: em(28px);
        display: block;
        margin: 0 0 1.02143em -0.07143em;
        cursor: text;
    }
}

.search-btn-wrapper {
    width: 100%;
    display: inline-block;
    float: left;

    @include breakpoint(phablet) {
        width: 23%;
        margin-left: 2%;
    }
}

.search-btn {
    background: $searchBtn-spring2017BgColor;
    border-color: $searchBtn-spring2017BgColor;
    font-size: 18px;
    width: 100%;
    margin-top: 0;
    padding: 13px 0;

    &:hover {
      background: $searchBtn-spring2017BgColor--hover;
      border-color: $searchBtn-spring2017BgColor--hover;
    }
}

.dots {
    font-size: 8px;
    position: relative;
    width: 4em;
    height: 1em;
    padding: 5.5px 0;
    margin: -1px auto 0;

    .dot {
        top: 0.95em;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        opacity: 0;

        &:nth-child(1) {
          left: 0;
        }
        &:nth-child(2) {
          left: 1.5em;
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          left: 3em;
          animation-delay: 0.5s;
        }
    }
}

// Google Automcomplete Widget
.pac-container {
    box-sizing: initial;
    border: 1px solid #c7c7c7;
    border-bottom: 2px solid #E12F95;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.31);
    border-radius: 0;
    margin-top: -2px;

    &:after {
        @include font-proximanova-regular;

        content: "Powered by Google";
        padding: 7px 7px;
        font-size: 12px;
        line-height: 1.6;
        color: #464646;
        background: none !important;
    }
}

.pac-item {
    padding: 14.5px 25px 14.5px 20px;
    line-height: 1.2;
    border-top: 1px solid #DCDCDC;
    color: #464646;

    &:hover,
    &.pac-item-selected {
        background: #f7f7f7;
    }
    &:first-of-type {
        border-top: 0;
    }
    &:last-of-type {
        border-bottom: 1px solid #dcdcdc;
    }
    .pac-icon {
        display: none;
    }
    .pac-item-query {
        font-size: 12px;
        color: #464646;
    }
}

@media (min-height: 321px) {
    .pac-item {
        font-size: 13px;

        .pac-item-query {
            font-size: 13px;
        }
    }
}
@include breakpoint(phablet) {
    .search-btn {
        font-size: 1.44286em;
    }
}
@include breakpoint(tablet) {
    .dots {
        padding: 9px 0;
    }
}