@import "../modules/footer-nav";
@import "../modules/top-cities";

#footer {
    color: $footerNav-color;

    .footer_contentWrapper {
        padding-top: em(30px);
        background: #F0F0F0;
    }

    .footer_content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: em(18px) em(34px) em(20px) em(34px);
        gap: 2rem;
    }

    @media screen and (min-width: 768px) {
        .footer_content {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 992px) {
        .footer_content {
            grid-template-columns: 30% repeat(4, 1fr);
        }
    }

    .footer_column {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 768px) {
        .footer_column--backToTop {
            grid-column: 2 / span 2;
            grid-row: 2;
            text-align: center;
        }
    }

    @media screen and (min-width: 992px) {
        .footer_column--backToTop {
            grid-column: initial;
            grid-row: initial;
            text-align: left;
        }
    }

    .footer_column--social {
        padding: 0;
    }

    .footer_column--backToTop {
        display: none;
    }
}

.copyright {
    padding: em(13px, 16px) 0px 20px;
    font-size: 13px;
    background: $footer-bgColor;
    text-align: center;
    position: relative;
    z-index: 1;
}

@include breakpoint(phablet) {
    #footer {
        .footer_column {
            margin-bottom: 0;
        }

        .footer_column--backToTop {
            display: inline-block;
        }
    }
}
