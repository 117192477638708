// Shame CSS //

// A place to throw in styles quickly, to be reorganized at a later date.
.validation-advice {
    clear: both;
    color: #d91a00 !important;
    font-size: 11px !important;
    padding: 3px 0 0 !important;
}

//Header
li.enterOnSite_option.enterOnSite_option--order {
	display: none;
}

//Top Cities
.topCityCard {
	border: 1px solid #ddd;
}
.topCityCard-linkWrapper {
	display: block;
}

//Product Detail Pages
.social-icons a img {
	width: 20%;
}
.order_modification .modification_price {
	font-weight: 500;
}
.order_delivery .delivery_details .change-zip {
    line-height: 2.1;
    font-size: 11px;
    padding: 0;
    margin: -1px 5px;
}
.zip-check .check-btn {
    padding: 4px 10px;
}
.order_submitBtn {
	padding-top: 14px;
    font-size: 1.24286em;
    line-height: 1.3;
    min-height: 3.1em;
}
.tab {
	font-weight: 500;
}

.subscribeForm_submit {
    padding-bottom: 8px;
    line-height: 1.2;
}

//Login and Password Pages
.coverPhoto-container--login,
.coverPhoto-container--password {
	width:400px;
}

// Breadcrumbs fixes for SRP
@media (max-width: 767px) {
  .breadcrumbs {
    display: none;
  }
}
@media (min-width: 768px) {
  .breadcrumbs {
    position: static;
    padding-bottom: 20px;

    li.crumb-filter::after {
      content: ": ";
    }
  }
}

@media (max-width: 1152px) {
	//Product Detail Page
	#page-details .tab-pane p {
		padding: 10px 20px 10px 20px;
	}
}

@media (max-width: 1099px) {
	//Login and Password Page
	.coverPhoto-container--login,
	.coverPhoto-container--password {
		margin-left: 25px;
	}
}

@media (max-width: 768px) {
	//Login and Signup Page
	.coverPhoto-container--login,
	.coverPhoto-container--signup,
	.coverPhoto-container--password {
		width: 80%;
		margin: 0 auto;
		max-width: 400px;
	}
	.coverPhoto-formWrapper--signup {
		width:100%;
		min-width: 0px;
	}
	.form-extLink,
	.coverPhoto-formErrors {
		font-size: 14px;
	}

    // Product
    .order_submitBtn {
        font-size: 16px;
    }
}

@media(max-width: 495px) {
	//Footer
	.subscribeForm_submit {
      padding: 13px 0 12px 0;
      font-size: 14px;
    }
}

@media(max-width: 420px) {
    .contacts_phone {
        font-size: 12px;
        margin-right: 5px;
        line-height: 1.2;
        margin-top: 5px;
        padding-bottom: 4px;
    }
}

//using in checkout, as review sidebar ajax loading indicator
.lightgray * {
    color: lightgray !important;
}

.modal.in .modal-dialog {
    z-index: 1041;
}

//BLOOM-1132
.filtersGroup .filter .filter_action {
    font-size: 12px;
    line-height: 1.53333em;
    color: #41858c;
    padding-left: 0.2em;
    margin-bottom: 0.53333em;
}

.filtersGroup .filter .filter_action a {
    color: #9E9E9E;
    border-bottom: 1px solid #E7E7E7;
    line-height: 8px;
    display: inline-block;
}

.filtersGroup .filter {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 6px;
    margin-bottom: 16px;
}

// BLOOM-2653
#store-rating-link {
  text-decoration: none;
  cursor: pointer;
}


//header filters - BLOOM-4008
.header-filter-title {
  display: inline-block;
  padding: 6px;
  border: 1px solid #726c71;
  color: #726c71;
  margin-top: 10px;
}

.header-filter-remove {
  display: inline-block;
  padding: 6px;
  border: 1px solid #726c71;
  margin-left: -4px;
  margin-right: 5px;
  margin-top: 10px;
}

.header-filter-remove a {
  color: #555;
  font-weight: bold;
}

.header-headline {
  font-size: 1.3em !important;
}

.header-filters-block {
  border-top: solid 1px #cdcdcd;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.page_header {
  padding-bottom: 5px;
}

@media(max-width: 767px) {
  .header-headline {
    display: block !important;
    font-size: 16px !important;
    text-align: center;
    margin: 10px 0 !important;
  }
}

@media(min-width: 1024px) {
  .grid-align {
    text-align: left !important;
  }
  .grid-margin {
    margin-right: 18px !important;
  }
}
