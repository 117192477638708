.subscribe {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    background: $subscribe-bgColor;
    color: #fff;
    padding: 20px 2.42857em;
    text-align: center;
}

.subscribe-bannerContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-basis: auto;
    flex-shrink: 1;
    justify-content: center;
}

.subscribeForm_submit,
.subscribe_title,
.subscribe_text {
    color: #fff;
}

.subscribe_title {
    @include inline-box;
    @include font-bodoni;
    padding: 5px 0;
    margin: 0 40px;
    font-size: 3em;
}

.subscribe_text {
    font-size: em(12px);
    line-height: em(14px, 12px);
    margin-bottom: em(17px, 12px);
}

.subscribeForm {
    padding: 5px 0;
    margin: 0 40px;
    display: inline-block;
    white-space: nowrap;
}

.subscribeForm_email {
    @include inline-box;
    @include font-proximanova-regular;

    box-sizing: border-box;
    height: $subscribeForm-height;
    width: 55%;
    padding: 14px;
    margin-right: 15px;
    border: 1px solid $subscribeEmail-borderColor;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.03);
}

.subscribeForm_submit {
    @include inline-box;
    @include font-proximanova-regular;
    @include reset-btn;
    
    width: 35%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #FFF;
    border-radius: 4px;
    background: $subscribeBtn-color;
    font-size: 1.5em;
    line-height: 1.4;
    padding-bottom: 5px;

    &.disabled, &[disabled] {
        background: #5AC6CC;
        color: #E7E7E7;
    }
}

.subscribeForm .subscribeForm_loader {
    display: none;
    font-size: 16pt;
    margin-left: -50px;
    position: absolute;
    color: #4B9FA5;
    margin-top: 10px;
    &.loading {
        display: inherit;
    }
}


@media (min-width: 496px) {
    .subscribeForm_email {
        width: 20em;
    }
    .subscribeForm_submit {
        font-size: 2.5em;
        width: 8em;
    }
}
@include breakpoint(phablet) {
    .subscribe_title {
        font-size: em(28px);
    }
    .subscribeForm_submit {
        font-size: em(21px);
    }
}
@media (min-width: 960px) {
    .subscribe {
        padding: 25px 2.42857em;
    }
}