.list_top {
    .pagination {
        display: none;
    }
}
.list_bottom {
    font-size: $base-font-size;
    padding: 20px 0.64286em 0;
}
.pagination {
    li {
        @include inline-box;
        @include font-proximanova-regular;
        font-size: 15px;
        line-height: em(51px, 24px);
        box-sizing: border-box;
        width: 35px;
        height: 33px;
        border: 1px solid #c5c4c2;
        border-radius: 4px;
        text-align: center;

        a {
            color: #b8b6b6;
            display: block;
            text-decoration: none;
        }

        &:hover {
            background: #60B8BE;
            border: 1px #469fa6 solid;

            a {
                color: #f2f2f2;
            }
        }

        &.active {
            background: #469fa6;
            border-color: #31959d;
            color: white;
        }

        &.active a {
            color: white;
        }

        &.next,
        &.previous {
            width: auto;

            a {
                padding: 0px em(18px, 24px);
            }
        }
    }
}

// Breakpoints
@include breakpoint(phablet) {
    .pagination {
        li {
            line-height: em(34px, 16px);
            width: 35px;
            height: 33px;

            &.next,
            &.previous {
                a {
                    padding: 0px em(18px, 16px);
                }
            }
        }
    }
}
