@include breakpoint(phablet) {
    #main,
    .headerWrapper {
        padding: 0px em(34px);
    }

    .headerWrapper {
        display: block;
        min-height: 200px;
        margin-bottom: 2em;
    }

    .headerBorder {
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0%, transparent 100%);
    }

    .page_header {
        z-index: 2;
        padding-top: 40px;
    }

    .page_header_title,
    .collapse-info p {
        color: #fff;
    }

    .page_header_title {
        font-size: 36px;
        margin-bottom: 0.98235em;
    }

    .storeList {
        .store.list-item {
            padding-bottom: 25px;
            margin-bottom: 30px;
            border-bottom: 1px solid #eee;


            .store_header {
                padding: 0 0.64286em 0;
            }

            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0px;
            }
        }
    }

    .icon-deliveryTruck {
        margin-right: 4px;
    }

	.breadcrumbs,
	.collapse-info,
	.store_header,
	.store_footer {
	    display: block;
	}

    .store_footer {
        .reviews-grid { 
            .review {
                width: 100%;
                margin: 0;
            }
        }
    }

	.otherProducts {
		display: inline-block;
	}

	.store {
		padding: 0px;
	    padding-bottom: em(22px);
	    border: none;
	    border-radius: 0px;
	    margin-bottom: em(27px);
	    width: 100%;
	    display: block;
	    border-bottom: 1px solid #9b9b9b;

	    &:last-child {
	        border-bottom: none;
	        margin-bottom: 0px;
	    }
	}

	.store_storeLink {
		padding-right: 0px;
		padding-left: 0px;

		width: calc-percent(302px, 1279px);
		text-align: center;

	}

    // Product Grid Styles
    .product.card {
        width: 25%;
        
        &:last-of-type {
            // Fixes leftover width spilling over
            // margin-left: 0;
            border: 10px solid red !important;
        }
        .product_florist {
            display: none;
        }
    }

    // Product Grid Footer
    .product-categories,
    #products_toolbar_filter,
    .products_toolbar {
        display: none;
    }
    .pagination {
        float: right;
    }
}
