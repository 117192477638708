@include breakpoint(mobile) {
    #sidebar,
    .page_header {
        display: none;
    }

    #main {
        margin-left: 0px;
    }

    // Product Grid Styles
    .product-grid {
        width: 98%;
        margin: 0 auto;

        // Product Grid Footer
        .pagination {
            text-align: center;
        }

        .notification {
            margin: 20px 0;
        }
    }

    // Product Grid Footer (SERP Specific)
    .products_toolbar,
    .list_top,
    .list_bottom {
        &::after {
            content: "";
            clear: right;
            display: table;
            width: 100%;
        }
    }
    .products_toolbar {
        // must reset font-scale
        font-size: 14px;
        padding: em(32px) em(15px) 0px em(15px);
        margin-bottom: em(17px);
    }
    #products_toolbar_filter {
        float: right;
        width: em(210px);
    }
    .products_toolbar_title {
        font-size: 1.22857em;
        line-height: 1.25;
        margin-top: -3px;

        .today {
            font-weight: bold;
        }
    }
    .product_alert {
        opacity: 1;
        transition: opacity .25s ease-in-out;
        position: absolute;
        bottom: 0;

        font-size: 12px;

        background-color: rgba(70,70,70,.8);
        padding-right: 1px;

        width: 100%;
        height: 30px;

        .product_alert_content {
            .product_alert_header {
                padding: 10px;
                color: #89C520;
                font-style: italic;
                font-weight: bold;
            }
            .product_alert_body {
                opacity: 0;
                color: #FFF;
            }
        }
    }

    .bloom-seo-header {
        text-transform: uppercase;
        line-height: 27px;
        color: #333;
    }

    .bloom-seo-content {
        color: #555;
        font-size: 14px;
        line-height: 22px;

        a {
            color: #41858c;
        }
    }

    .bloom-seo-bottom {
        margin-top: 3.57143em;
        padding: 0 2.42857em;

        @media only screen and (min-width: 768px) {
            padding: 0;
        }
    }

    .zipcode-value-input {
        color: #333;
        width: 110px;
        padding: 8px;
        font-size: 14px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    .delivery-zip-filter-btn {
        color: $darkPink;
        width: auto;
        padding: 8px 9px;
        font-size: 14px;
        background: transparent;
        border: 1px solid #d32375;
        border-radius: 4px;
        cursor: pointer;
    }

    .clear-filter-btn {
        cursor: pointer;
        width: 100%;
        max-width: 175px;
        padding: 10px;
        font-size: 14px;
        color: #333;
        background: transparent;
        border: 1px solid #333;
        border-radius: 3px;

        &:hover {
            background: #eee;
        }
    }

    #delivery-calendar-button {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .delivery-date-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;
    }

    .delivery-date-option {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 40%;
        text-align: center;
        padding: 9px 6px;
        border: 1px solid #ccc;
        border-radius: 3px;

        cursor: pointer;
        font-size: 13px;
        line-height: 1.2;

        &:hover {
            border-color: #41858c;

            svg path {
                fill: $teal--hover;
            }
        }

        &__svg {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            margin: 0 auto 9px auto;
        }

        svg {
            width: 32px;
            height: 32px;
            margin: 0 auto;

            path {
                fill: #777;
            }
        }

        &--active {
            font-weight: bold;
            color: #555;
            border-bottom-color: #FFF;

            &:hover {
                border-color: #ccc;
                border-bottom-color: #FFF;
            }
        }

        &--cal-open {
            z-index: 1000;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .datepickr-wrapper {
        width: 100%;
    }

    .datepickr-calendar {
        position: relative;
        width: 195px;
        padding: 2px;
        border-radius: 3px;
        margin-top: -1px;

        table {
            width: 100%;
        }

        thead {
            tr {
                font-weight: bold;
            }
        }

        .datepickr-current-month {
            font-size: 13px;
        }

        .today {
            font-weight: bold;
        }

        .datepickr-months {
            font-size: 100%;
            font-weight: bold;

            span {
                padding: 0.5em .5em 0 .5em;
            }
        }

        .datepickr-day {
            padding: 3px;
            font-size: 12px;
            border-radius: 1px;

            &:hover {
                background: $darkPink;
                color: #fff;
                font-weight: bold;
            }
        }
    }

    .same-day-no-results {
        h2 {
            font-size: 22px;
        }

        .no-arrangements-image {
            img {
                margin: 0 auto;
                height: 125px;
                width: 125px;
                border: 1px solid #cfcfcf;
                border-radius: 50%;
            }
        }

        .try-different-zip {
            margin: 20px auto;
            padding: 10px 25px;
            background: #f7f7f7;

            p {
                font-size: 16px;
                color: #333;
            }
        }

        .calendar-header {
            padding: 7px;
            background: #fff;
            font-weight: bold;
            border: 1px solid #ccc;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            span {
                margin-left: 5px;
            }

            svg {
                vertical-align: middle;
                margin-top: -3px;
                height: 20px;
                width: 20px;;
            }

            svg path {
                fill: #555;
            }
        }

        .clear-filter-btn {
            display: block;
            margin: 20px auto;
        }

        .datepickr-wrapper {
            width: auto;
            margin: 0 auto;
        }

        .datepickr-calendar {
            width: 220px;
            padding: 10px;
        }

        .datepickr-day  {
            padding: 5px;
            font-size: 14px;
        };
    }
}
