.order {
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.order_header {
    display: none;
}

.order_modification {
    background: #f5f5f5;
    padding: em(21px) em(15.19994px) em(22.6px) em(15.19994px);
    margin-bottom: em(18px);

    label {
        border-radius: 3px;
    }
    .switcher {
        margin-bottom: em(5px);
    }
    .modification_price {
        @include font-proximanova-semibold;
        font-size: em(54px);
        color: #666;
        text-align: center;
        margin-bottom: em(17px, 54px);
        transition: color .35s ease;

        &.isChanged {
            color: #00B551;
            transition: color .35s ease;
        }
    }
    .holidayPricingAlert--inline {
        display: none;
        padding-bottom: 10px;
        text-align: center;
        font-size: 11.75px;
        color: #d84c76;

        &.isActive {
            display: block;
        }
    }
    .modification_desc {
        @include font-proximanova-regular;
        font-size: em(25.9px);
        color: #666;
        margin: 0.5em 0.1875em 0;
        line-height: 1.2;

        li {
            display: none;
        }

        .active {
            display: block;
        }

        &.isChanged {
            color: #00B551;
            transition: color .35s ease;
        }
    }
}

.order_delivery {
    padding: 0px em(15.19994px);
    margin-bottom: em(20px);

    .switcher_option {

        .weekday,
        .date {
            display: block;
            text-transform: uppercase;
        }
        .weekday {
            @include font-proximanova-regular;
        }
        .date {
            text-transform: capitalize;
        }
    }
    .switcher_option_wrapper {
        font-size: em(23.8px);
    }

    .delivery_details {
        @include font-proximanova-regular;
        font-size: em(24.5px);
        line-height: em(31px, 17px);
        color: #999;
        padding-bottom: em(29px, 17px);
        margin-bottom: em(22px, 17px);
        border-bottom: 1px solid #ccc;

        #delivery-fee-widget .delivery-fee-wrapper span {
            vertical-align: middle;
            line-height: 30px;
            font-size: 12px;
        }

        #delivery-fee-widget .add-zip {
            height: 30px;
            box-sizing: border-box;
            line-height: 30px;
            padding: 0;
            margin: 0 0 0 10px;
            border: 0;
        }

        #delivery-fee-widget {
            font-size: em(32px, 17px);
        }

        a {
            display: inline-block;
            margin-top: 2px;
            margin-left: 5px;
            color: #d8227d;
        }
    }
    .datepickr-wrapper {
        // Fixes cal from covering Order Now button
        height: auto;
    }
    .datepickr-calendar {
        right: 0;
        bottom: 8.5em;
        left: 0;
        padding: 0;
        margin: 0 auto;
    }
}

.zip-check {
    display: none;
    vertical-align: top;
    margin-left: 10px;

    input {
        padding: 7px 6px 8px;
        width: 80px;
        vertical-align: top;
        border: 1px solid #aaaaaa;
        border-radius: 2px;
    }

    .check-btn {
        display: inline-block;
        border: 1px solid #d8227d;
        color: #d8227d;
        padding: 0px 10px;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background: #d8227d;
            color: white;
        }
    }
}

.change-zip {
    vertical-align: top;
}

.delivery-fee-wrapper {
    display: inline;

    span {
        display: inline-block;
    }
}
.invalid-zip-error {
    display: none;
    font-size: 12px;
    text-align: center;
    color: red;
}

.delivery_details span {
    vertical-align: top;
    line-height: 2.1;
}

.order_footer {
    padding: 0 em(15.19994px);
}

.order_submitBtn {
    width: 100%;
    min-height: 4em;
    margin-bottom: 1em;
    padding: em(16px, 26px) em(8px, 26px) em(18px, 26px);
    border-radius: 4px;
    font-size: em(28.8px);
    text-transform: uppercase;
    background: #00b551;
    border: solid 1px #00b551;

    &-unavailable {
        color: #fff;
        background: #03A24A;
    }

    &:hover {
        color: #fff;
        background: #03A24A;
    }
}

.order_delivery-selectDateWarning {
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
}

#delivery-timer-widget {
    font-family: 'ProximaNova', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #666;
    span:nth-child(1) {
        color: #89C520;
    }
    i {
        margin-right: 5px;
    }
    span span {
        font-weight: 800;
    }
}

#reviewStep .delivery-info-block a {
    display: inline-block;
}
#reviewStep .delivery-info-block a .datepickr-wrapper {
    display: block;
}
#reviewStep .delivery-info-block a #datepickr {
    font-size: 0;
}

@media (min-width: 568px) {
    .order_delivery .switcher_option,
    #switcher_option--calendar {
        width: calc-percent(142px, 600px);
    }
    .order_delivery .datepickr-calendar {
        left: auto;
        bottom: 5em;
        overflow: show;
    }
}
@include breakpoint(phablet) {
    .order_header {
        display: block;
        padding: em(16px) em(10px);

        .product_name {
            font-size: em(25px);
            text-align: center;
        }
    }

    .order_modification {
        padding: em(16px) em(22px) em(19px) em(22px);

        .modification_price {
            font-size: em(33px);
        }

        .modification_desc {
            font-size: em(11px);
            margin: 0;
        }
    }

    .order_delivery {
        padding: 0 em(22px);
        margin-bottom: em(18px);
    }

    .order_footer {
        padding: 0 em(22px) em(22px);
    }

    .order_submitBtn {
        font-size: em(16px);
        padding: em(11px, 16px) em(5px, 16px) em(12px, 16px) em(5px, 16px);
        min-height: 3.5em;
        margin-bottom: 0;
        padding: em(16px, 26px) em(8px, 26px) em(18px, 26px);
    }
    .order_delivery .switcher_option_wrapper {
        font-size: em(14px);
    }
    .order_delivery .switcher_option_wrapper.calendar {
        font-size: .91em;
    }
    .order_modification .modification_desc,
    .order_delivery .delivery_details {
        font-size: em(11.9px);
        line-height: 28px;
    }
    .delivery_details {
        padding-bottom: em(18.94116px);
        margin-bottom: em(23.05884px);
    }
    .delivery_details .pickup-only i.pickup-only-icon {
        background: url(../../../images/icon-pickup.svg) 0 0 no-repeat;
        display: inline-block;
        background-size: 100%;
        width: 18px;
        height: 24px;
        top: 8px;
        position: relative;
    }
    .delivery_details .pickup-only .pickup-only-label {
        margin-left: 10px;
        margin-top: 8px;
        position: absolute;
    }
    .order_delivery .delivery_details a {
        margin-top: -2px;
    }
    .order_delivery .datepickr-calendar {
        // top: -24.5em;
    }
}
@include breakpoint(tablet) {
    .order_delivery .switcher_option_wrapper.calendar {
        font-size: 1em;
    }
}
// Product Alert Styling:
@include breakpoint(mobile) {
    .product_alert_container {
        color: #666;
        margin: 20px 0 25px 0;
        font-size: 13px;
        p {
            text-align: center;
            span {
                font-weight: bold;
                font-style: italic;
            }
        }
    }
}
@include breakpoint(phablet) {
    .product_alert_container {
        font-size: 14px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
    .product_alert_container {
        p {
            span {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}
// End Product Alert Styling

// Choose product to order dialog styling
#chooseProductToOrderDialogWrapper{
    #chooseProductToOrderDialog{
        &.modal{
            overflow: hidden;
            .close {
                display: none;
                opacity: 0.7;
                font-size: 30px;
                margin-top: -8px;
                margin-right: -8px;
                font-weight: 100;
                &:hover{
                    opacity: 1;
                }
                @media (max-width: 420px) {
                    display: block;
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    z-index: 150;
                }
            }
        }
        .modal-dialog {
            width: 743px;
            @media (max-width: 767px) {
                width: 100%;
                margin: 0;
                position: fixed;
                bottom: 60px
            }
            @media (max-width: 420px) {
                position: relative;
                margin-top: 45px;
                margin-bottom: 55px;
                bottom: auto;
            }
        }
        .modal-content-inner{
            margin-top: 0;
            overflow: hidden;
            height: auto;
            @media (max-width: 420px) {
                height: 100vh;
                overflow-y: scroll;
                margin-top: 27px;
                padding-bottom: 130px;
            }
        }

        .modal-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.01em;
            color: rgba(9, 1, 28, 0.87);
        }

        .modal-header{
            padding: 15px 25px 8px 52px;
            border: none;
            @media (max-width: 767px) {
                padding: 15px 15px 0 3vw;
            }
            @media (max-width: 420px) {
                padding: 0px 15px 0 3vw;
            }
            .close {
                display: block;
                @media (max-width: 420px) {
                    display: none;
                }
            }
        }
        .modal-body{
            padding: 0 51px 20px;
            @media (max-width: 767px) {
                padding: 8px 3vw 20px 3vw;
                .left-product{
                    padding-right: 1.5vw;
                    .selected-product-title{
                        left: 1.5vw;
                    }
                }
                .right-product{
                    padding-left: 1.5vw;
                    .selected-product-title{
                        left: -1.5vw;
                    }
                }
            }
            @media (max-width: 420px) {
                .products-cols {
                    margin-left: 0;
                    margin-right: 0;
                }
                .left-product, .right-product{
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            .main-product-container {
                .main-product-order-now, .main-product-update-type {
                    @media (max-width: 767px) {
                        padding: 8px 10px;
                        margin: 0;
                    }
                }
                .main-product-name {
                    text-align: left;
                    @media (max-width: 767px) {
                        padding: 8px 0 0 10px;
                        margin: 0;
                        width: 56%;
                    }
                }
                .main-product-price {
                    text-align: right;
                    @media (max-width: 767px) {
                        padding: 8px 10px 0 0;
                        margin: 0;
                        width: 44%;
                    }
                }
            }
        }

        .modal-footer{
            padding: 0 51px 19px;
            margin-top: 0;
            border-top: none;
            .popup-cancel{
                color: #000000;
                opacity: 0.7;
                &:hover{
                    opacity: 1;
                }
            }
            @media (max-width: 767px) {
                padding: 11px 3vw;
            }
            @media (max-width: 421px) {
                padding: 11px 3vw;
            }
        }
    }

    .btn {
        @media (max-width: 767px) {
            &.popup-order-now, &.popup-cancel{
                padding: 10px 20px;
            }
        }
        @media (max-width: 420px) {
            &.popup-order-now {
                width: 75%;
            }
            &.popup-cancel {
                padding: 10px;
            }

        }
    }

    .modal-footer-mobile{
        display: none;
        position: fixed;
        width: 100%;
        bottom:0;
        left: 0;
        background: #FFFFFF;
        z-index: 1050;
        box-shadow: 0 -4px 4px rgba(171, 171, 171, 0.25);
    }
    @media (max-width: 767px) {
        .modal-footer.modal-footer-mobile{
            display: block;
        }
        .modal-footer{
            display: none;
        }
    }

    @include breakpoint(phablet) {
        .vertical-alignment-helper {
            display: table;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }

        .vertical-align-center {
            /* To center vertically */
            display: table-cell;
            vertical-align: middle;
            pointer-events: none;
        }

        .modal-content {
            /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
            width: inherit;
            max-width: inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */
            height: inherit;
            /* To center horizontally */
            margin: 0 auto;
            pointer-events: all;
        }
    }
}

.main-product-container {
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    padding: 1px;

    .main-product-image{
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .main-product-name-and-price {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #454545;
    }

    .main-product-name {
        padding-left: 20px;
        margin-top: 15px;
        float: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 60%;
    }

    .main-product-price {
        padding-right: 20px;
        margin-top: 15px;
        float: right;
        text-align: right;
        width: 40%;
    }

    .main-product-update-type {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #454545;
        margin: 8px 20px 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
    }

    .main-product-order-now {
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        letter-spacing: 0.01em;
        color: #454545;
        margin: 15px 20px;
    }

    .main-product-order-now{
        .get-it-on-text {
            font-weight: 700 !important;
        }
        .truck-icon-cell {
            vertical-align: middle;
        }
        .get-it-on-cell {
            padding-left: 8px;
            .delivery-date {
                white-space: nowrap;
            }
        }
    }

    .tooltip-inner {
        color: #181414;
        background-color: #cdcfd4;
        font-weight: 200;
    }
    .tooltip.top .tooltip-arrow,
    .tooltip.top-left .tooltip-arrow,
    .tooltip.top-right .tooltip-arrow {
        border-top-color: #cdcfd4;
    }
}

#chooseProductToOrderDialog .main-product-container {
    &:hover, &.active-product{
        border: 2px solid #D8247F;
        padding: 0;
    }
    @media (max-width: 767px) {
        &:hover{
            &.active-product{
                border: 2px solid #D8247F;
                padding: 0;
            }
            border: 1px solid #CDCDCD;
            padding: 1px;
        }
    }
}
#chooseProductToOrderDialog .crossell-info {
    padding-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

#chooseProductToOrderDialog .right-product .main-product-container {
    border-radius: 4px;
}

#chooseProductToOrderDialog .selected-product-title {
    background: #FFFFFF;
    border: 2px solid #D8247F;
    border-radius: 27px;
    text-align: center;
    width: 105px;
    height: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    margin: auto;
    z-index: 100;
}

#chooseProductToOrderDialog .selected-product-title .check-selected {
    color:  #D8247F;
}

#chooseProductToOrderDialog .block-copy {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: rgba(9, 1, 28, 0.87);
    margin-bottom: 25px;
}

#chooseProductToOrderDialog .delivery-date-in-past {
    color: #FF0000;
    font-weight: 700;
}

@media (max-width: 767px) {
    #chooseProductToOrderDialog img {
        width: 100%;
    }

    #chooseProductToOrderDialog .left-product {
        margin-bottom: 0;
    }

    #chooseProductToOrderDialog .col-xs-12 {
        width: 50%;
    }
}

@media (max-width: 420px) {
    #chooseProductToOrderDialog .col-xs-12 {
        width: 100%;
    }

    #chooseProductToOrderDialog .left-product {
        margin-bottom: 30px;
    }
}
// End choose product to order dialog styling
