.page_header {
    border: none;
    text-align: center;
}

.benefits {
    margin-top: 2.3em;
    text-transform: uppercase;
    text-align: center;
    color: #777;
    letter-spacing: .1em;

    &__card {
        display: inline-block;
        font-size: .8em;
        vertical-align: bottom;
        height: 15em;
        margin: .8em;
    }

    &__icon {
        max-height: 100px;
        padding-bottom: .5em;
        display: inline-block;
    }
}

@media (max-width: 768px) {
    .benefits {
        &__card {
            height: 120px;
        }

        p, h4 {
            font-size: 12px !important;
        }
    }
}

.details_photo {
    img {
        width: 100%;
        height: auto;
    }
}

.product_tabs {
    border: 1px solid #efefef;

    .tab-pane {
        box-sizing: border-box;
    }
}


.product_name {
    color: #666;
    line-height: 1.25;
}

.aside {
    .store {
        background: #f5f5f5;
        overflow: hidden;
    }

    .owner_photo {
        float: left;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .store_info {
        @include font-proximanova-regular;
        overflow: hidden;
    }

    .store_name {
        display: inline-block;
        color: #666;
    }

    .store_florist {
        color: #8e8e8e;
        &.no_review {
            font-size: .8em;
        }
    }

    .storeRating {
        margin-bottom: 1em;
        
        .reviewCount {
            margin-top: 1px;
            font-size: 14px;
        }
    }

    .store_rating {
        @include font-proximanova-semibold;

        img {
            @include inline-box;
            vertical-align: middle;
        }

        .rating_reviews {
            @include inline-box;
            vertical-align: middle;
            color: #666;
        }
    }

    .store_link {
        @include font-proximanova-semibold;
        display: block;
        max-width: 13.75em;
        text-align: center;
        text-transform: uppercase;
        color: #a6a6a6;
        border: 1px solid #a6a6a6;
        box-sizing: border-box;
        overflow: hidden;
        text-decoration: none;

        &:hover {
            color: #fff;
            background: #a6a6a6;
        }
    }
}
