#page-details {
	padding: 0 em(15px);

	@import "all";
	@import "mobile";
	@import "phablet";
	@import "tablet";

    // To be removed after Valentine's Day
    .details_info {
        .store_link {
            display: none;
        }
        .store_info {
            padding-top: 20px;
            padding-left: 5px;
        }
    }
	@include breakpoint(phablet) {
		padding: em(48px) em(34px) em(0px) em(34px);
	}
	@include breakpoint(desktop) {
		padding: em(48px) em(64px) em(0px) em(64px);
	}
}
