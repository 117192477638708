.backToTop {
    display: inline-block;
    padding: 30px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }
}

.black-vertical-logo-mp {
    width: 160px;
    height: 72px;
}

.backToTop-logo {
    margin-bottom: 15px;
    width: 131px;
    height: 80px;
}
.backToTop-text {
    display: block;
    color: #666;
    text-align: center;
    text-transform: uppercase;
    margin-top: 25px;
}
