@mixin breakpoint($point) {
    @if $point == mobile {
        @media (min-width: 320px) { @content ; }
    }
    @else if $point == phablet {
        @media (min-width: 768px) { @content ; }
    }
    @else if $point == tablet {
        @media (min-width: 960px) { @content ; }
    }
    @else if $point == laptop {
        @media (min-width: 1024px)  { @content ; }
    }
    @else if $point == desktop {
        @media (min-width: 1152px)  { @content ; }
    }
    @else if $point == desktop-hd {
        @media (min-width: 1280px)  { @content ; }
    } @else {
        @media (min-width: $point) { @content ; }
    }
}
