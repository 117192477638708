@import url("https://fonts.googleapis.com/css?family=Josefin+Slab:400,400italic&subset=latin-ext,latin&display=swap");

// FontAwesome icons (begin)
@font-face {
  font-family:'FontAwesome';
  src: url('../../../fonts/icons/fontawesome-webfont.woff?v=4.1.0') format("woff");
  font-weight:400;
  font-style:normal
}

@mixin font-icon {
	font-family: 'FontAwesome';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
// FontAwesome icons (end)

@font-face {
  font-display: swap;
  font-family: 'ProximaNovaT-Thin';
  src: local('Proxima Nova Thin'),
       url('../../../fonts/webfonts/2D6B4E_0_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2D6B4E_0_0.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: normal;
  src: local('Proxima Nova'),
       url('../../../fonts/webfonts/2D6B4E_1_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2D6B4E_1_0.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ProximaNova';
  font-weight: bold;
  font-style: normal;
  src: local('Proxima Nova'),
       url('../../../fonts/webfonts/2D6B4E_2_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2D6B4E_2_0.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Futura';
  font-weight: bold;
  font-style: normal;
  src: local('Futura'),
       url('../../../fonts/webfonts/2D6B4E_3_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2D6B4E_3_0.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Futura';
  font-weight: normal;
  font-style: normal;
  src: local('Futura'),
       url('../../../fonts/webfonts/2D6B4E_4_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2D6B4E_4_0.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Bodoni';
  src: local('Bodoni'),
       url('../../../fonts/webfonts/2FF023_0_0.woff2') format('woff2'),
       url('../../../fonts/webfonts/2FF023_0_0.woff') format('woff');
}

// Proxima Nova (begin)
@mixin font-proximanova-light {
    font-family: 'ProximaNovaT-Thin', sans-serif;
    font-weight: 300;
}

@mixin font-proximanova-regular {
	font-family: 'ProximaNova', sans-serif;
	font-weight: 400;
}

@mixin font-proximanova-semibold {
    font-family: 'ProximaNova', sans-serif;
    font-weight: 600;
}

@mixin font-proximanova-bold {
	font-family: 'ProximaNova', sans-serif;
	font-weight: 700;
}

@mixin font-proximanova-italic {
	font-family: 'ProximaNova', sans-serif;
	font-weight: 400;
	font-style: italic;
}
// Proxima Nova (end)

// Futura (begin)
@mixin font-futura-light {
    font-family: 'Futura', sans-serif;
    font-weight: 300;
}

@mixin font-futura-bold {
    font-family: 'Futura', sans-serif;
    font-weight: 600;
}
// Futura (end)

// Bodoni (begin)
@mixin font-bodoni {
    font-family: 'Bodoni', serif;
}
// Bodoni (end)

// Josefin Slab (begin)
@mixin josefin-slab {
    font-family: 'Josefin Slab', sans-serif;
}
@mixin josefin-slab-italic {
    font-family: 'Josefin Slab', sans-serif;
    font-style: italic;
}
// Josefin Slab (end)
