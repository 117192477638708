@import url("https://fonts.googleapis.com/css?family=Josefin+Slab:400,400italic&subset=latin-ext,latin&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bad+Script&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*====== Path Variables ======*/
/*====== Global Variables ======*/
/*====== Colors ======*/
/*====== Elements ======*/
/* Homepage Elements */
@font-face {
  font-family: "FontAwesome";
  src: url("../../../fonts/icons/fontawesome-webfont.woff?v=4.1.0") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "ProximaNovaT-Thin";
  src: local("Proxima Nova Thin"), url("../../../fonts/webfonts/2D6B4E_0_0.woff2") format("woff2"), url("../../../fonts/webfonts/2D6B4E_0_0.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "ProximaNova";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima Nova"), url("../../../fonts/webfonts/2D6B4E_1_0.woff2") format("woff2"), url("../../../fonts/webfonts/2D6B4E_1_0.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "ProximaNova";
  font-weight: bold;
  font-style: normal;
  src: local("Proxima Nova"), url("../../../fonts/webfonts/2D6B4E_2_0.woff2") format("woff2"), url("../../../fonts/webfonts/2D6B4E_2_0.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Futura";
  font-weight: bold;
  font-style: normal;
  src: local("Futura"), url("../../../fonts/webfonts/2D6B4E_3_0.woff2") format("woff2"), url("../../../fonts/webfonts/2D6B4E_3_0.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Futura";
  font-weight: normal;
  font-style: normal;
  src: local("Futura"), url("../../../fonts/webfonts/2D6B4E_4_0.woff2") format("woff2"), url("../../../fonts/webfonts/2D6B4E_4_0.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Bodoni";
  src: local("Bodoni"), url("../../../fonts/webfonts/2FF023_0_0.woff2") format("woff2"), url("../../../fonts/webfonts/2FF023_0_0.woff") format("woff");
}
[class^=icon-]:before, [class*=" icon-"]:before,
[class^=icon-]:after, [class*=" icon-"]:after {
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714286em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}

.fa.pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: spin 1.5s infinite linear;
  -moz-animation: spin 1.5s infinite linear;
  -o-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-gear:before, .fa-cog:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before, .fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before, .fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-gears:before, .fa-cogs:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before, .fa-users:before {
  content: "\f0c0";
}

.fa-chain:before, .fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before, .fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before, .fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-save:before, .fa-floppy-o:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck {
  transform: scaleX(-1);
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-unsorted:before, .fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "\f0dd";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "\f0e2";
}

.fa-legal:before, .fa-gavel:before {
  content: "\f0e3";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-flash:before, .fa-bolt:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-paste:before, .fa-clipboard:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before {
  content: "\f111";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "\f150";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "\f151";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "\f152";
}

.fa-euro:before, .fa-eur:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before, .fa-usd:before {
  content: "\f155";
}

.fa-rupee:before, .fa-inr:before {
  content: "\f156";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "\f157";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "\f158";
}

.fa-won:before, .fa-krw:before {
  content: "\f159";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "\f19c";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper-square:before, .fa-pied-piper:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before, .fa-car:before {
  content: "\f1b9";
}

.fa-cab:before, .fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "\f1c5";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "\f1c6";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before, .fa-rebel:before {
  content: "\f1d0";
}

.fa-ge:before, .fa-empire:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before, .fa-weixin:before {
  content: "\f1d7";
}

.fa-send:before, .fa-paper-plane:before {
  content: "\f1d8";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
  visibility: hidden;
}
.collapse.in {
  display: block;
  visibility: visible;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s linear, visibility 0.35s linear;
}

@keyframes loading_fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.u-loadingFade {
  animation: loading_fade 2s ease infinite;
}

.btn {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}
.btn:hover {
  text-decoration: none;
}

.btn--uppercase {
  text-transform: uppercase;
}

.btn-success {
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  box-sizing: border-box;
  font-size: 1.7142857143em;
  color: #fff;
  background: #7ebd1e;
  border: solid 1px #7ebd1e;
}
.btn-success:hover {
  color: #fff;
  background: #03A24A;
}

.btn-nofill {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid #f1f1f1;
  color: #f1f1f1;
  text-transform: uppercase;
}
.btn-nofill:hover {
  color: #333;
  background: #f1f1f1;
}

.account-submitBtn,
.fb-submitBtnText {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.account-submitBtn {
  display: block;
  width: 100%;
  margin: 5px 0;
  border-radius: 4px;
}

.account-submitBtn--fb {
  position: relative;
  color: #fff;
  width: 100%;
  background: #435A94;
}
.account-submitBtn--fb .fb-icon {
  font-size: 26px;
  position: absolute;
  left: 13px;
  top: 8px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.without-border {
  border: none !important;
}

.center-block {
  margin: 0 auto;
  max-width: 1040px;
}

.grid {
  text-align: justify !important;
  text-justify: distribute-all-lines;
}
.grid > * {
  text-align: left;
}
.grid:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.grid-cell {
  display: inline-block;
  vertical-align: top;
}

.hidden-on-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hidden-on-mobile {
    display: block;
  }
}
.pull-left {
  float: left;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.h1 {
  font-family: "Bodoni", serif;
  font-size: 25px;
  line-height: 1.32;
  letter-spacing: -1px;
  color: #464646;
}

@media (min-width: 960px) {
  .h1 {
    font-size: 30px;
    line-height: 1.4;
  }
}
body {
  font-size: 6px;
}

button,
input,
textarea {
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

button {
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

img {
  display: block;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

@media (min-width: 420px) {
  body {
    font-size: 8px;
  }
}
@media (min-width: 568px) {
  body {
    font-size: 50%;
  }
}
@media (min-width: 667px) {
  body {
    font-size: 55%;
  }
}
@media (min-width: 768px) {
  body {
    font-size: 14px;
  }
}
.invisible {
  height: 0;
  overflow: hidden;
}

#content {
  margin-bottom: 3.5714285714em;
}

#sidebar {
  width: 12.8571428571em;
  float: left;
}

#main {
  margin-left: 15em;
}

.page_header {
  padding-top: 2.5em;
  padding-bottom: 2.3571428571em;
  margin-bottom: 2em;
  border-bottom: solid 1px #cdcdcd;
  position: relative;
}

.breadcrumbs {
  position: absolute;
  top: 2.7142857143em;
  right: 0px;
}

.page_header_title {
  font-size: 17px;
  margin-bottom: 0.7142857143em;
  line-height: 27px;
  color: #555;
}

@media (min-width: 768px) {
  .page_header_title {
    font-size: 22px;
    margin-bottom: 0.8823529412em;
  }
}
@media only screen and (hover: none) and (pointer: coarse) {
  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px !important;
  }
}
.enterOnSite_option,
.enterOnSite_optionBtn {
  display: inline-block;
  vertical-align: top;
}

.enterOnSite_option {
  padding-left: 12px;
  font-size: 12px;
}
@media (min-width: 375px) {
  .enterOnSite_option {
    font-size: 13px;
  }
}
@media (min-width: 768px) {
  .enterOnSite_option {
    padding-left: 40px;
    font-size: 15px;
  }
}
.enterOnSite_option:hover {
  text-decoration: none;
}
.enterOnSite_option--marketplace, .enterOnSite_option--desktop, .enterOnSite_option--help, .enterOnSite_option--florist, .enterOnSite_option--logout {
  display: none;
}
@media (min-width: 768px) {
  .enterOnSite_option--marketplace, .enterOnSite_option--desktop, .enterOnSite_option--help, .enterOnSite_option--florist, .enterOnSite_option--logout {
    display: block;
  }
}
@media (min-width: 768px) {
  .enterOnSite_option--login {
    padding-left: 32px;
  }
}
.enterOnSite_option--mobile {
  display: block;
}
@media (min-width: 768px) {
  .enterOnSite_option--mobile {
    display: none;
  }
}
.enterOnSite_option--checkout {
  padding-left: 8px;
}
@media (min-width: 768px) {
  .enterOnSite_option--checkout {
    padding-left: 32px;
  }
}

.enterOnSite_optionBtn {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 30px;
  color: #555;
  text-align: center;
  line-height: 18px;
}
.enterOnSite_optionBtn:hover {
  text-decoration: none;
  color: #b51f6d;
}
.enterOnSite_optionBtn:hover svg polygon, .enterOnSite_optionBtn:hover svg path {
  fill: #b51f6d;
}
.enterOnSite_optionBtn:hover svg.goToCheckoutArrow path {
  fill: #FFFFFF;
}
.enterOnSite_optionBtn--marketplace {
  padding: 10px;
  border: 1px solid #777;
  border-radius: 4px;
}
.enterOnSite_optionBtn--marketplace:hover {
  border-color: #b51f6d;
}
.enterOnSite_optionBtn--florist {
  display: none;
  color: #d8247f;
  border: 1px solid #d8247f;
  padding: 10px 19px;
  border-radius: 4px;
}
.enterOnSite_optionBtn--florist:hover {
  color: #b51f6d;
  border: 1px solid #b51f6d;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--florist {
    display: block;
  }
}
.enterOnSite_optionBtn--logout-mobile {
  color: #d8247f;
  border: 1px solid #d8247f;
  padding: 10px;
  border-radius: 4px;
}
.enterOnSite_optionBtn--logout-mobile:hover {
  color: #b51f6d;
  border: 1px solid #b51f6d;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--logout-mobile {
    display: none;
  }
}
.enterOnSite_optionBtn--help {
  display: none;
}
@media (min-width: 520px) {
  .enterOnSite_optionBtn--help {
    display: block;
  }
}
.enterOnSite_optionBtn--login {
  background: #d8247f;
  color: #fff;
  padding: 10px;
  border: 1px solid #d8247f;
  border-radius: 4px;
  font-size: 14px;
}
.enterOnSite_optionBtn--login:hover {
  background: #b51f6d;
  color: #fff;
}
.enterOnSite_optionBtn--login .desktop-login-text {
  display: none;
  font-size: 15px;
}
.enterOnSite_optionBtn--login .mobile-login-text {
  font-size: 15px;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--login {
    padding: 10px 18px;
    font-size: 15px;
  }
  .enterOnSite_optionBtn--login .mobile-login-text {
    display: none;
  }
  .enterOnSite_optionBtn--login .desktop-login-text {
    display: inline;
  }
}
.enterOnSite_optionBtn--desktop {
  display: none;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--desktop {
    display: block;
  }
}
.enterOnSite_optionBtn--desktop .dropdown-triangle-icon {
  display: inline;
}
.enterOnSite_optionBtn--desktop .dropdown-triangle-icon svg {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.enterOnSite_optionBtn--account {
  padding-top: 7px;
  height: 40px;
  width: 40px;
  border: 1px solid #d8247f;
  border-radius: 4px;
}
.enterOnSite_optionBtn--account:hover {
  border: 1px solid #b51f6d;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--account {
    display: none;
  }
}
.enterOnSite_optionBtn--checkout {
  background: #d8247f;
  color: #fff;
  padding: 11px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 18px;
  font-size: 14px;
  white-space: nowrap;
}
.enterOnSite_optionBtn--checkout:hover {
  background: #b51f6d;
  color: #fff;
}
.enterOnSite_optionBtn--checkout .goToCheckoutArrow {
  margin-right: 14px;
  margin-bottom: 1px;
  display: none;
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--checkout .goToCheckoutArrow {
    display: block;
  }
}
@media (min-width: 768px) {
  .enterOnSite_optionBtn--checkout {
    font-size: 15px;
    padding: 11px 20px;
    white-space: normal;
  }
}

.enterOnSite_select {
  display: flex;
  align-items: center;
}

.enterOnSite_optionBtn--border {
  border: solid 1px #CDCDCD;
  width: 9.2em;
}

#header {
  display: block;
  border-bottom: 1px solid #E6E6E6;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.no-header-border {
  border: none !important;
}

.header-mobile {
  display: block;
  background: #fff;
}
@media (min-width: 768px) {
  .header-mobile {
    display: none;
  }
}
.header-mobile__list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-mobile__list-item {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-size: 12px;
}
.header-mobile__list-item:first-of-type {
  border-right: 1px solid #eee;
}
.header-mobile__link {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  color: #555;
  text-transform: uppercase;
}
.header-mobile__link svg {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-top: -5px;
  margin-right: 5px;
}
.header-mobile__icon-flower path {
  stroke: #555;
}
.header-mobile__icon-phone path {
  fill: #777;
}

#header {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  background: #fff;
  padding: 10px 5px;
  font-size: 1.25em;
  color: #555;
}
#header .mobile-logo {
  display: none;
}
#header .mobile-logo.mobile-logo-pdp {
  display: inline;
}
#header .desktop-logo {
  display: inline;
}
#header .desktop-logo.desktop-logo-pdp {
  display: none;
}
#header .logo {
  align-items: flex-start;
  flex-shrink: 0;
  margin-top: 5px;
  max-width: 175px;
}
#header .logo .logo_image {
  height: auto;
  width: 100%;
}
#header .header-nav {
  margin-left: auto;
}
#header .userbar,
#header .header-navToggle {
  font-size: 1.86349em;
  float: right;
}
#header .userbar {
  position: relative;
}

.header_content {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}
@media (min-width: 350px) {
  .header_content {
    padding: 0 15px;
  }
}

.header-secure-badge {
  padding-left: 10px;
}
.header-secure-badge span {
  color: #bbb;
}
.header-secure-badge .fa-stack {
  font-size: 13px;
}
.header-secure-badge .secure_checkout,
.header-secure-badge .secure_checkout-extraText {
  display: none;
}

@media (min-width: 420px) {
  #header {
    font-size: 1em;
  }
  .header-secure-badge .fa-stack {
    font-size: 18px;
  }
}
@media (min-width: 568px) {
  .header-secure-badge {
    padding-right: 5px;
  }
  .header-secure-badge .fa-stack {
    font-size: 22px;
  }
  .header-secure-badge .secure_checkout,
  .header-secure-badge .secure_checkout-extraText {
    display: block;
    font-weight: 600 !important;
  }
  .header-secure-badge .secure_checkout {
    float: right;
    margin-top: 8px;
    margin-right: 5px;
    line-height: 14px;
  }
}
@media (min-width: 768px) {
  #header {
    padding: 14px;
  }
  #header .mobile-logo {
    display: none;
  }
  #header .mobile-logo.mobile-logo-pdp {
    display: none;
  }
  #header .desktop-logo {
    display: inline;
  }
  #header .desktop-logo.desktop-logo-pdp {
    display: block;
  }
  #header .logo {
    max-width: 232px;
    height: 35px;
    margin-top: 0;
  }
  #header .userbar {
    font-size: 1.28571em;
    float: right;
  }
}
.column_title {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  margin-bottom: 1.25em;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
}

.nav_links_item {
  font-size: 14px;
  margin-bottom: 1.5384615385em;
}
.nav_links_item a {
  color: #333;
}

.socialIcon {
  width: 14px;
  text-align: center;
}

.topCities {
  padding: 45px 2.42857em 20px;
  background: #fff;
}

.topCities-header {
  font-size: 20px;
  margin-bottom: 25px;
}

h2.topCities-header {
  color: #464646;
  font-family: "Bodoni", serif;
  font-size: 22px;
  line-height: 1.32;
  margin-bottom: 25px;
  text-align: center;
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  h2.topCities-header {
    font-size: 25px;
  }
}
@media screen and (min-width: 960px) {
  h2.topCities-header {
    font-size: 30px;
  }
}
.topCities-cardList {
  text-align: center;
  margin-bottom: 5px;
}

.topCityCardWrapper {
  box-sizing: border-box;
  float: left;
  width: 50%;
}
.topCityCardWrapper:nth-child(2n) {
  padding-right: 10px;
}
.topCityCardWrapper:nth-child(2n-1) {
  padding-left: 10px;
}
.topCityCardWrapper:first-child {
  padding-left: 0;
}

.topCityCardWrapper--featured {
  width: 100%;
}

.topCityCard {
  height: 200px;
  margin-bottom: 20px;
  background-color: #777;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, transparent 100%);
  border: 1px solid #888;
}

.topCityCard-linkWrapper {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  box-shadow: 0em em 1em 0.1em rgba(29, 30, 35, 0.78);
}
.topCityCard-linkWrapper--losangeles {
  background-position-y: -105px;
}
@media (min-width: 375px) {
  .topCityCard-linkWrapper--losangeles {
    background-position-y: -140px;
  }
}
@media (min-width: 420px) {
  .topCityCard-linkWrapper--losangeles {
    background-position-y: top;
  }
}
.topCityCard-linkWrapper--losangeles .topCityCard-text {
  white-space: normal;
}

.topCityCard-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  border-radius: 0;
  padding: 25px 10px;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0 1px 0 black;
  color: #fff;
  text-transform: uppercase;
}
.topCityCard-text:hover {
  text-decoration: none;
}
@media (min-width: 420px) {
  .topCityCard-text {
    padding: 25px 15px;
    width: auto;
    border-radius: 3px;
    font-size: 22px;
  }
}
@media (min-width: 768px) {
  .topCityCard-text {
    padding: 25px 15px;
    white-space: nowrap;
    font-size: 25px;
  }
}

.topCities-toggleLink {
  display: block;
  margin-top: 15px;
  margin-bottom: 30px;
  text-decoration: underline;
  text-align: center;
  font-size: 13px;
  color: #555;
}

.topCities--home .topCities-listWrapper,
#topCities-toggle {
  display: none;
}

.topCities-nameList {
  display: flex;
  margin-bottom: 10px;
  flex-flow: wrap row;
  text-align: left;
  font-size: 14px;
}

.topCity-item {
  box-sizing: border-box;
  padding: 0 13.5px 16px;
  width: 33.333%;
  font-size: 13px;
}

.topCity-itemName {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
}

@media (min-width: 420px) {
  .topCityCardWrapper:first-child {
    padding-left: 0;
    padding-right: 10px;
  }
  .topCityCardWrapper:nth-child(2) {
    padding-left: 10px;
    padding-right: 0;
  }
  .topCityCardWrapper--featured {
    width: 50%;
  }
  .topCityCard--featured {
    height: 420px;
  }
  .topCities--home .topCities-listWrapper {
    display: block;
  }
  .topCity-item {
    width: 25%;
  }
}
@media (min-width: 768px) {
  .topCityCardWrapper--featured {
    width: 100%;
  }
  .topCityCardWrapper {
    width: 36.25%;
  }
  .topCityCardWrapper:nth-child(2) {
    padding-left: 0;
    padding-right: 10px;
  }
  .topCities-cardList .topCityCardWrapper--featured {
    width: 27.5%;
    padding-right: 20px;
  }
}
@media (min-width: 960px) {
  .topCity-item {
    width: 20%;
  }
}
#footer {
  color: #333;
}
#footer .footer_contentWrapper {
  padding-top: 2.1428571429em;
  background: #F0F0F0;
}
#footer .footer_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1.2857142857em 2.4285714286em 1.4285714286em 2.4285714286em;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  #footer .footer_content {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 992px) {
  #footer .footer_content {
    grid-template-columns: 30% repeat(4, 1fr);
  }
}
#footer .footer_column {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  #footer .footer_column--backToTop {
    grid-column: 2/span 2;
    grid-row: 2;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  #footer .footer_column--backToTop {
    grid-column: initial;
    grid-row: initial;
    text-align: left;
  }
}
#footer .footer_column--social {
  padding: 0;
}
#footer .footer_column--backToTop {
  display: none;
}

.copyright {
  padding: 0.8125em 0px 20px;
  font-size: 13px;
  background: #F0F0F0;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  #footer .footer_column {
    margin-bottom: 0;
  }
  #footer .footer_column--backToTop {
    display: inline-block;
  }
}
#notification-bar {
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #3b4146;
  color: #fff;
}
#notification-bar svg {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
#notification-bar svg:hover {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
#notification-bar svg > path {
  stroke: #3b4146;
}
#notification-bar svg:hover > circle {
  fill: #707070;
  stroke: #707070;
}
#notification-bar svg:hover > path {
  stroke: #fff;
}

#notification .notification-content ul {
  width: 75%;
  list-style: none;
  margin: 15px auto;
}
#notification .notification-content ul li {
  margin: 0 auto;
  line-height: 17px;
  word-wrap: normal;
}
@media (min-width: 320px) {
  #notification .notification-content ul li {
    font-size: 11px;
  }
}
@media (min-width: 768px) {
  #notification .notification-content ul li {
    font-size: 12px;
  }
}
@media (min-width: 960px) {
  #notification .notification-content ul li {
    font-size: 13px;
  }
}

.notification-close {
  position: absolute;
  right: 8px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}
@media only screen and (min-width: 550px) {
  .notification-close {
    right: 20px;
  }
}
.notification-close .btn-remove {
  text-decoration: none;
  height: 24px;
  width: 24px;
}
.notification-close a {
  text-decoration: none;
}

.payment-banner {
  height: 44px;
  width: 100%;
  background: #EFEFEF;
  font-size: 16px;
  display: none;
}

.payment-banner-chrome .content-wrapper {
  max-width: 560px;
  margin: auto;
  height: 44px;
}

.payment-banner-safari .content-wrapper {
  max-width: 725px;
  margin: auto;
  height: 44px;
}

.paid-method-icon-wrapper {
  padding: 8px;
  text-align: right;
  float: left;
}

.paid-method-text-wrapper {
  padding: 8px;
  text-align: left;
  float: left;
  line-height: 28px;
}

.close-cross-wrapper {
  width: auto;
  padding: 15px;
  text-align: right;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

.banner-title {
  font-weight: 600 !important;
  padding-right: 8px;
  font-size: 16px;
  line-height: 20px;
}

.banner-description {
  padding-left: 8px;
  font-size: 16px;
  line-height: 20px;
}

.payment-banner-close-cross {
  cursor: pointer;
  display: block;
  width: 14px;
  height: 14px;
}

.line-break {
  display: none;
}

@media screen and (max-width: 767px) {
  .payment-banner {
    height: auto;
  }
  .delimiter {
    display: none;
  }
  .paid-method-icon-wrapper {
    width: auto;
    padding: 16px 8px 16px 16px;
    text-align: left;
    float: left;
  }
  .paid-method-text-wrapper {
    width: 75%;
    padding: 16px 8px;
    line-height: 24px;
    float: none;
  }
  .payment-banner-chrome .paid-method-text-wrapper {
    margin-left: 96px;
  }
  .payment-banner-safari .paid-method-text-wrapper {
    margin-left: 78px;
  }
  .payment-banner-chrome .content-wrapper,
  .payment-banner-safari .content-wrapper {
    max-width: none;
    height: auto;
  }
  .line-break {
    display: inline;
  }
  .banner-title, .banner-description {
    padding: 0;
  }
  .banner-description {
    font-size: 14px;
    line-height: 16px;
  }
  .close-cross-wrapper {
    padding: 8px;
  }
  .payment-banner-close-cross {
    width: 30px;
    height: 30px;
  }
  .safari-badge {
    width: 54px;
    height: 34px;
  }
  .chrome-badge {
    width: 72px;
    height: 34px;
  }
}
.text-control input.error,
input.error {
  border: 1px solid #d0021b !important;
  color: #d0021b !important;
}

.visuallyhidden {
  display: none;
}

.datepickr-wrapper {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.datepickr-calendar {
  z-index: 100;
  position: absolute;
  right: 0px;
  display: none;
  padding: 0 15px 15px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 200;
  background-color: #fff;
  color: #333;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.open .datepickr-calendar {
  display: block;
}

.datepickr-calendar .datepickr-day:hover,
.datepickr-calendar .datepickr-prev-month:hover,
.datepickr-calendar .datepickr-next-month:hover {
  color: #444;
  background: rgba(0, 0, 0, 0.05);
}

.datepickr-calendar .datepickr-months {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #444;
  padding: 2px;
  text-align: center;
  font-size: 120%;
}

.datepickr-calendar .datepickr-prev-month,
.datepickr-calendar .datepickr-next-month {
  padding: 1em;
  text-decoration: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  color: #444;
}

.datepickr-calendar .datepickr-prev-month {
  float: left;
}

.datepickr-calendar .datepickr-next-month {
  float: right;
}

.datepickr-calendar .datepickr-current-month {
  display: block;
  padding: 0.75em;
}

.datepickr-calendar table {
  width: 100%;
  padding: 0;
  border-collapse: collapse;
  background: #fff;
}

.datepickr-calendar thead {
  font-size: 90%;
}

.datepickr-calendar th,
.datepickr-calendar td {
  width: 14.3%;
}

.datepickr-calendar th {
  text-align: center;
  padding: 5px;
}

.datepickr-calendar td {
  text-align: right;
  padding: 1px;
}

.datepickr-calendar .datepickr-day {
  display: block;
  padding: 11.2px;
  color: #444;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.datepickr-calendar .selected .datepickr-day {
  background: #00b551;
  color: #fff;
}

.datepickr-calendar .disabled .datepickr-day,
.datepickr-calendar .disabled .datepickr-day:hover {
  color: #ccc;
  background: #fff;
  cursor: not-allowed;
  border: 0;
}

@media (min-width: 768px) {
  .datepickr-calendar .datepickr-day {
    padding: 16px;
    font-size: 17px;
  }
}
.text-control {
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  padding-bottom: 6px;
}
.text-control.m-with-icon {
  position: relative;
}
.text-control.m-with-icon .searchIcon {
  position: absolute;
  top: 0.85em;
  left: 0.9157894737em;
  z-index: 2;
  max-width: 11px;
  color: #ccc;
}
.text-control label {
  display: block;
  margin-bottom: 5px;
}
.text-control input {
  border: none;
  background: #fff;
  margin: 0px;
  padding: 0px;
  box-shadow: none;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  width: 100%;
  border: 1px solid #c7c7c7;
  border-radius: 3px;
  padding: 0.5714285714em 0.3571428571em 0.5em 0.8214285714em;
}
.text-control input:focus, .text-control input:hover {
  background: #FCFCFC;
}

.select-control {
  position: relative;
  border: 0;
  margin-top: -0.2857142857em;
  font-size: 0.65em;
  text-transform: none;
}
.select-control::after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 50%;
  right: 1.0714285714em;
  margin-top: -0.4285714286em;
  border-style: solid;
  border-width: 0.8571428571em 0.8571428571em 0 0.8571428571em;
  border-color: #333333 transparent transparent transparent;
}
.select-control select {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  color: #3d3d3d;
  background: transparent;
  font-size: 14px;
  min-width: 10.7142857143em;
  border-radius: 0.2857142857em;
  -webkit-appearance: none;
  padding: 0px 42px 0px 11px;
  box-sizing: border-box;
  height: 35px;
}
.select-control select::-ms-expand {
  display: none;
}
@media (min-width: 568px) {
  .select-control {
    font-size: 0.7em;
  }
}
@media (min-width: 768px) {
  .select-control {
    font-size: 14px;
    border-radius: 4px;
  }
  .select-control::after {
    right: 10px;
    margin-top: -4px;
    border-width: 0.5714285714em 0.5714285714em 0 0.5714285714em;
  }
  .select-control select {
    height: 35px;
    line-height: 27px;
    padding-right: 42px;
  }
}

@-moz-document url-prefix() {
  .select-control select {
    border: 1px #333 solid;
  }
  .select-control::after {
    display: none;
  }
}
.warming_message_wrapper {
  display: none;
  color: #333;
}
.warming_message_wrapper .orBreak {
  margin: 0 2px;
}
.warming_message_wrapper .center-block {
  max-width: 960px;
}
.warming_message_wrapper.activated {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: fixed;
  padding: 0;
  background: white;
  height: 100%;
  z-index: 15;
  padding-top: 3em;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.warming_message_wrapper h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #333;
}
.warming_message_wrapper h3 {
  font-size: 1.2em;
  font-style: italic;
}
.warming_message_wrapper .callout {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}
.warming_message_wrapper .card {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin: 0 auto 120px auto;
  background: rgb(239, 239, 239);
  padding: 4em;
  padding-bottom: 2em;
  overflow: auto;
}
.warming_message_wrapper .card img {
  display: none;
}
.warming_message_wrapper .product-title {
  display: none;
}
.warming_message_wrapper #card-message {
  box-sizing: border-box;
  width: 100%;
  height: 10em;
  resize: none;
  border: 1px solid #ddd;
  padding: 1em;
  font-size: 1.2em;
  font-family: "Bad Script", cursive, sans-serif;
  font-size: 19px;
}
.warming_message_wrapper .char-remaining {
  display: none;
  color: #777;
  font-size: 0.75em;
  margin-top: 0.3em;
}
.warming_message_wrapper .char-remaining input {
  background: #efefef;
}
.warming_message_wrapper .select-control {
  margin-top: 1.5em;
  font-size: 0.7em;
}
.warming_message_wrapper #occasion_select {
  background: white;
  display: block;
  width: 100%;
}
.warming_message_wrapper .card-message-action-block-right {
  margin-bottom: 0.85em;
  width: 100%;
  text-align: center;
}
.warming_message_wrapper .card-message-action-block-right button {
  font-size: 1.4em;
  width: 100%;
  height: 3em;
  margin-top: 1em;
  border-radius: 3px;
  background: #00b551;
  margin-bottom: 0.2em;
}
.warming_message_wrapper .card-message-action-block-right button:hover {
  background: #03A24A;
}
.warming_message_wrapper .card-nav,
.warming_message_wrapper .orBreak {
  font-size: 1em;
}
.warming_message_wrapper .card-nav {
  cursor: pointer;
}
.warming_message_wrapper .card-nav a {
  display: inline-block;
  padding-top: 10px;
  font-size: 14px;
  text-decoration: underline;
  color: #333;
}
.warming_message_wrapper .card-nav a:hover {
  text-decoration: underline;
}
.warming_message_wrapper .card-footer {
  font-size: 1.3em;
}

@media (min-width: 768px) {
  .warming_message_wrapper.activated {
    padding-top: 1em;
  }
  .warming_message_wrapper .bouquet {
    float: left;
    width: 22%;
    padding: 1em;
    background: white;
    text-align: center;
  }
  .warming_message_wrapper .bouquet .product-title {
    display: block;
    font-size: 1.1em;
    color: #333;
    margin-top: 0.5em;
    font-weight: 300;
  }
  .warming_message_wrapper .bouquet img {
    display: block;
    width: 100%;
  }
  .warming_message_wrapper h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
  }
  .warming_message_wrapper .card-nav,
  .warming_message_wrapper .orBreak {
    font-size: 0.7em;
  }
  .warming_message_wrapper .card {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 1em;
    overflow: auto;
    padding: 2em;
    padding-bottom: 0.5em;
  }
  .warming_message_wrapper .card-nav {
    font-size: 14px;
  }
  .warming_message_wrapper .card-message-input {
    width: 100%;
  }
  .warming_message_wrapper #card-message {
    font-size: 2.2em;
  }
  .warming_message_wrapper .card-message-input,
  .warming_message_wrapper .card-message-action-block-left {
    margin-bottom: 3px;
    width: 44%;
    float: left;
  }
  .warming_message_wrapper .card-message-action-block-right {
    width: 50%;
    float: right;
  }
  .warming_message_wrapper .card-message-action-block-right button {
    font-size: 0.8em;
    margin-top: 1.85em;
  }
  .warming_message_wrapper .select-control {
    margin-top: 1em;
  }
  .warming_message_wrapper #occasion_select {
    background: white;
    display: block;
    margin-top: 0.2em;
  }
  .warming_message_wrapper .char-remaining {
    display: block;
  }
  .warming_message_wrapper .char_remaining {
    box-shadow: none;
    border: none;
    background: #fff;
  }
  .warming_message_wrapper .card-message-box {
    float: right;
    width: 72%;
  }
  .warming_message_wrapper .card-message-continue {
    font-size: 2em;
    padding: 5px;
    cursor: pointer;
  }
  .warming_message_wrapper .card-footer {
    width: 100%;
    float: right;
  }
  .warming_message_wrapper .card-message-action-block-occasion {
    font-size: 0.9em;
  }
  .warming_message_wrapper .card-message-continue {
    font-size: 1em;
  }
}
.breadcrumbs li {
  font-size: 1.0714285714em;
  color: #726c71;
  display: inline-block;
}
.breadcrumbs li::after {
  content: " >";
}
.breadcrumbs li:last-child::after {
  display: none;
}
.breadcrumbs li a {
  color: #726c71;
}

.pdp-breadcrumbs-wrapper {
  padding: 10px 1.0714285714em 0 1.0714285714em;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper {
    padding: 0 2.4285714286em;
    margin-bottom: -3.4285714286em;
  }
}
@media (min-width: 1152px) {
  .pdp-breadcrumbs-wrapper {
    padding: 0 4.5714285714em;
  }
}
.pdp-breadcrumbs-wrapper .breadcrumbs {
  max-width: 500px;
  margin: auto;
  display: block;
  position: static;
  padding: 0;
}
.pdp-breadcrumbs-wrapper .breadcrumbs li {
  font-size: 12px;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper .breadcrumbs {
    padding: 2em 2.4285714286em 20px 2.4285714286em;
    margin: 0;
    max-width: 1040px;
  }
  .pdp-breadcrumbs-wrapper .breadcrumbs li {
    font-size: 15px;
  }
}
@media (min-width: 1152px) {
  .pdp-breadcrumbs-wrapper .breadcrumbs {
    margin: 0 auto;
    max-width: 1040px;
  }
  .pdp-breadcrumbs-wrapper .breadcrumbs li {
    font-size: 15px;
  }
}

.suave.cms-page-view * {
  box-sizing: border-box;
}
.suave.cms-page-view body {
  font-size: 14px;
  line-height: 1.4;
}
.suave.cms-page-view p {
  line-height: 1.4;
  margin-bottom: 10px;
}
.suave.cms-page-view.cms-jobs a.btn {
  padding: 5px;
  color: #fff;
}
.suave.cms-page-view #layoutContainer {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
  padding: 40px;
}
.suave.cms-page-view h1, .suave.cms-page-view h2 {
  margin: 15px 0;
  font-size: 24px;
  text-align: center;
}
.suave.cms-page-view #about .btn {
  position: static !important;
}

.std {
  max-width: 960px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.4;
}
.std * {
  box-sizing: border-box;
}
.std > .pressroom {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
  padding: 40px;
}
.std > .pressroom .pressroom-item {
  max-width: 660px;
  margin: 0 auto 30px;
}
.std > .pressroom .pressroom-item .pressroom-title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
}

.suave .cart-empty {
  margin-bottom: 100px;
}

.cms-page-view .std {
  max-width: none;
}

/*CONTACT PAGE CSS*/
.coverPhoto-wrapper--contact {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.coverPhoto-formWrapper--contact {
  width: 33%;
  min-width: 500px;
  padding-top: 130px;
  padding-bottom: 50px;
  padding-left: 1%;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer .text-control {
  background: none;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer .text-control input {
  background: #fff;
  margin-bottom: 2%;
}

.coverPhoto-formContainer {
  background: rgba(255, 255, 255, 0.7);
}

textarea#comment {
  border: none;
  outline: none;
  background: #fff;
  margin: 0px;
  margin-bottom: 2%;
  padding: 0px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
  color: #333;
  width: 100%;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 0.67857em 0.35714em 0.82143em 0.82143em;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer .btn-success {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  font-weight: 700;
  width: 100%;
  text-transform: uppercase;
  font-size: 1em;
}

.coverPhoto-formContainer-title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2%;
  font-size: 24px;
  color: #333;
}

.contact-details {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.customer-service {
  width: 33%;
  float: left;
  margin-left: 2%;
}

.contact-info {
  width: 33%;
  float: right;
  margin-right: 2%;
}

.contact-email {
  color: #000;
}

.customer-service-title, .contact-info-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.contact-infoLine {
  margin-bottom: 5px;
}

.contact-details .contact-info i {
  text-align: center;
  padding: 1px;
  color: #333;
}

.contact-info .fa-circle-thin {
  font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .noRoute-photo-container .btn-nofill {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .coverPhoto-formWrapper--contact {
    width: 100%;
    min-width: 0;
    max-width: 500px;
    padding: 10px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 540px) {
  .contact-details {
    width: 100%;
    margin: 10px auto;
    padding: 0 15px;
  }
}
.filter {
  margin-bottom: 2.1428571429em;
}

.filter_header {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400;
  color: #555555;
}

.filter_header_title a {
  color: #555555;
  display: block;
  position: relative;
  text-decoration: none;
  transition: 0.2s opacity;
}
.filter_header_title a:hover {
  opacity: 0.8;
}
.filter_header_title a::before {
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.7058823529em;
  color: #cecece;
  position: absolute;
  top: -0.1034482759em;
  right: -0.4137931034em;
  content: "\e001";
  transition: 0.3s transform;
  display: none;
}
.filter_header_title a.collapsed::before {
  content: "\e00d";
  display: none;
}
.filter_header_title a[data-toggle=expand]::before {
  transform: rotate(180deg);
}

.filter_option {
  display: flex;
  font-size: 13px;
  line-height: 1.5333333333em;
  color: #41858c;
  padding-left: 0.2em;
  margin-bottom: 0.5333333333em;
}
.filter_option label[data-checked=true] a::before {
  content: "x";
  display: inline-block;
  position: absolute;
  left: -0.8em;
  top: -0.0666666667em;
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  color: #555;
}
.filter_option a {
  color: #41858c;
  display: block;
  position: relative;
}
.filter_option a input[type=checkbox] {
  display: none;
}
.filter_option a.checked::after {
  content: "&times;";
  display: inline-block;
  position: absolute;
  left: -0.8em;
  top: -0.0666666667em;
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  color: #555;
}

.products_toolbar {
  position: relative;
}

#product_filter {
  float: right;
}

#pickup-only-filter {
  -webkit-appearance: checkbox;
}

.topShops .shop {
  width: 100%;
  background: white;
  border: 0;
  padding: 0;
  padding-bottom: 1.3571428571em;
  box-sizing: border-box;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 1em;
}
.topShops .shop:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
}
.topShops .shop_cover {
  margin-bottom: 1.5em;
}
.topShops .shop_cover img {
  width: 100%;
  box-sizing: border-box;
}
.topShops .shop_name {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #205f64;
  margin-bottom: 0.46875em;
}
.topShops .shop_name a {
  color: #2d848a;
}
.topShops .shop_location {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  font-size: 1.5714285714em;
  font-style: italic;
  color: #2d848a;
}
.topShops .shop_location a {
  color: #555;
}

@media (min-width: 320px) {
  .topShops .shop {
    width: 49.0384615385%;
  }
}
@media (min-width: 768px) {
  .topShops .shop {
    width: 31.8416523236%;
    padding-bottom: 1.1428571429em;
  }
  .topShops .shop:first-child {
    display: none;
  }
  .topShops .shop_cover {
    margin-bottom: 1.2857142857em;
  }
  .topShops .shop_name {
    font-size: 20px;
    margin-bottom: 0.5217391304em;
  }
  .topShops .shop_location {
    font-size: 1.1428571429em;
  }
}
@media (min-width: 1024px) {
  .topShops {
    max-width: 1162px;
  }
  .topShops .shop {
    width: 23.321858864%;
  }
  .topShops .shop:first-child {
    display: inline-block;
  }
}

.list_top .pagination {
  display: none;
}

.list_bottom {
  font-size: 14px;
  padding: 20px 0.64286em 0;
}

.pagination li {
  display: inline-block;
  vertical-align: top;
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 2.125em;
  box-sizing: border-box;
  width: 35px;
  height: 33px;
  border: 1px solid #c5c4c2;
  border-radius: 4px;
  text-align: center;
}
.pagination li a {
  color: #b8b6b6;
  display: block;
  text-decoration: none;
}
.pagination li:hover {
  background: #60B8BE;
  border: 1px #469fa6 solid;
}
.pagination li:hover a {
  color: #f2f2f2;
}
.pagination li.active {
  background: #469fa6;
  border-color: #31959d;
  color: white;
}
.pagination li.active a {
  color: white;
}
.pagination li.next, .pagination li.previous {
  width: auto;
}
.pagination li.next a, .pagination li.previous a {
  padding: 0px 0.75em;
}

@media (min-width: 768px) {
  .pagination li {
    line-height: 2.125em;
    width: 35px;
    height: 33px;
  }
  .pagination li.next a, .pagination li.previous a {
    padding: 0px 1.125em;
  }
}
.collapse-info {
  color: #726c71;
}
.collapse-info p {
  margin-bottom: 0.8183453237em;
  font-size: 0.9928571429em;
  line-height: 1.4604316547em;
  color: #888;
  display: inline;
}
.collapse-info .teaser {
  display: inline;
}
.collapse-info .teaser .teaser p {
  display: inline-block;
}
.collapse-info .full-text {
  padding: 10px 0;
}
.collapse-info .toggle-full-text {
  font-size: 0.9928571429em;
  color: #fff;
  display: inline;
  font-weight: 600;
}

.vendorAlert {
  margin: 15px 10px 0;
}

.vendorAlert-innerWrapper {
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
}

.vendorAlert-imgContainer {
  max-width: 30%;
}

.vendorAlert-img {
  max-width: 100%;
  height: auto;
}

.vendorAlert-body {
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 13.5px;
  font-weight: 200;
  line-height: 1.45;
}
.vendorAlert-body p {
  margin: 0;
}

#page-details .vendorAlert {
  margin: 0;
}

#page-details .vendorAlert-innerWrapper {
  margin-bottom: 10px;
}

.pickup-only-warning {
  text-align: center;
  border-radius: 3px;
  margin-left: 15px;
  background-color: #fffbe8 !important;
  border: solid 1px #ddc337 !important;
  padding: 10px 0 10px 0px !important;
  color: #555;
  font-size: 13px;
  min-height: 36px;
}

.order_delivery table.pickup-only-warning {
  display: table;
  margin: 0;
  width: 100%;
  line-height: 1.42857143;
}

.pickup-only-warning-alert {
  height: 26px;
  font-weight: 200 !important;
  margin-left: 14px;
}

.pickup-only-warning td {
  vertical-align: middle;
}

.pickup-only-text {
  font-weight: 200 !important;
  padding: 12px 8px;
}

.hidden {
  display: none;
}

@media (min-width: 420px) {
  .vendorAlert-body {
    font-size: 15.5px;
  }
}
.product-categories {
  white-space: nowrap;
}
.product-categories li {
  display: inline-block;
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  margin-right: 0.2142857143em;
}
.product-categories li a {
  font-size: 1.9285714286em;
  color: #666;
  display: block;
  background: #EFEFEF;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0.5185185185em 1.1111111111em 0.5925925926em 1.1111111111em;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.7);
}
.product-categories li a:hover {
  text-decoration: none;
  color: #fff;
  background: #ccc;
  box-shadow: none;
}
.product-categories li.active a {
  background: #4ac1c7;
  color: #fff;
  box-shadow: inset 0px 1px 1px 0px rgba(64, 166, 171, 0.7);
  border-color: #36a0a5;
  cursor: default;
}
.product-categories li.active a:hover {
  background: #4ac1c7;
}

.product-grid {
  font-size: 0;
}
.product-grid:after {
  content: "";
  display: table;
  clear: both;
}
.product-grid .grid-cell {
  margin-bottom: 10px;
}

.product.card {
  width: 100%;
  box-sizing: border-box;
  border: 0.5px solid #E4E4E4;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 13px;
  position: relative;
}
.product.card:hover, .product.card:active {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
}
.product.card .product_photo {
  margin-bottom: 3px;
  border-bottom: 1px solid #EFEFEF;
}
.product.card .product_photo img {
  width: 100%;
  height: auto;
}
.product.card .product_info {
  padding: 0 0.6428571429em 0.6428571429em 0.9285714286em;
}
.product.card .product_name,
.product.card .product_price {
  display: inline-block;
  vertical-align: top;
}
.product.card .product_name {
  font-size: 11px;
  line-height: 1.8;
  white-space: normal;
  width: 100%;
  padding: 5px 0;
  text-overflow: ellipsis;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.product.card .product_name a {
  color: #464646;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  display: inline-block;
}
.product.card .product_name a:hover {
  text-decoration: none;
}
.product.card .product_name .product_price {
  font-size: 13px !important;
  line-height: 1.5;
  color: #89c520;
  text-decoration: none !important;
  padding-left: 5px;
  overflow: visible;
}
.product.card .divider {
  border-bottom: 1px solid #efefef;
}
.product.card .product_florist {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 3px;
}
.product.card .product_florist a {
  color: #464646;
  display: inline;
  vertical-align: middle;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product.card .product_florist .florist_image {
  border: 1px solid #EFEFEF;
  display: inline;
  float: left;
  margin: -2px 10px 0 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.product.card .product_florist .florist_name {
  font-size: 10px;
  color: #898989;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 63%;
  display: inline-block;
}
.product.card .product_florist .florist_name:hover {
  text-decoration: none;
}
.product.card .product_florist .florist_delivery {
  display: inline-block;
  padding-top: 2px;
  font-size: 9px;
  font-style: italic;
  color: #898989;
}
.product.card .product_florist:has(.florist_delivery:not(.is_free_delivery)) .florist_name {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: -10px;
}

.other_designs .product_florist a {
  max-width: 55% !important;
}

@media (min-width: 320px) {
  .product.card {
    width: 46%;
    margin: 0 2% 30px 2%;
  }
  .product.card .product_info .product_florist {
    line-height: 1;
  }
}
@media (min-width: 960px) {
  .product.card .product_info .product_florist .florist_name {
    width: 78%;
  }
}
@media (min-width: 555px) {
  .product.card {
    width: 30%;
    margin-right: 1.66%;
    margin-left: 1.66%;
  }
}
@media (min-width: 768px) {
  .product.card .product_name a {
    max-width: 70%;
  }
  .product.card .product_price {
    color: #555;
    float: right;
    display: block;
    padding-left: 5px;
  }
  .product.card .product_info .product_florist .florist_name {
    font-size: 11px;
  }
  .product.card .product_info .product_florist .florist_delivery {
    font-size: 10px;
  }
}
@media (min-width: 1152px) {
  .product.card .product_name .product_price {
    font-size: 15px !important;
  }
}
.product-card-link {
  display: flex;
  flex-direction: inherit;
  height: calc(100% - 50px);
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: -999999px;
  top: 0;
  width: 100%;
  z-index: 1;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.toolbar-bottom {
  clear: both;
  padding-top: 10px;
}

.applied-filters-count {
  font-weight: 700;
}

.mobile-filters-toolbar {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background: #ffffff;
}
.mobile-filters-toolbar *,
.mobile-filters-toolbar *::before,
.mobile-filters-toolbar *::after {
  box-sizing: border-box;
}
.mobile-filters-toolbar .mobile-filters-container {
  width: 100%;
  min-height: 100%;
  max-width: 375px;
  padding: 20px 30px;
  position: fixed;
  z-index: 1060;
  background: #FFFFFF;
}
.mobile-filters-toolbar .mobile-filters-container .header {
  margin-bottom: 20px;
  height: 30px;
}
.mobile-filters-toolbar .mobile-filters-container .header .title {
  float: left;
  font-size: 20px;
}
.mobile-filters-toolbar .mobile-filters-container .header .close-mobile-filters-container {
  background: transparent;
  border: none;
  float: right;
  opacity: 1;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 84px - 80px);
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sorting-option-title {
  color: #555;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 18px;
  padding-bottom: 20px;
  margin-left: 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sorting-option-title .pull-left {
  font-weight: 700 !important;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile a {
  color: #333333;
  text-decoration: none;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-option-title {
  padding-top: 20px;
  margin-top: 0;
  margin-right: 13px;
  border-top: 1px solid #CFCFD4;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-option-title a {
  line-height: 16px;
  font-weight: 700 !important;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-option-title--postal-code .filter_option {
  display: flex;
  gap: 5px;
  text-decoration: none !important;
  margin-bottom: 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-option-title--postal-code .filter_option > span:not(.clear) {
  text-decoration: underline !important;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .mobile-filter-postal-code {
  display: grid;
  grid-template-columns: 70% 1fr;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: 13px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .mobile-filter-postal-code input[type=text] {
  margin-bottom: 0;
  width: 100%;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .mobile-filter-postal-code .error-msg {
  font-size: 14px;
  grid-row-start: 2;
  grid-column: span 2;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=text],
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=date] {
  width: 96%;
  border: 1px solid #CFCFD4;
  border-radius: 4px;
  padding: 8px 12px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=text],
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=date],
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .seemore-text {
  margin-bottom: 20px;
}
@supports (-webkit-touch-callout: none) {
  .mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=date] {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 34px;
    position: relative;
    width: 96%;
    background: #fff;
  }
  .mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile input[type=date]:after {
    content: "\f073";
    font-family: "FontAwesome";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px 25px 0 0;
    width: 1.2em;
    height: 1.2em;
    pointer-events: none;
  }
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options {
  padding: 0;
  margin: 0 0 20px 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label span {
  color: #464646;
  font-size: 14px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label input[disabled] ~ span,
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label input[disabled] ~ i,
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label input[disabled] ~ i:before,
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label input[disabled] ~ i:after {
  opacity: 0.5;
  cursor: not-allowed;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options li label:has(input[type=checkbox]:focus) > i.filterby-on-plp-checkbox::before {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 4px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options label {
  display: flex;
  margin: 0 0 20px 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options label .fa {
  width: 16px;
  margin-right: 14px;
  margin-top: -1px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options label .fa-square-o:before {
  background-color: transparent;
  border: 1px solid #aaa;
  border-radius: 2px;
  content: "";
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  padding: 7px;
  position: relative;
  vertical-align: middle;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options label .fa-check-square-o:before {
  background: #d8247f;
  border: 1px solid #d8247f;
  border-radius: 2px;
  content: "";
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  padding: 7px;
  position: relative;
  vertical-align: middle;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options label .fa-check-square-o:after {
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 12px;
  margin-left: 5px;
  margin-top: -15px;
  transform: rotate(45deg);
  width: 6px;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options--postal-code .filter_option {
  font-weight: 700 !important;
  display: flex;
  gap: 5px;
  text-decoration: none !important;
  margin-bottom: 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .sorting-options--postal-code .filter_option > span:not(.clear) {
  text-decoration: underline !important;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .seemore-text,
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .filter_option {
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
  text-transform: capitalize;
  margin-left: 0;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile .filter_option {
  cursor: pointer;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile button.seemore-text {
  background: none;
  border: none;
}
.mobile-filters-toolbar .mobile-filters-container .mobile-toolbar-overflow-wrapper .sidebar-mobile button.seemore-text:hover {
  text-decoration: none;
}
@media only screen and (max-width: 420px) {
  .mobile-filters-toolbar .mobile-filters-container {
    max-width: 100%;
  }
}
.mobile-filters-toolbar .mobile-toolbar-footer {
  display: flex;
  padding: 18px 20px;
  gap: 10px;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -4px 4px rgba(171, 171, 171, 0.25);
  width: 100%;
  max-width: 375px;
  background: #FFFFFF;
  z-index: 1070;
  left: 0;
}
@media only screen and (max-width: 420px) {
  .mobile-filters-toolbar .mobile-toolbar-footer {
    max-width: 100%;
  }
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container {
  flex: 1;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .clear-all-filters,
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .apply-filters {
  cursor: pointer;
  width: 100%;
  display: inline-block;
  font-weight: normal;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .clear-all-filters .applied-filters-count,
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .apply-filters .applied-filters-count {
  font-weight: normal;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .clear-all-filters:hover,
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .apply-filters:hover {
  text-decoration: none;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .clear-all-filters {
  border: 1px solid #d8247f;
  color: #d8247f;
  border-radius: 4px;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .clear-all-filters:hover {
  border: 1px solid #b51f6d;
  color: #b51f6d;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .apply-filters {
  border: 1px solid #d8247f;
  color: #FFFFFF;
  background-color: #d8247f;
  border-radius: 4px;
}
.mobile-filters-toolbar .mobile-toolbar-footer .footer-button-container .apply-filters:hover {
  background-color: #b51f6d;
  border: 1px solid #b51f6d;
}
.mobile-filters-toolbar .mobile-toolbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background: rgba(79, 79, 79, 0.79);
}
.mobile-filters-toolbar label.mobile-filters-radio-wrapper .mobile-filters-radio {
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 2px solid #3F3F3F;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
}
.mobile-filters-toolbar label.mobile-filters-radio-wrapper .mobile-filters-radio:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #3F3F3F;
  transform: scale(0);
  transition: all 0s ease;
  opacity: 0.08;
  pointer-events: none;
}
.mobile-filters-toolbar label.mobile-filters-radio-wrapper .mobile-filters-label {
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
}
.mobile-filters-toolbar label.mobile-filters-radio-wrapper input[type=radio]:checked + .mobile-filters-radio:after {
  transform: scale(1);
  transition: all 0s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}

#serpA-page .sticky,
#page-storefront .sticky {
  margin-top: 0 !important;
  position: fixed;
  top: 0;
  width: 100%;
}
#serpA-page .show-filter-container,
#page-storefront .show-filter-container {
  cursor: pointer;
  margin: 10px 0 20px 0;
  padding: 15px 0;
  text-align: center;
  background-color: #FBFBFB;
  border: 1px solid #EEE;
}
#serpA-page .show-filter-container i,
#page-storefront .show-filter-container i {
  font-size: 13px;
  color: #464646;
  margin-left: -5px;
}
#serpA-page .show-filter-container span,
#page-storefront .show-filter-container span {
  font-size: 13px;
  color: #464646;
  cursor: pointer;
  margin: 10px 0 20px 0;
  padding: 15px 0;
  text-align: center;
  background-color: #FBFBFB;
}

/**
 * PLP
 */
@media (min-width: 960px) {
  #serpA-page .mobile-filter-container,
  #serpA-page .mobile-filter-container.active,
  #serpA-page .show-filter-container,
  #serpA-page .mobile-filter-overlay,
  #serpA-page .mobile-filter-overlay.active {
    display: none;
  }
}

/**
 * Florist About Us Page
 */
@media (min-width: 1024px) {
  #page-storefront .mobile-filter-container,
  #page-storefront .mobile-filter-container.active,
  #page-storefront .show-filter-container,
  #page-storefront .mobile-filter-overlay,
  #page-storefront .mobile-filter-overlay.active {
    display: none;
  }
}

#search-shop-form {
  position: relative;
}

.search-formContainer {
  display: inline-block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.search-formContainer #pac-input {
  font-size: 13px;
}
@media (min-width: 768px) {
  .search-formContainer {
    width: 75%;
    margin: 0;
  }
}

.search-form_row {
  margin-bottom: 2.4285714286em;
}
.search-form_row label {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  color: rgb(73, 80, 76);
  font-size: 2em;
  display: block;
  margin: 0 0 1.02143em -0.07143em;
  cursor: text;
}

.search-btn-wrapper {
  width: 100%;
  display: inline-block;
  float: left;
}
@media (min-width: 768px) {
  .search-btn-wrapper {
    width: 23%;
    margin-left: 2%;
  }
}

.search-btn {
  background: #D8247F;
  border-color: #D8247F;
  font-size: 18px;
  width: 100%;
  margin-top: 0;
  padding: 13px 0;
}
.search-btn:hover {
  background: #b51f6d;
  border-color: #b51f6d;
}

.dots {
  font-size: 8px;
  position: relative;
  width: 4em;
  height: 1em;
  padding: 5.5px 0;
  margin: -1px auto 0;
}
.dots .dot {
  top: 0.95em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  opacity: 0;
}
.dots .dot:nth-child(1) {
  left: 0;
}
.dots .dot:nth-child(2) {
  left: 1.5em;
  animation-delay: 0.2s;
}
.dots .dot:nth-child(3) {
  left: 3em;
  animation-delay: 0.5s;
}

.pac-container {
  box-sizing: initial;
  border: 1px solid #c7c7c7;
  border-bottom: 2px solid #E12F95;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  border-radius: 0;
  margin-top: -2px;
}
.pac-container:after {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  content: "Powered by Google";
  padding: 7px 7px;
  font-size: 12px;
  line-height: 1.6;
  color: #464646;
  background: none !important;
}

.pac-item {
  padding: 14.5px 25px 14.5px 20px;
  line-height: 1.2;
  border-top: 1px solid #DCDCDC;
  color: #464646;
}
.pac-item:hover, .pac-item.pac-item-selected {
  background: #f7f7f7;
}
.pac-item:first-of-type {
  border-top: 0;
}
.pac-item:last-of-type {
  border-bottom: 1px solid #dcdcdc;
}
.pac-item .pac-icon {
  display: none;
}
.pac-item .pac-item-query {
  font-size: 12px;
  color: #464646;
}

@media (min-height: 321px) {
  .pac-item {
    font-size: 13px;
  }
  .pac-item .pac-item-query {
    font-size: 13px;
  }
}
@media (min-width: 768px) {
  .search-btn {
    font-size: 1.44286em;
  }
}
@media (min-width: 960px) {
  .dots {
    padding: 9px 0;
  }
}
.breadcrumbs li {
  font-size: 1.0714285714em;
  color: #726c71;
  display: inline-block;
}
.breadcrumbs li::after {
  content: " >";
}
.breadcrumbs li:last-child::after {
  display: none;
}
.breadcrumbs li a {
  color: #726c71;
}

.pdp-breadcrumbs-wrapper {
  padding: 10px 1.0714285714em 0 1.0714285714em;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper {
    padding: 0 2.4285714286em;
    margin-bottom: -3.4285714286em;
  }
}
@media (min-width: 1152px) {
  .pdp-breadcrumbs-wrapper {
    padding: 0 4.5714285714em;
  }
}
.pdp-breadcrumbs-wrapper .breadcrumbs {
  max-width: 500px;
  margin: auto;
  display: block;
  position: static;
  padding: 0;
}
.pdp-breadcrumbs-wrapper .breadcrumbs li {
  font-size: 12px;
}
@media (min-width: 768px) {
  .pdp-breadcrumbs-wrapper .breadcrumbs {
    padding: 2em 2.4285714286em 20px 2.4285714286em;
    margin: 0;
    max-width: 1040px;
  }
  .pdp-breadcrumbs-wrapper .breadcrumbs li {
    font-size: 15px;
  }
}
@media (min-width: 1152px) {
  .pdp-breadcrumbs-wrapper .breadcrumbs {
    margin: 0 auto;
    max-width: 1040px;
  }
  .pdp-breadcrumbs-wrapper .breadcrumbs li {
    font-size: 15px;
  }
}

i.fa.fa-google-white {
  width: 24px;
  height: 24px;
  background-image: url("../../../../../../../skin/frontend/base/default/images/google-icon-white.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 24px 24px;
}

.store.head .store_content {
  padding: 1.5em 1.5em 1.3571428571em;
  text-align: center;
  position: relative;
}
.store.head .store_logo,
.store.head .store_info {
  position: relative;
  z-index: 1;
}
.store.head .store_info .storeRating {
  margin-bottom: 0.6428571429em;
}
.store.head .store_info .storeRating img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.2142857143em;
  width: 12.1428571429em;
}
.store.head .store_info .storeRating p {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 1.2142857143em;
}
.store.head .store_logo {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 16em;
  max-height: 16em;
  padding: 0.3571428571em;
  margin-bottom: 0.4285714286em;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;
}
.store.head .store_logo img {
  width: 100%;
  height: auto;
}
.store.head .socialLinks {
  display: none;
}
.store.head .store_name {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  margin-bottom: 0.0561797753em;
  font-size: 25px;
}
.store.head .store_location {
  font-size: 1.7142857143em;
  line-height: 1.40833em;
  max-width: 17.7083333333em;
  display: inline-block;
}
.store.head .store_location a {
  color: #fff;
}
.store.head .store_cover {
  background: #333;
  position: relative;
}
.store.head .store_cover::after {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0.8) 100%);
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.store.head .store_cover .store_logo img {
  border: solid 1px #999999;
}
.store.head .store_cover .store_name,
.store.head .store_cover .store_location {
  color: #fff;
}

@media (min-width: 768px) {
  .store.head .store_logo,
  .store.head .store_info {
    display: inline-block;
    vertical-align: bottom;
  }
  .store.head .socialLinks__item {
    display: inline-block;
    vertical-align: top;
    font-size: 2.02857em;
    margin-left: 0.1470588235em;
  }
  .store.head .socialLinks__item a {
    color: #FFF;
  }
  .store.head .socialLinks__item a:hover {
    text-decoration: none;
  }
  .store.head .store_content {
    padding: 6.4285714286em 2.4285714286em 1.1428571429em 2.4285714286em;
    text-align: left;
  }
  .store.head .store_content::after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: bottom;
  }
  .store.head .store_info {
    max-width: 67.5%;
    margin-top: 15px;
  }
  .store.head .store_info .storeRating {
    display: none;
  }
  .store.head .store_logo {
    width: 100%;
    max-width: 16.7857142857em;
    max-height: 16.7857142857em;
    padding: 0.7142857143em;
    border-radius: 4px;
    margin-bottom: 0px;
    margin-right: 2.2857142857em;
  }
  .store.head .store_name {
    font-size: 45px;
    margin-bottom: 0.125em;
  }
  .store.head .store_location {
    font-size: 1.1428571429em;
    line-height: 1.1875em;
    max-width: 19.625em;
  }
}
@media (min-width: 960px) {
  .store.head .socialLinks {
    position: absolute;
    bottom: 2.3571428571em;
    right: 2.4285714286em;
    z-index: 1;
    display: block;
  }
}
@media (min-width: 1152px) {
  .store.head .socialLinks {
    right: 4.5714285714em;
  }
  .store.head .store_content {
    padding: 6.4285714286em 4.5714285714em 1.1428571429em 4.5714285714em;
  }
}

.tabs-nav {
  background: #efefef;
  border: 1px solid #eee;
  border-bottom: 0;
}
.tabs-nav li {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  float: left;
  width: 33.333333%;
  height: 3.442857em;
  font-size: 1.9142857143em;
  text-align: center;
  color: #333;
  line-height: 1.2;
  cursor: pointer;
}
.tabs-nav li.active {
  background: #fff;
}
.tabs-nav li.active a {
  cursor: pointer;
}
.tabs-nav li:hover {
  background: #fff;
}
.tabs-nav li:hover a {
  text-decoration: none;
  cursor: pointer;
}
.tabs-nav li a {
  padding: 0.80833em 1.625em 0.8166666667em 1.625em;
  display: block;
  color: #333;
}
.tabs-nav .badge {
  background: #fff;
  font-size: 0.75em;
  color: #333;
  padding: 0.0833333333em 0.5833333333em;
  border-radius: 50%;
}

.tab-pane {
  display: none;
  padding: 10px 5px 20px;
}
.tab-pane.active {
  display: block;
}

@media (min-width: 768px) {
  .tabs-nav li {
    font-size: 1.1428571429em;
    width: auto;
    text-align: left;
  }
  .tabs-nav li a {
    padding: 1.2083333333em 1.625em 1.4166666667em 1.625em;
  }
}
@media (min-width: 1152px) {
  .tabs-content {
    padding: 1.2142857143em 2.2857142857em;
    border: 1px solid #eee;
  }
}
.storeDelegate {
  text-align: center;
}
.storeDelegate .delegate_name,
.storeDelegate .delegate_position {
  font-size: 1.7142857143em;
  line-height: 1.1666666667em;
  color: #8e8e8e;
}
.storeDelegate .delegate_position {
  font-style: italic;
  margin-bottom: 0.5em;
}
.storeDelegate .delegate_photo {
  width: 62.8571428571%;
  height: 62.8571428571%;
  margin-bottom: 6.0714285714%;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}
.storeDelegate .delegate_photo img {
  width: 100%;
  height: auto;
}
.storeDelegate .storeRating {
  color: #8e8e8e;
  font-size: 1.2142857143em;
}
.storeDelegate .storeRating img {
  display: inline-block;
  max-height: 1.7647058824em;
  margin-bottom: 2.1428571429%;
}

@media (min-width: 768px) {
  .storeDelegate .delegate_name,
  .storeDelegate .delegate_position {
    font-size: 1.1428571429em;
    line-height: 1.1875em;
  }
  .storeDelegate .delegate_position {
    margin-bottom: 0.5em;
  }
  .storeDelegate .delegate_photo {
    width: 9.4285714286em;
    height: 9.4285714286em;
    margin-bottom: 0.9285714286em;
  }
  .storeDelegate .storeRating {
    font-size: 0.7857142857em;
  }
  .storeDelegate .storeRating img {
    max-height: 1.8181818182em;
    margin-bottom: 0.4545454545em;
  }
}
.store.list-item .store_header {
  margin-bottom: 1.0714285714em;
  overflow: hidden;
}
.store.list-item .store_avatar {
  display: none;
  width: 4.5em;
  height: 4.5em;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.7142857143em;
}
.store.list-item .store_avatar img {
  height: auto;
  width: 100%;
  background-size: cover;
}
@media (min-width: 768px) {
  .store.list-item .store_avatar {
    display: inline-block;
    vertical-align: top;
  }
}
.store.list-item .store_info {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  display: inline-block;
  vertical-align: top;
  max-width: 66.66666%;
  color: #666;
  margin-top: 0.2em;
}
.store.list-item .store_name {
  display: inline-block;
  margin-bottom: 0.37241em;
  color: #333;
  font-size: 25px;
}
.store.list-item .store_name:hover {
  text-decoration: none;
}
.store.list-item .store_details li {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 16px;
  margin-right: 0.5625em;
  margin-bottom: 5px;
  vertical-align: middle;
}
.store.list-item .store_details li.storeRating img {
  height: 1.25em;
}
.store.list-item .store_details li.storeRating .rating-box {
  margin-top: 2px;
}
.store.list-item .store_storeLink {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  float: left;
  padding: 0.875em 2.1875em 0.9375em 1.875em;
  border: 1px solid #638c1c;
  font-size: 13px;
  text-transform: uppercase;
  color: #638c1c;
  border-radius: 4px;
  margin-top: 0.5em;
  text-decoration: none;
}
.store.list-item .store_storeLink:hover {
  color: #FFF;
  background: #638c1c;
}
@media (min-width: 500px) {
  .store.list-item .store_storeLink {
    float: right;
    max-width: 33.3333%;
  }
}
@media (min-width: 768px) {
  .store.list-item .store_storeLink {
    font-size: 1.1428571429em;
  }
}
.store.list-item .store_products {
  text-align: justify !important;
  text-justify: distribute-all-lines;
  margin-bottom: 1.0714285714em;
}
.store.list-item .store_products > * {
  text-align: left;
}
.store.list-item .store_products:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.store.list-item .otherProducts-designs {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 11px;
}
.store.list-item .otherProducts-designs:hover .otherProducts-countWrapper {
  transform: scale(1.1) translate(-45%, -50%);
}
.store.list-item .otherProducts-designs img {
  width: 50%;
  padding: 1px;
  float: left;
  box-sizing: border-box;
}
.store.list-item .otherProducts_link {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}
.store.list-item .otherProducts-countWrapper {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  width: 6.3333333333em;
  height: 6.3333333333em;
  font-size: 0.8571428571em;
  color: #666;
  text-align: center;
  text-transform: uppercase;
  background: #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.store.list-item .otherProducts-countWrapper .otherProducts-count {
  display: table-cell;
  vertical-align: middle;
}
.store.list-item .otherProducts-countWrapper .otherProducts-countNum {
  display: block;
  font-size: 2.0833333333em;
  position: relative;
  left: -0.2em;
}
.store.list-item .otherProducts-countWrapper .otherProducts-countNum::before {
  content: "+";
  font-size: 0.84em;
  position: relative;
  top: -0.064516129em;
}
.store.list-item .otherProducts-title {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #638c1c;
  font-size: 1.1428571429em;
}
.store.list-item .otherProducts-title a {
  color: #638c1c;
}
.store.list-item .feedback_photo {
  width: 3em;
  float: left;
  margin: 0.2857142857em 0.5em 0px 0.2142857143em;
}
.store.list-item .feedback_photo img {
  width: 100%;
  border-radius: 4px;
}
.store.list-item .feedback_desc {
  font-size: 1.2142857143em;
  color: #333333;
  overflow: hidden;
}
.store.list-item .feedback_desc p {
  line-height: 1.4117647059em;
}

.subscribe {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  background: #d8227d;
  color: #fff;
  padding: 20px 2.42857em;
  text-align: center;
}

.subscribe-bannerContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-basis: auto;
  flex-shrink: 1;
  justify-content: center;
}

.subscribeForm_submit,
.subscribe_title,
.subscribe_text {
  color: #fff;
}

.subscribe_title {
  display: inline-block;
  vertical-align: top;
  font-family: "Bodoni", serif;
  padding: 5px 0;
  margin: 0 40px;
  font-size: 3em;
}

.subscribe_text {
  font-size: 0.8571428571em;
  line-height: 1.1666666667em;
  margin-bottom: 1.4166666667em;
}

.subscribeForm {
  padding: 5px 0;
  margin: 0 40px;
  display: inline-block;
  white-space: nowrap;
}

.subscribeForm_email {
  display: inline-block;
  vertical-align: top;
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  height: 3em;
  width: 55%;
  padding: 14px;
  margin-right: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.03);
}

.subscribeForm_submit {
  display: inline-block;
  vertical-align: top;
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  width: 35%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #FFF;
  border-radius: 4px;
  background: #d8227d;
  font-size: 1.5em;
  line-height: 1.4;
  padding-bottom: 5px;
}
.subscribeForm_submit.disabled, .subscribeForm_submit[disabled] {
  background: #5AC6CC;
  color: #E7E7E7;
}

.subscribeForm .subscribeForm_loader {
  display: none;
  font-size: 16pt;
  margin-left: -50px;
  position: absolute;
  color: #4B9FA5;
  margin-top: 10px;
}
.subscribeForm .subscribeForm_loader.loading {
  display: inherit;
}

@media (min-width: 496px) {
  .subscribeForm_email {
    width: 20em;
  }
  .subscribeForm_submit {
    font-size: 2.5em;
    width: 8em;
  }
}
@media (min-width: 768px) {
  .subscribe_title {
    font-size: 2em;
  }
  .subscribeForm_submit {
    font-size: 1.5em;
  }
}
@media (min-width: 960px) {
  .subscribe {
    padding: 25px 2.42857em;
  }
}
.policyList {
  color: #999;
  padding: 0px 1.1428571429em;
}
.policyList h5 {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  font-size: 2.1428571429em;
  margin-bottom: 0.2333333333em;
}
.policyList p {
  font-size: 1.2857142857em;
  line-height: 1.3888888889em;
}
.policyList .policy {
  margin-bottom: 3.2142857143em;
}

.switcher_title {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 1.85em;
  color: #666;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5714285714em;
}

.switcher_select {
  text-align: justify !important;
  text-justify: distribute-all-lines;
  text-align: center !important;
  margin-bottom: 10px;
}
.switcher_select > * {
  text-align: left;
}
.switcher_select:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.switcher_option {
  display: inline-block;
  vertical-align: top;
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 1.1em;
  text-align: center;
  text-transform: uppercase;
  color: #999;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 32.1666666667%;
  height: 6.6470588235em;
  box-sizing: border-box;
}
.switcher_option:hover {
  border: 2px solid #df4661;
  color: #df4661;
  background: #fff;
}
.switcher_option .switcher_option_btn_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}
.switcher_option .switcher_option_btn_wrapper:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.switcher_option .switcher_option_wrapper {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.9em;
  width: 100%;
  height: 100%;
  color: inherit;
  font: inherit;
  text-transform: uppercase;
}
.switcher_option input {
  display: none;
  line-height: 0px;
  font-size: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.switcher_option .date {
  text-transform: uppercase;
}
.switcher_option.active {
  color: #fff;
  border: 2px solid #df4661;
  background: #e56284;
}
.switcher_option.active label {
  cursor: default;
}
.switcher_option.disabled {
  color: #aaa;
  background: #eee;
}
.switcher_option.disabled:hover {
  background: #eee;
  border: 1px solid #aaa;
}
.switcher_option.disabled label,
.switcher_option.disabled input {
  cursor: not-allowed;
}
.switcher_option.disabled input {
  pointer-events: none;
}

.switcher_option .date_input,
#datepickr {
  display: block;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  background: none;
}

.order_delivery-selectDateWarning {
  display: none;
  font-size: 11.75px;
  text-align: center;
  color: red;
}
.order_delivery-selectDateWarning.isActive {
  display: block;
}

#switcher_option--calendar {
  width: 100%;
  height: 5.3em;
  margin-top: 1em;
}

@media (min-width: 568px) {
  #switcher_option--calendar {
    margin-top: 0;
    height: 6.64706em;
  }
}
@media (min-width: 768px) {
  .switcher_title {
    font-size: 1em;
    margin-bottom: 1.5555555556em;
  }
  .switcher_option,
  #switcher_option--calendar {
    height: 5.1818181818em;
  }
  .switcher_select .switcher_option {
    font-size: 0.9em;
  }
  .switcher_option .switcher_option_wrapper {
    font-size: 1.2em;
  }
}
@media (max-width: 768px) {
  .switcher_option .switcher_option_wrapper {
    font-size: 1.9em !important;
  }
}
.order {
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.order_header {
  display: none;
}

.order_modification {
  background: #f5f5f5;
  padding: 1.5em 1.08571em 1.6142857143em 1.08571em;
  margin-bottom: 1.2857142857em;
}
.order_modification label {
  border-radius: 3px;
}
.order_modification .switcher {
  margin-bottom: 0.3571428571em;
}
.order_modification .modification_price {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  font-size: 3.8571428571em;
  color: #666;
  text-align: center;
  margin-bottom: 0.3148148148em;
  transition: color 0.35s ease;
}
.order_modification .modification_price.isChanged {
  color: #00B551;
  transition: color 0.35s ease;
}
.order_modification .holidayPricingAlert--inline {
  display: none;
  padding-bottom: 10px;
  text-align: center;
  font-size: 11.75px;
  color: #d84c76;
}
.order_modification .holidayPricingAlert--inline.isActive {
  display: block;
}
.order_modification .modification_desc {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 1.85em;
  color: #666;
  margin: 0.5em 0.1875em 0;
  line-height: 1.2;
}
.order_modification .modification_desc li {
  display: none;
}
.order_modification .modification_desc .active {
  display: block;
}
.order_modification .modification_desc.isChanged {
  color: #00B551;
  transition: color 0.35s ease;
}

.order_delivery {
  padding: 0px 1.08571em;
  margin-bottom: 1.4285714286em;
}
.order_delivery .switcher_option .weekday,
.order_delivery .switcher_option .date {
  display: block;
  text-transform: uppercase;
}
.order_delivery .switcher_option .weekday {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
}
.order_delivery .switcher_option .date {
  text-transform: capitalize;
}
.order_delivery .switcher_option_wrapper {
  font-size: 1.7em;
}
.order_delivery .delivery_details {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 1.75em;
  line-height: 1.8235294118em;
  color: #999;
  padding-bottom: 1.7058823529em;
  margin-bottom: 1.2941176471em;
  border-bottom: 1px solid #ccc;
}
.order_delivery .delivery_details #delivery-fee-widget .delivery-fee-wrapper span {
  vertical-align: middle;
  line-height: 30px;
  font-size: 12px;
}
.order_delivery .delivery_details #delivery-fee-widget .add-zip {
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  padding: 0;
  margin: 0 0 0 10px;
  border: 0;
}
.order_delivery .delivery_details #delivery-fee-widget {
  font-size: 1.8823529412em;
}
.order_delivery .delivery_details a {
  display: inline-block;
  margin-top: 2px;
  margin-left: 5px;
  color: #d8227d;
}
.order_delivery .datepickr-wrapper {
  height: auto;
}
.order_delivery .datepickr-calendar {
  right: 0;
  bottom: 8.5em;
  left: 0;
  padding: 0;
  margin: 0 auto;
}

.zip-check {
  display: none;
  vertical-align: top;
  margin-left: 10px;
}
.zip-check input {
  padding: 7px 6px 8px;
  width: 80px;
  vertical-align: top;
  border: 1px solid #aaaaaa;
  border-radius: 2px;
}
.zip-check .check-btn {
  display: inline-block;
  border: 1px solid #d8227d;
  color: #d8227d;
  padding: 0px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.zip-check .check-btn:hover {
  background: #d8227d;
  color: white;
}

.change-zip {
  vertical-align: top;
}

.delivery-fee-wrapper {
  display: inline;
}
.delivery-fee-wrapper span {
  display: inline-block;
}

.invalid-zip-error {
  display: none;
  font-size: 12px;
  text-align: center;
  color: red;
}

.delivery_details span {
  vertical-align: top;
  line-height: 2.1;
}

.order_footer {
  padding: 0 1.08571em;
}

.order_submitBtn {
  width: 100%;
  min-height: 4em;
  margin-bottom: 1em;
  padding: 0.6153846154em 0.3076923077em 0.6923076923em;
  border-radius: 4px;
  font-size: 2.0571428571em;
  text-transform: uppercase;
  background: #00b551;
  border: solid 1px #00b551;
}
.order_submitBtn-unavailable {
  color: #fff;
  background: #03A24A;
}
.order_submitBtn:hover {
  color: #fff;
  background: #03A24A;
}

.order_delivery-selectDateWarning {
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
}

#delivery-timer-widget {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #666;
}
#delivery-timer-widget span:nth-child(1) {
  color: #89C520;
}
#delivery-timer-widget i {
  margin-right: 5px;
}
#delivery-timer-widget span span {
  font-weight: 800;
}

#reviewStep .delivery-info-block a {
  display: inline-block;
}

#reviewStep .delivery-info-block a .datepickr-wrapper {
  display: block;
}

#reviewStep .delivery-info-block a #datepickr {
  font-size: 0;
}

@media (min-width: 568px) {
  .order_delivery .switcher_option,
  #switcher_option--calendar {
    width: 23.6666666667%;
  }
  .order_delivery .datepickr-calendar {
    left: auto;
    bottom: 5em;
    overflow: show;
  }
}
@media (min-width: 768px) {
  .order_header {
    display: block;
    padding: 1.1428571429em 0.7142857143em;
  }
  .order_header .product_name {
    font-size: 1.7857142857em;
    text-align: center;
  }
  .order_modification {
    padding: 1.1428571429em 1.5714285714em 1.3571428571em 1.5714285714em;
  }
  .order_modification .modification_price {
    font-size: 2.3571428571em;
  }
  .order_modification .modification_desc {
    font-size: 0.7857142857em;
    margin: 0;
  }
  .order_delivery {
    padding: 0 1.5714285714em;
    margin-bottom: 1.2857142857em;
  }
  .order_footer {
    padding: 0 1.5714285714em 1.5714285714em;
  }
  .order_submitBtn {
    font-size: 1.1428571429em;
    padding: 0.6875em 0.3125em 0.75em 0.3125em;
    min-height: 3.5em;
    margin-bottom: 0;
    padding: 0.6153846154em 0.3076923077em 0.6923076923em;
  }
  .order_delivery .switcher_option_wrapper {
    font-size: 1em;
  }
  .order_delivery .switcher_option_wrapper.calendar {
    font-size: 0.91em;
  }
  .order_modification .modification_desc,
  .order_delivery .delivery_details {
    font-size: 0.85em;
    line-height: 28px;
  }
  .delivery_details {
    padding-bottom: 1.35294em;
    margin-bottom: 1.64706em;
  }
  .delivery_details .pickup-only i.pickup-only-icon {
    background: url(../../../images/icon-pickup.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 18px;
    height: 24px;
    top: 8px;
    position: relative;
  }
  .delivery_details .pickup-only .pickup-only-label {
    margin-left: 10px;
    margin-top: 8px;
    position: absolute;
  }
  .order_delivery .delivery_details a {
    margin-top: -2px;
  }
}
@media (min-width: 960px) {
  .order_delivery .switcher_option_wrapper.calendar {
    font-size: 1em;
  }
}
@media (min-width: 320px) {
  .product_alert_container {
    color: #666;
    margin: 20px 0 25px 0;
    font-size: 13px;
  }
  .product_alert_container p {
    text-align: center;
  }
  .product_alert_container p span {
    font-weight: bold;
    font-style: italic;
  }
}
@media (min-width: 768px) {
  .product_alert_container {
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .product_alert_container p span {
    display: block;
    margin-bottom: 10px;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog.modal {
  overflow: hidden;
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog.modal .close {
  display: none;
  opacity: 0.7;
  font-size: 30px;
  margin-top: -8px;
  margin-right: -8px;
  font-weight: 100;
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog.modal .close:hover {
  opacity: 1;
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog.modal .close {
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 150;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-dialog {
  width: 743px;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-dialog {
    width: 100%;
    margin: 0;
    position: fixed;
    bottom: 60px;
  }
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-dialog {
    position: relative;
    margin-top: 45px;
    margin-bottom: 55px;
    bottom: auto;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-content-inner {
  margin-top: 0;
  overflow: hidden;
  height: auto;
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-content-inner {
    height: 100vh;
    overflow-y: scroll;
    margin-top: 27px;
    padding-bottom: 130px;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: rgba(9, 1, 28, 0.87);
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-header {
  padding: 15px 25px 8px 52px;
  border: none;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-header {
    padding: 15px 15px 0 3vw;
  }
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-header {
    padding: 0px 15px 0 3vw;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-header .close {
  display: block;
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-header .close {
    display: none;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body {
  padding: 0 51px 20px;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body {
    padding: 8px 3vw 20px 3vw;
  }
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .left-product {
    padding-right: 1.5vw;
  }
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .left-product .selected-product-title {
    left: 1.5vw;
  }
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .right-product {
    padding-left: 1.5vw;
  }
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .right-product .selected-product-title {
    left: -1.5vw;
  }
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .products-cols {
    margin-left: 0;
    margin-right: 0;
  }
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .left-product, #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .right-product {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-order-now, #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-update-type {
    padding: 8px 10px;
    margin: 0;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-name {
  text-align: left;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-name {
    padding: 8px 0 0 10px;
    margin: 0;
    width: 56%;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-price {
  text-align: right;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-body .main-product-container .main-product-price {
    padding: 8px 10px 0 0;
    margin: 0;
    width: 44%;
  }
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-footer {
  padding: 0 51px 19px;
  margin-top: 0;
  border-top: none;
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-footer .popup-cancel {
  color: #000000;
  opacity: 0.7;
}
#chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-footer .popup-cancel:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-footer {
    padding: 11px 3vw;
  }
}
@media (max-width: 421px) {
  #chooseProductToOrderDialogWrapper #chooseProductToOrderDialog .modal-footer {
    padding: 11px 3vw;
  }
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper .btn.popup-order-now, #chooseProductToOrderDialogWrapper .btn.popup-cancel {
    padding: 10px 20px;
  }
}
@media (max-width: 420px) {
  #chooseProductToOrderDialogWrapper .btn.popup-order-now {
    width: 75%;
  }
  #chooseProductToOrderDialogWrapper .btn.popup-cancel {
    padding: 10px;
  }
}
#chooseProductToOrderDialogWrapper .modal-footer-mobile {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 1050;
  box-shadow: 0 -4px 4px rgba(171, 171, 171, 0.25);
}
@media (max-width: 767px) {
  #chooseProductToOrderDialogWrapper .modal-footer.modal-footer-mobile {
    display: block;
  }
  #chooseProductToOrderDialogWrapper .modal-footer {
    display: none;
  }
}
@media (min-width: 768px) {
  #chooseProductToOrderDialogWrapper .vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  #chooseProductToOrderDialogWrapper .vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
  }
  #chooseProductToOrderDialogWrapper .modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    max-width: inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
  }
}

.main-product-container {
  background: #FFFFFF;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  padding: 1px;
}
.main-product-container .main-product-image {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.main-product-container .main-product-name-and-price {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #454545;
}
.main-product-container .main-product-name {
  padding-left: 20px;
  margin-top: 15px;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
}
.main-product-container .main-product-price {
  padding-right: 20px;
  margin-top: 15px;
  float: right;
  text-align: right;
  width: 40%;
}
.main-product-container .main-product-update-type {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #454545;
  margin: 8px 20px 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
}
.main-product-container .main-product-order-now {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #454545;
  margin: 15px 20px;
}
.main-product-container .main-product-order-now .get-it-on-text {
  font-weight: 700 !important;
}
.main-product-container .main-product-order-now .truck-icon-cell {
  vertical-align: middle;
}
.main-product-container .main-product-order-now .get-it-on-cell {
  padding-left: 8px;
}
.main-product-container .main-product-order-now .get-it-on-cell .delivery-date {
  white-space: nowrap;
}
.main-product-container .tooltip-inner {
  color: #181414;
  background-color: #cdcfd4;
  font-weight: 200;
}
.main-product-container .tooltip.top .tooltip-arrow,
.main-product-container .tooltip.top-left .tooltip-arrow,
.main-product-container .tooltip.top-right .tooltip-arrow {
  border-top-color: #cdcfd4;
}

#chooseProductToOrderDialog .main-product-container:hover, #chooseProductToOrderDialog .main-product-container.active-product {
  border: 2px solid #D8247F;
  padding: 0;
}
@media (max-width: 767px) {
  #chooseProductToOrderDialog .main-product-container:hover {
    border: 1px solid #CDCDCD;
    padding: 1px;
  }
  #chooseProductToOrderDialog .main-product-container:hover.active-product {
    border: 2px solid #D8247F;
    padding: 0;
  }
}

#chooseProductToOrderDialog .crossell-info {
  padding-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

#chooseProductToOrderDialog .right-product .main-product-container {
  border-radius: 4px;
}

#chooseProductToOrderDialog .selected-product-title {
  background: #FFFFFF;
  border: 2px solid #D8247F;
  border-radius: 27px;
  text-align: center;
  width: 105px;
  height: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  margin: auto;
  z-index: 100;
}

#chooseProductToOrderDialog .selected-product-title .check-selected {
  color: #D8247F;
}

#chooseProductToOrderDialog .block-copy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: rgba(9, 1, 28, 0.87);
  margin-bottom: 25px;
}

#chooseProductToOrderDialog .delivery-date-in-past {
  color: #FF0000;
  font-weight: 700;
}

@media (max-width: 767px) {
  #chooseProductToOrderDialog img {
    width: 100%;
  }
  #chooseProductToOrderDialog .left-product {
    margin-bottom: 0;
  }
  #chooseProductToOrderDialog .col-xs-12 {
    width: 50%;
  }
}
@media (max-width: 420px) {
  #chooseProductToOrderDialog .col-xs-12 {
    width: 100%;
  }
  #chooseProductToOrderDialog .left-product {
    margin-bottom: 30px;
  }
}
.reviews-grid {
  text-align: justify !important;
  text-justify: distribute-all-lines;
}
.reviews-grid > * {
  text-align: left;
}
.reviews-grid:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.reviews-grid .review {
  margin-bottom: 2.7142857143em;
}
.reviews-grid .review:last-child {
  margin-bottom: 0;
}

.review {
  color: #555;
}

.review_header {
  margin-bottom: 0.8571428571em;
}

.review_author {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  display: block;
  text-align: center;
  font-size: 2.2857142857em;
}

.review_rating {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
.review_rating img {
  display: inline;
  height: 1.9285714286em;
}

.review_data {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 1.82857em;
  text-align: center;
  color: #777;
}

.review_text {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  max-width: 260px;
  padding: 0 1em 20px;
  margin: 0 auto;
  font-size: 2.2285714286em;
  line-height: 1.4em;
  border-bottom: 1px solid #eaeaea;
}
.review_text p {
  line-height: 1.4166666667em;
}

@media (min-width: 420px) {
  .review_text {
    font-size: 1.928571em;
    max-width: 450px;
  }
}
@media (min-width: 768px) {
  .reviews-grid .review {
    display: inline-block;
    vertical-align: top;
    width: 46.0909090909%;
  }
  .reviews-grid .review {
    margin-bottom: 2.2142857143em;
  }
  .review_header {
    margin-bottom: 0.6428571429em;
  }
  .review_author {
    display: inline;
    font-size: 1.1428571429em;
    margin-right: 0.3125em;
  }
  .review_rating {
    display: inline-block;
    margin: 0;
    text-align: left;
  }
  .review_rating img {
    height: 1.0714285714em;
  }
  .review_data {
    font-size: 0.77143em;
    margin-top: 1.125em;
    float: none;
    text-align: left;
  }
  .review_text {
    max-width: none;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 1.05714em;
    line-height: 1.4;
  }
}
.rating-box {
  display: inline-block;
  background: url("../../../../../../../js/jquery/raty/images/star-mini-empty.png") repeat-x;
  background-size: 16px;
  position: relative;
  width: 79px;
  height: 16px;
  margin-right: 3px;
}

.rating-box .rating {
  height: 16px;
  background: url(../../../../../../../js/jquery/raty/images/star-on.png) repeat-x;
  background-size: 16px;
}

.reviewCount {
  display: inline-block;
  margin-top: 2px;
  vertical-align: top;
}

.social-icons {
  margin-top: 10px;
  color: #555;
}
.social-icons a img {
  display: inline-block;
}

.backToTop {
  display: inline-block;
  padding: 30px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.backToTop:hover {
  text-decoration: none;
}

.black-vertical-logo-mp {
  width: 160px;
  height: 72px;
}

.backToTop-logo {
  margin-bottom: 15px;
  width: 131px;
  height: 80px;
}

.backToTop-text {
  display: block;
  color: #666;
  text-align: center;
  text-transform: uppercase;
  margin-top: 25px;
}

.intro {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: height 1s 0.3s;
  min-height: 530px;
}

.intro_title,
.intro_subtitle {
  color: #fff;
  position: relative;
}

.intro_title {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  font-size: 35px;
  margin-bottom: 5px;
}

.intro_subtitle {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  margin-bottom: 1.05em;
  font-size: 2.1928571429em;
}

.intro_content {
  box-sizing: border-box;
  padding-top: 13.4285714286em;
  padding-bottom: 10.4285714286em;
  text-align: center;
}

.intro_imgCredit {
  display: none;
}
.intro_imgCredit img {
  position: absolute;
  bottom: 30px;
  max-width: 165px;
  margin-left: 15px;
}

.coverPhoto-formWrapper {
  display: inline-block;
  width: 95.3333333333%;
  max-width: 550px;
  border-radius: 4px;
  box-shadow: 0em -14em 7.57143em 0.1em rgba(29, 30, 35, 0.4);
}

.coverPhoto-formContainer {
  box-sizing: border-box;
  padding: 2.8em 2.85714em;
  margin-bottom: 25px;
  border-radius: 4px;
  background: #fff;
  text-align: left;
}

.coverPhoto-formErrors,
.coverPhoto-formSuccess {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  font-size: 13px;
}

.coverPhoto-formErrors {
  border: 1px solid #eba19f;
  color: #a74945;
  background-color: #EED9D8;
}

.coverPhoto-formSuccess {
  border: 1px solid #79b941;
  color: #466a00;
  background-color: #d3eac1;
}

@media (min-width: 768px) {
  .intro {
    background-position: center center;
  }
  .intro_content {
    padding-top: 7.91429em;
    padding-bottom: 10.4285714286em;
  }
  .intro_title {
    font-size: 54px;
    margin-bottom: 0.0638297872em;
  }
  .intro_subtitle {
    font-size: 1.7357142857em;
    margin-bottom: 1.6296296296em;
  }
  .coverPhoto-formContainer {
    padding: 1.65714em 2.55714em;
  }
}
@media (min-width: 960px) {
  .intro_subtitle {
    margin-bottom: 0.92963em;
    font-size: 24px;
  }
  .intro_imgCredit {
    display: block;
  }
}
@media (min-width: 1280px) {
  .intro_content {
    padding-right: 0;
  }
}
.dropdown-nav {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  box-sizing: initial;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 30px;
  left: 47px;
  width: 250px;
  padding: 15px 15px 0 15px;
  background: #fff;
  border: 1px solid #ddd;
}
.dropdown-nav--visible {
  transition: all 0.25s ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.dropdown-nav--account-management {
  top: 40px;
  left: 244px;
}
.dropdown-nav__line {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}
.dropdown-nav__arrow-up {
  position: absolute;
  top: -15px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #DDD;
}
.dropdown-nav__arrow-up--overlay {
  position: absolute;
  top: -14px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FFF;
}
.dropdown-nav__name {
  font-family: "Bodoni", serif;
  letter-spacing: -0.5px;
  font-size: 24px;
}
.dropdown-nav__list {
  padding: 0 10px;
  font-size: 15px;
}
.dropdown-nav__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.dropdown-nav__link {
  color: #555;
}
.dropdown-nav__link .icon {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.dropdown-nav__link svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.dropdown-nav__link svg path {
  fill: #555;
}
.dropdown-nav__link span {
  margin-left: 5px;
}
.dropdown-nav__link:hover {
  color: #d8247f;
  text-decoration: none;
}
.dropdown-nav__link:hover svg path {
  fill: #d8247f;
}

.notification {
  display: none;
  position: relative;
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;
  background: #f1ffc9;
  border: 1px solid #92bd29;
  border-radius: 2px;
  box-sizing: border-box;
}
.notification__text {
  width: 90%;
  margin: 0;
  color: #555;
  font-size: 13px;
  line-height: 1.2;
}
.notification__close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #777;
  cursor: pointer;
}
.notification--visible {
  display: block;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
}
.notification--error {
  background: #ffebe5;
  border: 1px solid #e75f0b;
}
.notification--warning {
  background: #fffbe8;
  border: 1px solid #ddc237;
}

@media (min-width: 768px) {
  .notification {
    padding: 14px;
    font-size: 13px;
  }
}
.zip-code-banner {
  display: none;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
@media (min-width: 960px) {
  .zip-code-banner {
    display: block;
  }
}
.zip-code-banner .banner__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.zip-code-banner .banner__center {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding: 25px;
  background: #FFF;
  color: #333;
  border-radius: 5px;
}
.zip-code-banner .banner__header {
  margin-bottom: 20px;
  font-family: Bodoni, serif;
  font-size: 24px;
}
.zip-code-banner .banner__enter-zip {
  margin-bottom: 20px;
  font-family: "ProximaNova", sans-serif;
  font-size: 18px;
}
.zip-code-banner .banner__zip-input .delivery-zip-filter-btn {
  margin-left: 5px;
}
.zip-code-banner .banner__zip-input #pac-input-two {
  width: 200px;
}

#page-home .text-control {
  padding-bottom: 0;
}
#page-home .text-control input {
  z-index: 1;
}
#page-home .page_section_header .h1 {
  text-align: center;
}
#page-home .shop_name_link:hover {
  text-decoration: none;
}
#page-home .hero {
  background-size: cover !important;
  background-repeat: no-repeat;
}
#page-home .hero__content {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  text-align: center;
  background-size: cover;
}
@media screen and (max-width: 450px) {
  #page-home .hero {
    background-position: -168px;
  }
}
#page-home .hero {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  height: 475px;
}
#page-home .hero__content-wrapper {
  position: relative;
  flex-basis: 1040px;
  flex-shrink: 1;
  padding: 0 10px;
}
#page-home .hero__content {
  max-width: 290px;
  margin: 0 auto !important;
  padding: 50px 25px 15px 25px;
  text-align: center;
  color: #464646;
}
#page-home .hero__content p {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 13px;
}
#page-home .hero__content h1 {
  font-family: "Bodoni", serif;
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 1.2;
}
@media only screen and (min-width: 415px) {
  #page-home .hero__content {
    padding: 50px 50px 15px 50px;
  }
  #page-home .hero__content h1 {
    font-size: 22px;
  }
}
#page-home .hero__notification {
  display: block;
  margin: 10px 0 0 0;
  box-sizing: border-box;
}
#page-home .hero__content-header p {
  margin-bottom: 15px;
}
#page-home .hero__content-divider {
  width: 33%;
  margin: 20px auto;
  border-bottom: 1px solid #a7a7a7;
}
#page-home #content {
  margin-bottom: 50px;
}
#page-home .topShops {
  max-width: 1042px;
  padding: 0 5px;
  margin: 0 auto;
}
#page-home .search-form_row {
  height: auto;
  margin-bottom: 13.5px;
}
#page-home .search-form_row label {
  display: block;
  margin: 0 0 18.3px;
  line-height: 1.3;
  text-align: center;
  color: #D8247F;
  font-size: 20px;
  font-weight: 600;
}
#page-home .text-control input {
  padding: 14px 0.65714em 15.9px 32px;
  line-height: 1.2;
  font-size: 11px;
  color: #464646;
}
#page-home .text-control .searchIcon {
  top: 14px;
  left: 10px;
}
#page-home .page_section {
  margin-bottom: 3.6428571429em;
}
#page-home .page_section_header {
  padding: 0 2.1428571429em;
  margin: 45px 0;
}
#page-home .page_section_header .h1 {
  font-size: 22px;
}
#page-home .search-btn {
  font-family: "ProximaNova", sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  line-height: 1.32;
  border-radius: 2px;
}
@media screen and (min-width: 321px) {
  #page-home .text-control input {
    padding: 14px 0.65714em 15.9px 35px;
  }
}
@media (min-width: 768px) {
  #page-home .hero {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 650px;
  }
  #page-home .hero__content-wrapper {
    margin: 0 auto;
  }
  #page-home .hero__content h1 {
    font-size: 23px;
  }
  #page-home .page_section_header {
    margin: 55px 0;
  }
  #page-home .page_section_header .h1 {
    font-size: 25px;
  }
}
@media (min-width: 960px) {
  #page-home .hero {
    height: 500px;
  }
  #page-home .hero__content-wrapper {
    flex-shrink: 1;
    padding: 0 40px;
    margin: 0 auto;
  }
  #page-home .hero__content {
    max-width: 400px;
    float: right;
  }
  #page-home .hero__content h1 {
    font-size: 29px;
  }
  #page-home .search-form_row {
    margin-bottom: 20px;
  }
  #page-home .search-form_row label {
    margin-bottom: 21.6px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
  }
  #page-home .text-control input {
    font-size: 15px;
    padding: 16.5px 15px 18.5px 47.3px;
  }
  #page-home .text-control .searchIcon {
    top: 1.05em;
    left: 1.3em;
    max-width: 14px;
  }
  #page-home .search-btn {
    padding: 13px 0;
    font-size: 19px;
    line-height: 1.42;
  }
  #page-home .page_section_header .h1 {
    font-size: 30px;
  }
}

#page-storefront .page_header {
  background: #efefef;
  border: none;
  padding: 0px;
}
#page-storefront .tabs-content {
  padding: 0 !important;
  border: 0 !important;
}
#page-storefront .mobile-filter-overlay,
#page-storefront .mobile-filter-container {
  display: none;
}
#page-storefront .page_header {
  padding: 0;
  margin-bottom: 1.2142857143em;
}
#page-storefront #sidebar,
#page-storefront .collapse-info {
  display: none;
}
#page-storefront #main {
  margin: 0;
}
#page-storefront .tab-pane {
  font-size: 14px;
  line-height: 22px;
}
#page-storefront .tab-pane p {
  font-size: 14px;
  line-height: 22px;
  color: #333;
}
@media (min-width: 420px) {
  #page-storefront .tab-pane {
    font-size: 14px;
    line-height: 22px;
  }
}
#page-storefront .pagination {
  text-align: center;
}
@media (min-width: 768px) {
  #page-storefront .product-categories {
    display: none;
  }
  #page-storefront .page_header {
    margin-bottom: 2em;
    padding: 0px 2.4285714286em;
  }
  #page-storefront #content {
    padding: 0px 2.4285714286em;
  }
  #page-storefront .tab-pane {
    font-size: 14px;
    line-height: 22px;
  }
  #page-storefront .tab-pane p {
    font-size: 14px;
    line-height: 22px;
  }
  #page-storefront .storeDelegate {
    margin-bottom: 2.6428571429em;
    position: relative;
  }
  #page-storefront .pagination {
    float: right;
  }
}
@media (min-width: 1024px) {
  #page-storefront .tabs-nav {
    margin-left: 19.2857142857em;
  }
  #page-storefront .storeDelegate {
    margin-top: -5.2142857143em;
  }
  #page-storefront #main {
    margin-left: 19.2857142857em;
  }
  #page-storefront #sidebar {
    display: block;
  }
}
@media (min-width: 1152px) {
  #page-storefront #content,
  #page-storefront .page_header {
    padding: 0px 4.5714285714em;
  }
}
@media (min-width: 320px) {
  #serpA-page #sidebar,
  #serpA-page .page_header {
    display: none;
  }
  #serpA-page #main {
    margin-left: 0px;
  }
  #serpA-page .product-grid {
    width: 98%;
    margin: 0 auto;
  }
  #serpA-page .product-grid .pagination {
    text-align: center;
  }
  #serpA-page .product-grid .notification {
    margin: 20px 0;
  }
  #serpA-page .products_toolbar::after,
  #serpA-page .list_top::after,
  #serpA-page .list_bottom::after {
    content: "";
    clear: right;
    display: table;
    width: 100%;
  }
  #serpA-page .products_toolbar {
    font-size: 14px;
    padding: 2.2857142857em 1.0714285714em 0px 1.0714285714em;
    margin-bottom: 1.2142857143em;
  }
  #serpA-page #products_toolbar_filter {
    float: right;
    width: 15em;
  }
  #serpA-page .products_toolbar_title {
    font-size: 1.22857em;
    line-height: 1.25;
    margin-top: -3px;
  }
  #serpA-page .products_toolbar_title .today {
    font-weight: bold;
  }
  #serpA-page .product_alert {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    background-color: rgba(70, 70, 70, 0.8);
    padding-right: 1px;
    width: 100%;
    height: 30px;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_header {
    padding: 10px;
    color: #89C520;
    font-style: italic;
    font-weight: bold;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_body {
    opacity: 0;
    color: #FFF;
  }
  #serpA-page .bloom-seo-header {
    text-transform: uppercase;
    line-height: 27px;
    color: #333;
  }
  #serpA-page .bloom-seo-content {
    color: #555;
    font-size: 14px;
    line-height: 22px;
  }
  #serpA-page .bloom-seo-content a {
    color: #41858c;
  }
  #serpA-page .bloom-seo-bottom {
    margin-top: 3.57143em;
    padding: 0 2.42857em;
  }
}
@media only screen and (min-width: 320px) and (min-width: 768px) {
  #serpA-page .bloom-seo-bottom {
    padding: 0;
  }
}
@media (min-width: 320px) {
  #serpA-page .zipcode-value-input {
    color: #333;
    width: 110px;
    padding: 8px;
    font-size: 14px;
    outline: 0;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}
@media (min-width: 320px) {
  #serpA-page .delivery-zip-filter-btn {
    color: #d8247f;
    width: auto;
    padding: 8px 9px;
    font-size: 14px;
    background: transparent;
    border: 1px solid #d32375;
    border-radius: 4px;
    cursor: pointer;
  }
}
@media (min-width: 320px) {
  #serpA-page .clear-filter-btn {
    cursor: pointer;
    width: 100%;
    max-width: 175px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    background: transparent;
    border: 1px solid #333;
    border-radius: 3px;
  }
  #serpA-page .clear-filter-btn:hover {
    background: #eee;
  }
}
@media (min-width: 320px) {
  #serpA-page #delivery-calendar-button svg {
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 320px) {
  #serpA-page .delivery-date-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
  }
}
@media (min-width: 320px) {
  #serpA-page .delivery-date-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    text-align: center;
    padding: 9px 6px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 13px;
    line-height: 1.2;
  }
  #serpA-page .delivery-date-option:hover {
    border-color: #41858c;
  }
  #serpA-page .delivery-date-option:hover svg path {
    fill: #2B7176;
  }
  #serpA-page .delivery-date-option__svg {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0 auto 9px auto;
  }
  #serpA-page .delivery-date-option svg {
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }
  #serpA-page .delivery-date-option svg path {
    fill: #777;
  }
  #serpA-page .delivery-date-option--active {
    font-weight: bold;
    color: #555;
    border-bottom-color: #FFF;
  }
  #serpA-page .delivery-date-option--active:hover {
    border-color: #ccc;
    border-bottom-color: #FFF;
  }
  #serpA-page .delivery-date-option--cal-open {
    z-index: 1000;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (min-width: 320px) {
  #serpA-page .datepickr-wrapper {
    width: 100%;
  }
}
@media (min-width: 320px) {
  #serpA-page .datepickr-calendar {
    position: relative;
    width: 195px;
    padding: 2px;
    border-radius: 3px;
    margin-top: -1px;
  }
  #serpA-page .datepickr-calendar table {
    width: 100%;
  }
  #serpA-page .datepickr-calendar thead tr {
    font-weight: bold;
  }
  #serpA-page .datepickr-calendar .datepickr-current-month {
    font-size: 13px;
  }
  #serpA-page .datepickr-calendar .today {
    font-weight: bold;
  }
  #serpA-page .datepickr-calendar .datepickr-months {
    font-size: 100%;
    font-weight: bold;
  }
  #serpA-page .datepickr-calendar .datepickr-months span {
    padding: 0.5em 0.5em 0 0.5em;
  }
  #serpA-page .datepickr-calendar .datepickr-day {
    padding: 3px;
    font-size: 12px;
    border-radius: 1px;
  }
  #serpA-page .datepickr-calendar .datepickr-day:hover {
    background: #d8247f;
    color: #fff;
    font-weight: bold;
  }
}
@media (min-width: 320px) {
  #serpA-page .same-day-no-results h2 {
    font-size: 22px;
  }
  #serpA-page .same-day-no-results .no-arrangements-image img {
    margin: 0 auto;
    height: 125px;
    width: 125px;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
  }
  #serpA-page .same-day-no-results .try-different-zip {
    margin: 20px auto;
    padding: 10px 25px;
    background: #f7f7f7;
  }
  #serpA-page .same-day-no-results .try-different-zip p {
    font-size: 16px;
    color: #333;
  }
  #serpA-page .same-day-no-results .calendar-header {
    padding: 7px;
    background: #fff;
    font-weight: bold;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  #serpA-page .same-day-no-results .calendar-header span {
    margin-left: 5px;
  }
  #serpA-page .same-day-no-results .calendar-header svg {
    vertical-align: middle;
    margin-top: -3px;
    height: 20px;
    width: 20px;
  }
  #serpA-page .same-day-no-results .calendar-header svg path {
    fill: #555;
  }
  #serpA-page .same-day-no-results .clear-filter-btn {
    display: block;
    margin: 20px auto;
  }
  #serpA-page .same-day-no-results .datepickr-wrapper {
    width: auto;
    margin: 0 auto;
  }
  #serpA-page .same-day-no-results .datepickr-calendar {
    width: 220px;
    padding: 10px;
  }
  #serpA-page .same-day-no-results .datepickr-day {
    padding: 5px;
    font-size: 14px;
  }
}
@media (min-width: 450px) {
  #serpA-page .product_photo:hover .product_alert {
    opacity: 1;
  }
  #serpA-page .product_alert {
    opacity: 1;
    height: 40px;
  }
  #serpA-page .product_alert .product_alert_content {
    padding: 10px;
    margin-top: -2px;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_header {
    padding: 0 0 1px 0;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_body {
    padding: 1px 0 0 0;
    opacity: 1;
  }
}
@media only screen and (min-width: 555px) {
  #serpA-page .product-grid {
    width: 95%;
  }
}
@media (min-width: 768px) {
  #serpA-page .page_header {
    display: block;
  }
  #serpA-page .product-categories {
    display: none;
  }
  #serpA-page .products_toolbar {
    padding: 0 0.64286em 0;
  }
  #serpA-page .products_toolbar::after {
    display: none;
  }
  #serpA-page .list_top .pagination,
  #serpA-page .list_bottom .products_toolbar {
    display: none;
  }
  #serpA-page .products_toolbar_title {
    font-size: 1.4285714286em;
  }
  #serpA-page #content {
    padding: 0 2.4285714286em;
  }
  #serpA-page .product-grid {
    width: 100%;
  }
  #serpA-page .pagination {
    float: right;
  }
  #serpA-page .product_alert {
    height: 40px;
  }
  #serpA-page .product_alert .product_alert_content {
    font-size: 13px;
    padding: 8px;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_body {
    opacity: 1;
  }
}
@media (min-width: 960px) {
  #serpA-page #sidebar {
    display: block;
  }
  #serpA-page #main {
    margin-left: 15em;
  }
  #serpA-page .product_alert {
    height: 50px;
    opacity: 0;
  }
  #serpA-page .product_alert .product_alert_content {
    margin-top: 1px;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_header {
    padding: 2px 0 2px 0;
  }
  #serpA-page .product_alert .product_alert_content .product_alert_body {
    padding: 2px 0 0 0;
  }
}
@media (min-width: 1152px) {
  #serpA-page #content {
    padding: 0px 4.5714285714em;
  }
}
#serpB-page #sidebar,
#serpB-page .breadcrumbs,
#serpB-page .collapse-info,
#serpB-page .headerBorder {
  display: none;
}
#serpB-page #main,
#serpB-page .headerWrapper {
  padding: 0px 1.0714285714em;
  margin: 0 auto;
}
#serpB-page .headerWrapper {
  position: relative;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  max-width: 1910px;
}
@media (max-width: 768px) {
  #serpB-page .headerWrapper {
    display: none;
  }
}
#serpB-page .page_header_title {
  color: #333;
}
#serpB-page .page_header {
  border: 0;
}
#serpB-page .storeList .store.list {
  border: 0;
  padding: 0;
  margin-bottom: 0;
}
#serpB-page .product-categories {
  margin-bottom: 1.9285714286em;
}
#serpB-page #products_toolbar_filter {
  float: right;
  width: 15em;
}
#serpB-page .otherProducts a:hover {
  text-decoration: none;
}
#serpB-page .product.card {
  width: 49%;
}
#serpB-page .product.card:last-of-type {
  margin-left: 2%;
}
#serpB-page .product.card .product_florist {
  display: block;
  margin: 0.4em 0;
  font-size: 13px;
  line-height: 1.25;
  color: #999;
}
#serpB-page .pagination {
  text-align: center;
}
#serpB-page .products_toolbar::after,
#serpB-page .list_top::after,
#serpB-page .list_bottom::after {
  content: "";
  clear: right;
  display: table;
  width: 100%;
}
#serpB-page .products_toolbar {
  padding: 2.2857142857em 0 1em 0;
  margin-bottom: 1.2142857143em;
  font-size: 13px;
  line-height: 1.2;
}
#serpB-page #products_toolbar_filter {
  float: right;
  width: 15em;
}
#serpB-page .select_control {
  font-size: 0.6em;
}
#serpB-page .products_toolbar_title {
  font-size: 1.22857em;
}
@media (min-width: 768px) {
  #serpB-page #main,
  #serpB-page .headerWrapper {
    padding: 0px 2.4285714286em;
  }
  #serpB-page .headerWrapper {
    display: block;
    min-height: 200px;
    margin-bottom: 2em;
  }
  #serpB-page .headerBorder {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0%, transparent 100%);
  }
  #serpB-page .page_header {
    z-index: 2;
    padding-top: 40px;
  }
  #serpB-page .page_header_title,
  #serpB-page .collapse-info p {
    color: #fff;
  }
  #serpB-page .page_header_title {
    font-size: 36px;
    margin-bottom: 0.98235em;
  }
  #serpB-page .storeList .store.list-item {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
  #serpB-page .storeList .store.list-item .store_header {
    padding: 0 0.64286em 0;
  }
  #serpB-page .storeList .store.list-item:last-of-type {
    border-bottom: none;
    margin-bottom: 0px;
  }
  #serpB-page .icon-deliveryTruck {
    margin-right: 4px;
  }
  #serpB-page .breadcrumbs,
  #serpB-page .collapse-info,
  #serpB-page .store_header,
  #serpB-page .store_footer {
    display: block;
  }
  #serpB-page .store_footer .reviews-grid .review {
    width: 100%;
    margin: 0;
  }
  #serpB-page .otherProducts {
    display: inline-block;
  }
  #serpB-page .store {
    padding: 0px;
    padding-bottom: 1.5714285714em;
    border: none;
    border-radius: 0px;
    margin-bottom: 1.9285714286em;
    width: 100%;
    display: block;
    border-bottom: 1px solid #9b9b9b;
  }
  #serpB-page .store:last-child {
    border-bottom: none;
    margin-bottom: 0px;
  }
  #serpB-page .store_storeLink {
    padding-right: 0px;
    padding-left: 0px;
    width: 23.6121970289%;
    text-align: center;
  }
  #serpB-page .product.card {
    width: 25%;
  }
  #serpB-page .product.card:last-of-type {
    border: 10px solid red !important;
  }
  #serpB-page .product.card .product_florist {
    display: none;
  }
  #serpB-page .product-categories,
  #serpB-page #products_toolbar_filter,
  #serpB-page .products_toolbar {
    display: none;
  }
  #serpB-page .pagination {
    float: right;
  }
}
@media (min-width: 960px) {
  #serpB-page #sidebar {
    display: block;
  }
}
@media (min-width: 1024px) {
  #serpB-page #main {
    padding: 0;
  }
  #serpB-page .store_avatar {
    display: inline-block;
  }
}
@media (min-width: 1152px) {
  #serpB-page #content {
    padding: 0;
  }
}

#page-details {
  padding: 0 1.0714285714em;
}
#page-details .page_header {
  border: none;
  text-align: center;
}
#page-details .benefits {
  margin-top: 2.3em;
  text-transform: uppercase;
  text-align: center;
  color: #777;
  letter-spacing: 0.1em;
}
#page-details .benefits__card {
  display: inline-block;
  font-size: 0.8em;
  vertical-align: bottom;
  height: 15em;
  margin: 0.8em;
}
#page-details .benefits__icon {
  max-height: 100px;
  padding-bottom: 0.5em;
  display: inline-block;
}
@media (max-width: 768px) {
  #page-details .benefits__card {
    height: 120px;
  }
  #page-details .benefits p, #page-details .benefits h4 {
    font-size: 12px !important;
  }
}
#page-details .details_photo img {
  width: 100%;
  height: auto;
}
#page-details .product_tabs {
  border: 1px solid #efefef;
}
#page-details .product_tabs .tab-pane {
  box-sizing: border-box;
}
#page-details .product_name {
  color: #666;
  line-height: 1.25;
}
#page-details .aside .store {
  background: #f5f5f5;
  overflow: hidden;
}
#page-details .aside .owner_photo {
  float: left;
  border-radius: 50%;
  overflow: hidden;
}
#page-details .aside .owner_photo img {
  width: 100%;
  height: auto;
}
#page-details .aside .store_info {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  overflow: hidden;
}
#page-details .aside .store_name {
  display: inline-block;
  color: #666;
}
#page-details .aside .store_florist {
  color: #8e8e8e;
}
#page-details .aside .store_florist.no_review {
  font-size: 0.8em;
}
#page-details .aside .storeRating {
  margin-bottom: 1em;
}
#page-details .aside .storeRating .reviewCount {
  margin-top: 1px;
  font-size: 14px;
}
#page-details .aside .store_rating {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
}
#page-details .aside .store_rating img {
  display: inline-block;
  vertical-align: top;
  vertical-align: middle;
}
#page-details .aside .store_rating .rating_reviews {
  display: inline-block;
  vertical-align: top;
  vertical-align: middle;
  color: #666;
}
#page-details .aside .store_link {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  display: block;
  max-width: 13.75em;
  text-align: center;
  text-transform: uppercase;
  color: #a6a6a6;
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
}
#page-details .aside .store_link:hover {
  color: #fff;
  background: #a6a6a6;
}
#page-details .page_header,
#page-details .main,
#page-details .product_desc,
#page-details .aside,
#page-details .product_tabs {
  max-width: 500px;
  margin: 0 auto 1.4285714286em;
}
#page-details .page_header {
  margin-bottom: 1.7857142857em;
  padding-top: 2.1428571429em;
  padding-bottom: 0;
}
#page-details .page_header .product_name {
  font-size: 2.7857142857em;
}
#page-details .main {
  float: none;
  width: 100%;
  margin-bottom: 60px;
}
#page-details .details_photo {
  width: 100%;
  height: auto;
}
#page-details .mediaGallery__container--video {
  margin-top: 10px;
  width: 100%;
  position: relative;
}
#page-details .mediaGallery__container--video-landscape {
  padding-bottom: 56.25%;
}
#page-details .mediaGallery__container--video-portrait {
  padding-bottom: 177.77%;
}
#page-details .mediaGallery__container--video blockquote {
  border: none;
}
#page-details .mediaGallery__container--video .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#page-details .mediaGalleryImg {
  float: left;
  cursor: pointer;
  max-width: 19%;
  padding-right: 1%;
  padding-top: 1%;
  overflow: hidden;
  display: none;
}
#page-details .mediaGalleryImg img {
  max-width: 100%;
  height: auto;
}
#page-details .mediaGalleryImg.active {
  display: inline;
}
#page-details .product_tabs {
  margin-bottom: 2.8571428571em;
  border-top: 0;
}
#page-details .order {
  margin-bottom: 2.1428571429em;
}
#page-details .product_info_title {
  display: none;
}
#page-details .aside .store {
  margin: 1em 0 2.4285714286em;
  padding: 1.9285714286em 2.3571428571em;
}
#page-details .aside .owner_photo {
  width: 13.8571428571em;
  height: 13.8571428571em;
  margin-right: 1.7142857143em;
}
#page-details .aside .store_info {
  padding-left: 5px;
}
#page-details .aside .store_name {
  font-size: 3.2857142857em;
  margin-bottom: 0.152173913em;
}
#page-details .aside .store_florist {
  font-size: 1.8571428571em;
  margin-bottom: 0.3846153846em;
  line-height: 1.2;
}
#page-details .aside .store_rating {
  margin-bottom: 0.6428571429em;
}
#page-details .aside .store_rating img {
  width: 8.5714285714em;
  margin-right: 0.5714285714em;
}
#page-details .aside .store_rating .rating_reviews {
  font-size: 1.0714285714em;
  margin-top: 0.2em;
}
#page-details .aside .store_link {
  height: 3.35em;
  font-size: 1.4285714286em;
  line-height: 3.25em;
  border-radius: 2px;
  padding: 0 0.25em;
}
#page-details .product_desc {
  display: none;
}
#page-details .tab-pane p {
  font-size: 14px;
  line-height: 25px;
  color: #333;
}
@media (min-width: 420px) {
  #page-details .tab-pane p {
    font-size: 14px;
    line-height: 25px;
  }
}
#page-details .product.card {
  width: 46%;
}
#page-details .pagination {
  text-align: center;
}
@media (min-width: 768px) {
  #page-details .page_header,
  #page-details .main,
  #page-details .product_desc,
  #page-details .aside,
  #page-details .product_tabs {
    max-width: none;
  }
  #page-details .page_header {
    display: none;
  }
  #page-details .product_info_title {
    display: block;
    color: #656565;
    font-size: 1.2857142857em;
    text-align: center;
    margin-bottom: -0.1666666667em;
  }
  #page-details .main {
    float: left;
    width: 49%;
    max-width: 600px;
    margin-bottom: auto;
  }
  #page-details .mediaGalleryImg {
    max-width: 15.6%;
  }
  #page-details .aside {
    float: right;
    width: 49%;
  }
  #page-details .aside .store {
    padding: 1.2142857143em 1.2857142857em 1.1428571429em 1.2857142857em;
    margin: 0.8571428571em 0 2.1428571429em;
  }
  #page-details .aside .owner_photo {
    width: 8.5714285714em;
    height: 8.5714285714em;
    margin-right: 1em;
  }
  #page-details .aside .store_name {
    font-size: 1.85em;
  }
  #page-details .aside .store_florist {
    font-size: 1.1428571429em;
  }
  #page-details .aside .reviewCount {
    margin-top: 0.14444em;
    font-size: 0.94286em;
    color: #8e8e8e;
  }
  #page-details .aside .store_link {
    height: 2.5833333333em;
    padding: 0 0.25em;
    border-radius: 4px;
    font-size: 0.8571428571em;
    line-height: 2.5833333333em;
  }
  #page-details .product_desc {
    display: block;
    color: #666;
    font-size: 0.8571428571em;
    margin-top: 2em;
  }
  #page-details .product_desc h3, #page-details .product_desc h6 {
    font-family: "ProximaNova", sans-serif;
    font-weight: 600;
    margin-bottom: 0.6666666667em;
    font-size: 14px;
  }
  #page-details .product_desc p {
    line-height: 1.5em;
    font-size: 13px;
  }
  #page-details #content {
    padding: 0px 2.4285714286em;
  }
  #page-details .product_tabs {
    clear: both;
  }
  #page-details #tab-about,
  #page-details #tab-reviews,
  #page-details #tab-delivery-information,
  #page-details #tab-substitution-policy {
    font-size: 1em;
  }
  #page-details .tab-pane p {
    font-size: 14px;
    line-height: 25px;
  }
  #page-details .product-grid {
    width: 100%;
    text-align: center !important;
  }
  #page-details .product-grid .product.card {
    width: 20%;
    box-sizing: border-box;
    margin: 10px;
  }
  #page-details .product-grid .product.card .product_florist .florist_name {
    font-size: 11px;
  }
  #page-details .product-grid .product.card .product_florist .florist_delivery {
    font-size: 10px;
  }
  #page-details #tab-other-design .product.card .product_name a {
    line-height: 22px;
  }
  #page-details .pagination {
    float: right;
  }
}
@media (min-width: 960px) {
  #page-details .aside {
    width: 49%;
  }
  #page-details .aside {
    width: 49%;
  }
}
#page-details .details_info .store_link {
  display: none;
}
#page-details .details_info .store_info {
  padding-top: 20px;
  padding-left: 5px;
}
@media (min-width: 768px) {
  #page-details {
    padding: 3.4285714286em 2.4285714286em 0 2.4285714286em;
  }
}
@media (min-width: 1152px) {
  #page-details {
    padding: 3.4285714286em 4.5714285714em 0 4.5714285714em;
  }
}

/* Account Management */
.settings {
  margin: 40px auto;
}

.settings-head {
  text-align: center;
}

.settings-header {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  font-size: 32px;
  margin-bottom: 15px;
}

.settingsNav {
  margin-bottom: 35px;
  background: #F3F3F3;
  text-align: center;
}

.settingsNav-link {
  display: inline-block;
  padding: 15px 25px;
  color: #333;
}
.settingsNav-link:hover {
  text-decoration: none;
}
.settingsNav-link.isActive {
  border-top: 2px solid #77BD3A;
}

.setting .btn-nofill {
  border: 1px solid #21a0a2;
  color: #21a0a2;
}
.setting .btn-nofill:hover {
  color: #fff;
  background: #21a0a2;
}
.setting .btn-full {
  display: block;
  padding: 15px;
  background: #ddd;
  color: #fff;
}
.setting .text-control,
.setting .select-control {
  padding-bottom: 15px;
}
.setting .text-control label,
.setting .select-control label {
  font-weight: 400;
}
.setting .text-control input {
  max-height: 40px;
}
.setting .select-controlWrapper {
  box-sizing: border-box;
  font-size: 14px;
}
.setting .select-controlWrapper .select-control {
  display: inline-block;
  width: 100%;
  margin-top: 0;
}
.setting .select-controlWrapper .select-control select {
  width: 100%;
  height: 40px;
  margin-top: 0;
  border: 1px solid #DCDCDC;
}
.setting .select-controlWrapper .select-control::after {
  top: 38%;
}
.setting .select-controlWrapper label {
  display: block;
  margin-bottom: 5px;
}
.setting .select-controlWrapper--month,
.setting .select-controlWrapper--year {
  float: left;
  width: 50%;
}
.setting .select-controlWrapper--month {
  padding-right: 20px;
}

.setting.isActive {
  display: block;
}

.setting-summaryWrapper,
.setting-container {
  box-sizing: border-box;
  width: 50%;
}

.setting-container--orders {
  width: 100%;
}

.setting-summaryWrapper {
  float: left;
  padding-right: 25px;
}

.setting-container {
  float: right;
  border-left: 1px solid #CDCDCD;
  padding-left: 25px;
}

.setting-container--orders {
  padding-left: 0;
  border-left: 0;
}

.setting-header {
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid #CDCDCD;
}

.setting-summaryWrapper .setting-header {
  font-family: "ProximaNovaT-Thin", sans-serif;
  font-weight: 300;
  font-size: 21px;
  text-transform: uppercase;
}

.setting-container .setting-header {
  font-size: 24px;
  font-weight: 400;
}

.settingSummary-list {
  margin-top: 20px;
}

.selectList {
  border: 1px solid #21a0a2;
  border-radius: 4px;
}

.selectListItem {
  box-sizing: border-box;
  padding: 15px;
  border-bottom: 1px solid #21a0a2;
  color: #333;
  cursor: pointer;
}
.selectListItem .selectListItem-iconContainer {
  float: left;
  color: #c5c5c5;
  font-size: 36px;
}
.selectListItem .selectListItem-info {
  float: left;
  margin-left: 15px;
}
.selectListItem.isActive {
  color: #fff;
  background: #21a0a2;
}
.selectListItem.isActive .selectListItem-iconContainer {
  color: #fff;
}
.selectListItem:last-of-type {
  border-bottom: 0;
}

.setting-edit {
  float: right;
}

.newFormSubmit .btn-success,
.newFormSubmit .newFormCancelBtn {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  padding: 10px 20px;
  font-size: 18px;
}
.newFormSubmit .newFormCancelBtn {
  background: #ccc;
  color: #333;
}

.orders-tableHeader,
.orders-tableContent {
  width: 100%;
}

.orders-tableContent td {
  width: 20%;
}

.orders-detailsBtn {
  white-space: nowrap;
  padding: 7px 15px;
  border: 1px solid #4c79bb;
  border-radius: 20px;
  color: #4c79bb;
}
.orders-detailsBtn:hover {
  text-decoration: none;
  background: #4c79bb;
  color: #fff;
}

.profileRow-header {
  display: inline-block;
  width: 90px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 11px;
  color: #999;
}

.address-residence,
.creditCard-info {
  display: block;
  margin-bottom: 5px;
}

html {
  font-size: 100%;
}

.intro--signup {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}

.coverPhoto-container--login,
.coverPhoto-container--signup {
  padding-top: 5.91429em;
}
.coverPhoto-container--login .intro_title,
.coverPhoto-container--login .intro_subtitle,
.coverPhoto-container--signup .intro_title,
.coverPhoto-container--signup .intro_subtitle {
  color: #333;
}
.coverPhoto-container--login .intro_title,
.coverPhoto-container--signup .intro_title {
  text-transform: uppercase;
}
.coverPhoto-container--login .intro_subtitle,
.coverPhoto-container--signup .intro_subtitle {
  font-size: 16px;
}

.coverPhoto-container--login .intro_subtitle {
  font-weight: 400;
}

.coverPhoto-container--signup .intro_title {
  color: #FFF;
  font-size: 40px;
}

.coverPhoto-container--password .intro_title {
  font-size: 36px;
}

.coverPhoto-formWrapper--signup {
  box-shadow: none;
  width: 35%;
  min-width: 330px;
}

.coverPhoto-formWrapper--login {
  width: 100%;
  box-shadow: 0em -11em 7.57143em 0.1em rgb(243, 246, 244);
}
.coverPhoto-formWrapper--login .btn-nofill {
  width: 90%;
}

.account-page {
  padding: 20px;
  height: 600px;
  background-size: cover;
  background-position: center center;
}

.account-card {
  max-width: 400px;
  margin: 25px auto;
  padding: 30px;
  background: rgba(250, 250, 250, 0.8);
  color: #3d3d3d;
}
.account-card__title {
  margin: 15px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}
@media only screen and (min-width: 321px) {
  .account-card__title {
    font-size: 30px;
  }
}
.account-card__subtitle {
  margin: 15px 0;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
}

.account-form__input {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border: 1px solid #DDD;
  border-top: none;
  font-size: 15px;
}
.account-form__input:first-of-type {
  border-top: 1px solid #DDD;
}
.account-form__button {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  background: #f36faf;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  text-align: center;
  color: #FFF;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
}
.account-form__button:hover {
  background: #f15fa6;
}
.account-form__footer {
  margin-top: 25px;
}
.account-form__link {
  font-size: 10px;
}
.account-form__link a {
  text-decoration: none;
  color: #3D3D3D;
}
.account-form__link a:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 321px) {
  .account-form__link {
    font-size: 12px;
  }
}
.account-form__left {
  float: left;
}
.account-form__right {
  float: right;
}
.account-form__password-show {
  float: right;
  margin: 5px;
}
.account-form__password-show span {
  font-size: 12px;
}
.account-form__password-show input {
  -webkit-appearance: checkbox;
}
.account-form #signupForm .g-recaptcha {
  float: left;
  margin: 5px 0px 13px 0px;
}

.horizontal-line {
  width: 30%;
  margin: 20px auto;
  background: #CCC;
  height: 1px;
}

.loginForm .text-control,
.signupForm .text-control,
.passwordForm .text-control {
  margin-bottom: 8px;
}
.loginForm label,
.signupForm label,
.passwordForm label {
  font-family: "ProximaNova", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

@media (min-width: 768px) {
  .coverPhoto-wrapper--login .intro_imgCredit,
  .coverPhoto-wrapper--signup .intro_imgCredit {
    right: 0;
  }
}
@media (min-width: 960px) {
  .coverPhoto-container--login {
    float: left;
    padding-left: 5%;
  }
  .coverPhoto-container--signup {
    padding-left: 5%;
  }
  .account-page {
    min-height: 700px;
  }
  .account-card {
    margin: 75px auto;
  }
}
@media (min-width: 768px) {
  .coverPhoto-container--login,
  .coverPhoto-container--signup {
    padding-left: 0;
  }
}
#cityDiv {
  padding: 20px;
}
#cityDiv h1, #cityDiv h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
}
#cityDiv p.heading-subtext {
  font-size: 16px;
  margin-top: 14px;
  line-height: 1.2;
}
#cityDiv div.sitemapInfo {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 24px;
  width: 100%;
}
#cityDiv div.col-list-state {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
#cityDiv .state-header {
  display: flex;
  justify-content: space-between;
}
#cityDiv .stateName {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}
#cityDiv .state-header i {
  font-size: 22px;
  color: #d8247f;
}
#cityDiv ul {
  margin-top: 5px;
}
#cityDiv ul > li {
  padding: 4px 0;
}
#cityDiv a {
  color: #000;
  font-size: 14px;
  line-height: 1.2;
  white-space: normal;
  width: 100%;
}
#cityDiv li.topcities-seemore a {
  padding: 0;
  text-decoration: none;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  color: #d8247f;
  display: flex;
  align-items: center;
  gap: 2px;
}
#cityDiv li.topcities-seemore a .topcities-seemore-arrow {
  font-size: 16px;
  color: #d8247f;
  transition: color 0.3s ease-in-out;
}
#cityDiv li.topcities-seemore a:hover {
  color: #000;
  text-decoration: none;
}
#cityDiv li.topcities-seemore a:hover .topcities-seemore-arrow {
  color: #000;
}
#cityDiv .sitemapInfo.state-cities {
  display: block;
}
#cityDiv .col-list-cities ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, calc((100% - 48px) / 4)));
  gap: 16px;
}
@media screen and (max-width: 910px) {
  #cityDiv .col-list-cities ul {
    grid-template-columns: repeat(auto-fit, minmax(200px, calc((100% - 48px) / 3)));
  }
}
@media screen and (max-width: 768px) {
  #cityDiv .col-list-cities ul {
    grid-template-columns: 1fr;
  }
}
#cityDiv .col-list-cities ul li {
  border-radius: 10px;
  padding: 14px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
#cityDiv .col-list-cities ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#cityDiv .col-list-cities ul li a:hover {
  text-decoration: none;
  color: #d8247f;
}
#cityDiv .col-list-cities ul li a p {
  font-size: 14px;
}

.validation-advice {
  clear: both;
  color: #d91a00 !important;
  font-size: 11px !important;
  padding: 3px 0 0 !important;
}

li.enterOnSite_option.enterOnSite_option--order {
  display: none;
}

.topCityCard {
  border: 1px solid #ddd;
}

.topCityCard-linkWrapper {
  display: block;
}

.social-icons a img {
  width: 20%;
}

.order_modification .modification_price {
  font-weight: 500;
}

.order_delivery .delivery_details .change-zip {
  line-height: 2.1;
  font-size: 11px;
  padding: 0;
  margin: -1px 5px;
}

.zip-check .check-btn {
  padding: 4px 10px;
}

.order_submitBtn {
  padding-top: 14px;
  font-size: 1.24286em;
  line-height: 1.3;
  min-height: 3.1em;
}

.tab {
  font-weight: 500;
}

.subscribeForm_submit {
  padding-bottom: 8px;
  line-height: 1.2;
}

.coverPhoto-container--login,
.coverPhoto-container--password {
  width: 400px;
}

@media (max-width: 767px) {
  .breadcrumbs {
    display: none;
  }
}
@media (min-width: 768px) {
  .breadcrumbs {
    position: static;
    padding-bottom: 20px;
  }
  .breadcrumbs li.crumb-filter::after {
    content: ": ";
  }
}
@media (max-width: 1152px) {
  #page-details .tab-pane p {
    padding: 10px 20px 10px 20px;
  }
}
@media (max-width: 1099px) {
  .coverPhoto-container--login,
  .coverPhoto-container--password {
    margin-left: 25px;
  }
}
@media (max-width: 768px) {
  .coverPhoto-container--login,
  .coverPhoto-container--signup,
  .coverPhoto-container--password {
    width: 80%;
    margin: 0 auto;
    max-width: 400px;
  }
  .coverPhoto-formWrapper--signup {
    width: 100%;
    min-width: 0px;
  }
  .form-extLink,
  .coverPhoto-formErrors {
    font-size: 14px;
  }
  .order_submitBtn {
    font-size: 16px;
  }
}
@media (max-width: 495px) {
  .subscribeForm_submit {
    padding: 13px 0 12px 0;
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .contacts_phone {
    font-size: 12px;
    margin-right: 5px;
    line-height: 1.2;
    margin-top: 5px;
    padding-bottom: 4px;
  }
}
.lightgray * {
  color: lightgray !important;
}

.modal.in .modal-dialog {
  z-index: 1041;
}

.filtersGroup .filter .filter_action {
  font-size: 12px;
  line-height: 1.53333em;
  color: #41858c;
  padding-left: 0.2em;
  margin-bottom: 0.53333em;
}

.filtersGroup .filter .filter_action a {
  color: #9E9E9E;
  border-bottom: 1px solid #E7E7E7;
  line-height: 8px;
  display: inline-block;
}

.filtersGroup .filter {
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

#store-rating-link {
  text-decoration: none;
  cursor: pointer;
}

.header-filter-title {
  display: inline-block;
  padding: 6px;
  border: 1px solid #726c71;
  color: #726c71;
  margin-top: 10px;
}

.header-filter-remove {
  display: inline-block;
  padding: 6px;
  border: 1px solid #726c71;
  margin-left: -4px;
  margin-right: 5px;
  margin-top: 10px;
}

.header-filter-remove a {
  color: #555;
  font-weight: bold;
}

.header-headline {
  font-size: 1.3em !important;
}

.header-filters-block {
  border-top: solid 1px #cdcdcd;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.page_header {
  padding-bottom: 5px;
}

@media (max-width: 767px) {
  .header-headline {
    display: block !important;
    font-size: 16px !important;
    text-align: center;
    margin: 10px 0 !important;
  }
}
@media (min-width: 1024px) {
  .grid-align {
    text-align: left !important;
  }
  .grid-margin {
    margin-right: 18px !important;
  }
}