.switcher_title {
    @include font-proximanova-regular;
    font-size: em(25.9px);
    color: #666;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: em(22px, 14px);
}

.switcher_select {
    @include grid;
    text-align: center !important;
    margin-bottom: 10px;
}

.switcher_option {
    @include grid-cell;
    @include font-proximanova-bold;
    position: relative;
    font-size: 1.1em;
    text-align: center;
    text-transform: uppercase;
    color: $switcher-textColor;
    background: $switcher-bgColor;
    border: 1px solid $switcher-borderColor;
    border-radius: 4px;
    width: calc-percent(193px, 600px);
    height: em(113px, 17px);
    box-sizing: border-box;

    &:hover {
        border: 2px solid $switcher-borderColor--hover;
        color: $switcher-textColor--hover;
        background: $switcher-bgColor--hover;
    }

    .switcher_option_btn_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        border: 0;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 0;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        user-select: none;

        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
    }
    .switcher_option_wrapper {
        white-space: normal;
        display: inline-block;
        vertical-align: middle;
        font-size: em(26.6px);
        width: 100%;
        height: 100%;
        color: inherit;
        font: inherit;
        text-transform: uppercase;
    }
    input {
        display: none;
        line-height: 0px;
        font-size: 0;
        cursor: pointer;
        box-sizing: border-box;
    }
    .date {
        text-transform: uppercase;
    }
    &.active {
        color: $switcher-textColor--active;
        border: 2px solid $switcher-borderColor--active;
        background: $switcher-bgColor--active;

        label {
            cursor: default;
        }
    }
    &.disabled {
        color: #aaa;
        background: #eee;

        &:hover {
            background: #eee;
            border: 1px solid #aaa;
        }
        label,
        input {
            cursor: not-allowed;
        }
        input {
            pointer-events: none;
        }
    }
}

.switcher_option .date_input,
#datepickr {
    display: block;
    border: none;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    background: none;
}


.order_delivery-selectDateWarning {
    display: none;
    font-size: 11.75px;
    text-align: center;
    color: red;

    &.isActive {
        display: block;
    }
}

#switcher_option--calendar {
    width: 100%;
    height: em(74.2px);
    margin-top: em(14px);
}

// Breakpoints
@media (min-width: 568px) {
    #switcher_option--calendar {
        margin-top: 0;
        height: em(93.05884px);
    }
}
@include breakpoint(phablet) {
    .switcher_title {
        font-size: em(14px);
        margin-bottom: em(14px, 9px);
    }
    .switcher_option,
    #switcher_option--calendar {
        // font-size: em(11px);
        height: em(57px, 11px);
    }
    .switcher_select .switcher_option {
        font-size: em(12.6px);
    }
    .switcher_option .switcher_option_wrapper {
        font-size: em(16.8px);
    }
}

@media (max-width: 768px) {
    .switcher_option {
        .switcher_option_wrapper {
            font-size: em(26.6px) !important;
        }
    }
}
