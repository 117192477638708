body {
    font-size: 6px;
}

button,
input,
textarea {
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

button {
    @include reset-btn;
}

img {
    display: block;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@media (min-width: 420px) {
    body {
        font-size: 8px;
    }
}

@media (min-width: 568px) {
    body {
        font-size: 50%;
    }
}

@media (min-width: 667px) {
    body {
        font-size: 55%;
    }
}

@media (min-width: 768px) {
    body {
        font-size: $base-font-size;
    }
}
