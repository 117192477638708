.payment-banner {
  height: 44px;
  width: 100%;
  background: #EFEFEF;
  font-size: 16px;
  display: none;
}

.payment-banner-chrome .content-wrapper {
  max-width: 560px;
  margin: auto;
  height: 44px;
}

.payment-banner-safari .content-wrapper {
  max-width: 725px;
  margin: auto;
  height: 44px;
}

.paid-method-icon-wrapper {
  padding: 8px;
  text-align: right;
  float: left;
}

.paid-method-text-wrapper {
  padding: 8px;
  text-align: left;
  float: left;
  line-height: 28px;
}

.close-cross-wrapper {
  width: auto;
  padding: 15px;
  text-align: right;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

.banner-title {
  font-weight: 600 !important;
  padding-right: 8px;
  font-size: 16px;
  line-height: 20px;
}

.banner-description {
  padding-left: 8px;
  font-size: 16px;
  line-height: 20px;
}

.payment-banner-close-cross {
  cursor: pointer;
  display: block;
  width: 14px;
  height: 14px;
}

.line-break {
  display: none;
}

@media screen and (max-width: 767px) {
  .payment-banner {
    height: auto;
  }
  .delimiter {
    display: none;
  }
  .paid-method-icon-wrapper {
    width: auto;
    padding: 16px 8px 16px 16px;
    text-align: left;
    float: left;
  }
  .paid-method-text-wrapper {
    width: 75%;
    padding: 16px 8px;
    line-height: 24px;
    float: none;
  }

  .payment-banner-chrome .paid-method-text-wrapper {
    margin-left: 96px;
  }

  .payment-banner-safari .paid-method-text-wrapper {
    margin-left: 78px;
  }

  .payment-banner-chrome .content-wrapper,
  .payment-banner-safari .content-wrapper {
    max-width: none;
    height: auto;
  }

  .line-break {
    display: inline;
  }
  .banner-title, .banner-description {
    padding: 0;
  }
  .banner-description {
    font-size: 14px;
    line-height: 16px;
  }
  .close-cross-wrapper {
    padding: 8px;
  }
  .payment-banner-close-cross {
    width: 30px;
    height: 30px;
  }
  .safari-badge{
    width: 54px;
    height: 34px;
  }
  .chrome-badge{
    width: 72px;
    height: 34px;
  }
}