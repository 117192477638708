.reviews-grid {
    @include grid;

    .review {
        margin-bottom: em(38px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.review {
    color: #555;
}

.review_header {
    margin-bottom: em(12px);
}

.review_author {
    @include font-proximanova-semibold;
    display: block;
    text-align: center;
    font-size: em(32px);
}

.review_rating {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;

    img {
        display: inline;
        height: em(27px);
    }
}

.review_data {
    @include font-proximanova-semibold;
    display: block;
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 1.82857em;
    text-align: center;
    color: #777;
}

.review_text {
    @include font-proximanova-regular;
    max-width: 260px;
    padding: 0 1em 20px;
    margin: 0 auto;
    font-size: em(31.2px);
    line-height: em(28px, 20px);
    border-bottom: 1px solid #eaeaea;

    p {
        line-height: em(17px, 12px);
    }
}
@media (min-width: 420px) {
    .review_text {
        font-size: 1.928571em;
        max-width: 450px;
    }
}
@include breakpoint(phablet) {
    .reviews-grid {
        .review {
            @include inline-box;
            width: calc-percent(507px, 1100px);
        }

        .review {
            margin-bottom: em(31px);
        }
    }

    .review_header {
        margin-bottom: em(9px);
    }

    .review_author {
        display: inline;
        font-size: em(16px);
        margin-right: em(5px, 16px);
    }

    .review_rating {
        display: inline-block;
        margin: 0;
        text-align: left;

        img {
            height: em(15px);
        }
    }

    .review_data {
        font-size: 0.77143em;
        margin-top: em(9px, 8px);
        float: none;
        text-align: left;
    }

    .review_text {
        max-width: none;
        padding: 0;
        margin: 0;
        border: 0;
        font-size: 1.05714em;
        line-height: 1.4;
    }
}
