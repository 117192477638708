@include breakpoint(tablet) {
    #sidebar {
        display: block;
    }

    #main {
        margin-left: em(210px);
    }

    .product_alert {
			height: 50px;
			opacity: 0;

			.product_alert_content {
				margin-top: 1px;
				.product_alert_header {
					padding: 2px 0 2px 0;
				}
				.product_alert_body {
					padding: 2px 0 0 0;
				}
			}
	}
}
