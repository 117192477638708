
.h1 {
    @include font-bodoni;
    font-size: 25px;
    line-height: 1.32;
    letter-spacing: -1px;
    color: $header_grey;
}


@include breakpoint(tablet) {

    .h1 {
        font-size: 30px;
        line-height: 1.4;
    }
}