.product-grid {
    @include clearfix;
    // Inline-blocked grid ignores
    // whitespace that breaks layout
    font-size: 0;

    .grid-cell {
        margin-bottom: 10px;
    }
}

.product.card {
    width: 100%;
    box-sizing: border-box;
    border: 0.5px solid #E4E4E4;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
    // reset font-scale
    font-size: 13px;
    position: relative;

    &:hover,
    &:active
    {
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.24);
    }

    .product_photo {
        margin-bottom: 3px;
        border-bottom: 1px solid #EFEFEF;

        img {
            width: 100%;
            height: auto;
        }
    }

    .product_info {
        padding: 0 em(9px) em(9px) em(13px);
    }

    .product_name,
    .product_price {
        @include inline-box;
    }

    .product_name {
        font-size: 11px;
        line-height: 1.8;
        white-space: normal;
        width: 100%;
        padding: 5px 0;
        text-overflow: ellipsis;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;

        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;

        a {
            color: #464646;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            // IE 10 Fallback (Flexbox not fully supported):
            max-width: 60%;
            display: inline-block;

            &:hover {
                text-decoration: none;
            }
        }
        .product_price {
            font-size: 13px !important;
            line-height: 1.5;
            color: #89c520;
            text-decoration: none !important;
            padding-left: 5px;
            overflow: visible;
        }
    }
    .divider {
        border-bottom: 1px solid #efefef;
    }

    .product_florist {
        @include font-proximanova-regular;
        padding-top: 10px;
        padding-bottom: 3px;

        a {
            color: #464646;
            display: inline;
            vertical-align: middle;
            max-width: 60%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .florist_image {
            border: 1px solid #EFEFEF;
            display: inline;
            float: left;
            margin: -2px 10px 0 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
        .florist_name {
            font-size: 10px;
            color: #898989;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 63%;
            display: inline-block;

            &:hover {
                text-decoration: none;
            }
        }
        .florist_delivery {
            display: inline-block;
            padding-top: 2px;
            font-size: 9px;
            font-style: italic;
            color: #898989;
        }
        &:has(.florist_delivery:not(.is_free_delivery)) {
            .florist_name {
                display: inline-block;
                margin-top: 10px;
                margin-bottom: -10px;
            }
        }
    }
}

.other_designs .product_florist a {
    max-width: 55% !important;
}

@include breakpoint(mobile) {
    .product.card {
        width: 46%;
        margin: 0 2% 30px 2%;

        .product_info .product_florist {
            line-height: 1;
        }
    }
}

@include breakpoint(tablet) {
    .product.card {
        .product_info .product_florist .florist_name {
            width: 78%;
        }
    }
}
@media (min-width: 555px) {
    .product.card {
        width: 30%;
        margin-right: 1.66%;
        margin-left: 1.66%;
    }
}
@include breakpoint(phablet) {
    .product.card {
        .product_name {
            a {
                max-width: 70%;
            }
        }

        .product_price {
            color: #555;
            float: right;
            display: block;
            padding-left: 5px;
        }

        .product_info .product_florist {
            .florist_name {
                font-size: 11px;
            }
            .florist_delivery {
                font-size: 10px;
            }
        }
    }
}
@include breakpoint(desktop) {
    .product.card {
        .product_name .product_price {
            font-size: 15px !important;
        }
   }
}

.product-card-link {
    display: flex;
    flex-direction: inherit;
    height: calc(100% - 50px);
    left: 0;
    overflow: hidden;
    position: absolute;
    text-indent: -999999px;
    top: 0;
    width: 100%;
    z-index: 1;
}
