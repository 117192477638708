.clearfix {
	@include clearfix;
}

.without-border {
    border: none !important;
}

.center-block {
	@include center-block;
}

.grid {
	@include grid;
}

.grid-cell {
	@include grid-cell;
}

.hidden-on-mobile {
	display: none;
}

@include breakpoint(phablet) {
    .hidden-on-mobile {
    	display: block;
    }
}

.pull-left {
	float: left;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
