i.fa.fa-google-white {
    width: 24px;
    height: 24px;
    background-image: url('../../../../../../../skin/frontend/base/default/images/google-icon-white.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 24px 24px;
}

.store.head {
    .store_content {
        padding: em(21px) em(21px) em(19px);
        text-align: center;
        position: relative;
    }

    .store_logo,
    .store_info {
        position: relative;
        z-index: 1;
    }

    .store_info {
        .storeRating {
            margin-bottom: em(9px);

            img {
                display: inline-block;
                vertical-align: middle;
                margin-right: em(17px);
                width: em(170px);
            }

            p {
                display: inline-block;
                vertical-align: middle;
                color: #fff;
                font-size: em(17px);
            }
        }
    }

    .store_logo {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        max-width: em(224px);
        max-height: em(224px);
        padding: em(5px);
        margin-bottom: em(6px);
        border-radius: 2px;
        background: #fff;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .socialLinks {
        display: none;
    }

    .store_name {
        @include font-proximanova-light;

        margin-bottom: em(5px, 89px);
        font-size: 25px;
    }

    .store_location {
        font-size: em(24px);
        line-height: em(33.79992px, 24px);
        max-width: em(425px, 24px);
        display: inline-block;
        a {
            color: #fff;
        }
    }

    .store_cover {
        background: #333;
        position: relative;

        &::after {
            content: "";
            background: linear-gradient(to bottom, rgba(0,0,0,0) 42%, rgba(0, 0, 0, 0.8) 100%);
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
        }

        .store_logo {
            img {
                border: solid 1px #999999;
            }
        }

        .store_name,
        .store_location {
            color: #fff;
        }
    }
}

// Breakpoints
.store.head {
    @include breakpoint(phablet) {
        .store_logo,
        .store_info {
            display: inline-block;
            vertical-align: bottom;
        }

        .socialLinks__item {
            display: inline-block;
            vertical-align: top;
            font-size: 2.02857em;
            margin-left: em(5px, 34px);

            a {
                color: #FFF;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .store_content {
            padding: em(90px) em(34px) em(16px) em(34px);
            text-align: left;

            &::after {
                content: "";
                height: 100%;
                display: inline-block;
                vertical-align: bottom;
            }
        }

        .store_info {
            max-width: 67.5%;
            margin-top: 15px;

            .storeRating {
                display: none;
            }
        }

        .store_logo {
            width: 100%;
            max-width: em(235px);
            max-height: em(235px);
            padding: em(10px);
            border-radius: 4px;
            margin-bottom: 0px;
            margin-right: em(32px);
        }

        .store_name {
            font-size: 45px;
            // font-size: em(89px);
            margin-bottom: em(8px, 64px);
        }

        .store_location {
            font-size: em(16px);
            line-height: em(19px, 16px);
            max-width: em(314px, 16px);
        }
    }

    @include breakpoint(tablet) {
        .socialLinks {
            position: absolute;
            bottom: em(33px);
            right: em(34px);
            z-index: 1;
            display: block;
        }
    }

    @include breakpoint(desktop) {
        .socialLinks {
            right: em(64px);
        }

        .store_content {
            padding: em(90px) em(64px) em(16px) em(64px);
        }
    }
}
