.select-control {
    position: relative;
    border: 0;
    margin-top: em(-4px);
    font-size: .65em;
    text-transform: none;

    &::after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 50%;
        right: em($select-arrow-offset);
        margin-top: em(round(calc($select-arrow-size / 2) * -1)) ;
        border-style: solid;
        border-width: em($select-arrow-size) em($select-arrow-size) 0 em($select-arrow-size);
        border-color: $select-arrow-color transparent transparent transparent;
    }

    select {
        @include font-proximanova-regular;
        color: $select-color;
        background: transparent;
        font-size: $select-font-size;
        min-width: em($select-width, $select-font-size);
        border-radius: em(4px);
        -webkit-appearance: none;
        padding: 0px 42px 0px 11px;
        box-sizing: border-box;
        height: 35px;

        &::-ms-expand {
          display: none;
        }
    }

    @media (min-width: 568px) {
            font-size: .7em;
    }
    @include breakpoint(phablet) {
        font-size: 14px;
        $select-phablet-height: 24px;
        $select-phablet-font-size: 14px;
        $select-phablet-arrow-size: 8px;
        $select-phablet-arrow-offset: 10px;

        border-radius: $select-border-radius;

        &::after {
            right: 10px;
            margin-top: -4px;
            border-width: em($select-phablet-arrow-size) em($select-phablet-arrow-size) 0 em($select-phablet-arrow-size);
        }

        select {
            height: 35px;
            line-height: 27px;
            padding-right: 42px;
        }
    }

}

@-moz-document url-prefix() {
    .select-control {
        select {
          border: 1px #333 solid;
        }

        &::after {
          display: none;
        }
    }
}
