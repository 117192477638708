.invisible {
    height: 0;
    overflow: hidden;
}

#content {
    margin-bottom: em(50px);
}

#sidebar {
    width: em(180px);
    float: left;
}

#main {
    margin-left: em(210px);
}

.page_header {
    padding-top: em(35px);
    padding-bottom: em(33px);
    margin-bottom: em(28px);
    border-bottom: solid 1px #cdcdcd;
    position: relative;
}

.breadcrumbs {
    position: absolute;
    top: em(38px);
    right: 0px;
}

.page_header_title {
    font-size: 17px;
    margin-bottom: em(10px);
    line-height: 27px;
    color: #555;
}

@include breakpoint(phablet) {
    .page_header_title {
        font-size: 22px;
        margin-bottom: em(15px, 17px);
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    input:focus,
    select:focus,
    textarea:focus {
        font-size: 16px !important;
    }
}
