@include breakpoint(phablet) {
    .hero {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 650px;

        &__content-wrapper {
            margin: 0 auto;
        }

        &__content {
            h1 {
                font-size: 23px;
            }
        }
    }

    .page_section_header {
        margin: 55px 0;

        .h1 {
            font-size: 25px;
        }
    }
}
