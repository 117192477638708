.enterOnSite_option,
.enterOnSite_optionBtn {
    @include inline-box;
}
.enterOnSite_option {
    padding-left: 12px;
    font-size: 12px;

    @include breakpoint(375px) {
        font-size: 13px;
    }

    @include breakpoint(phablet) {
        padding-left: 40px;
        font-size: 15px;
    }

    &:hover {
        text-decoration: none;
    }

    &--marketplace,
    &--desktop,
    &--help,
    &--florist,
    &--logout {
        display: none;

        @include breakpoint(phablet) {
            display: block;
        }
    }

    &--login {
        @include breakpoint(phablet) {
            padding-left: 32px;
        }
    }

    &--mobile {
        display: block;

        @include breakpoint(phablet) {
            display: none;
        }
    }
    &--checkout {
        padding-left: 8px;

        @include breakpoint(phablet) {
            padding-left: 32px;
        }
    }
}
.enterOnSite_optionBtn {
    @include font-proximanova-regular;

    box-sizing: border-box;
    border-radius: 30px;
    color: #555;
    text-align: center;
    line-height: 18px;

    &:hover {
        text-decoration: none;
        color: $darkPink--hover;

        svg polygon, svg path {
            fill: $darkPink--hover;
        }
        svg.goToCheckoutArrow path{
            fill: #FFFFFF;
        }
    }

    &--marketplace {
        padding: 10px;
        border: 1px solid #777;
        border-radius: 4px;

        &:hover {
            border-color: $darkPink--hover;
        }
    }

    &--florist {
        display: none;
        color: $darkPink;
        border: 1px solid $darkPink;
        padding: 10px 19px;
        border-radius: 4px;

        &:hover {
            color: $darkPink--hover;
            border: 1px solid $darkPink--hover;
        }

        @include breakpoint(phablet) {
            display: block;
        }
    }

    &--logout-mobile {
        color: $darkPink;
        border: 1px solid $darkPink;
        padding: 10px;
        border-radius: 4px;

        &:hover {
            color: $darkPink--hover;
            border: 1px solid $darkPink--hover;
        }

        @include breakpoint(phablet) {
            display: none;
        }
    }

    &--help {
        display: none;

        @include breakpoint(520px) {
            display: block;
        }
    }

    &--login {
        background: $darkPink;
        color: #fff;
        padding: 10px;
        border: 1px solid $darkPink;
        border-radius: 4px;
        font-size: 14px;

        &:hover {
            background: $darkPink--hover;
            color: #fff;
        }
        .desktop-login-text{
            display: none;
            font-size: 15px;
        }
        .mobile-login-text{
            font-size: 15px;
        }
        @include breakpoint(phablet) {
            padding: 10px 18px;
            font-size: 15px;
            .mobile-login-text{
                display: none;
            }
            .desktop-login-text{
                display: inline;
            }
        }
    }

    &--desktop {
        display: none;

        @include breakpoint(phablet) {
            display: block;
        }

        .dropdown-triangle-icon {
            display: inline;
        }

        .dropdown-triangle-icon svg {
            vertical-align: middle;
            width: 20px;
            height: 20px;
        }
    }

    &--account {
        padding-top: 7px;
        height: 40px;
        width: 40px;
        border: 1px solid $darkPink;
        border-radius: 4px;

        &:hover {
            border: 1px solid $darkPink--hover;
        }

        @include breakpoint(phablet) {
            display: none;
        }
    }

    &--checkout {
        background: $darkPink;
        color: #fff;
        padding: 11px 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        line-height: 18px;
        font-size: 14px;
        white-space: nowrap;
        &:hover {
            background: $darkPink--hover;
            color: #fff;
        }
        .goToCheckoutArrow{
            margin-right: 14px;
            margin-bottom: 1px;
            display: none;
            @include breakpoint(phablet) {
                display: block;
            }
        }
        @include breakpoint(phablet) {
            font-size: 15px;
            padding: 11px 20px;
            white-space: normal;
        }

    }

}
.enterOnSite_select {
    display: flex;
    align-items: center;
}
.enterOnSite_optionBtn--border {
    border: solid 1px #CDCDCD;
    width: 9.2em;
}

#header {
    display: block;
    border-bottom: 1px solid #E6E6E6;
    @include screen-box-shadow();
}

.no-header-border {
    border: none !important;
}

.header-mobile {
    display: block;
    background: #fff;

    @include breakpoint(phablet) {
        display: none;
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__list-item {
        width: 50%;
        text-align: center;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        font-size: 12px;

        &:first-of-type {
            border-right: 1px solid #eee;
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        padding: 15px 0;
        color: #555;
        text-transform: uppercase;

        svg {
            vertical-align: middle;
            width: 20px;
            height: 20px;
            margin-top: -5px;
            margin-right: 5px;
        }
    }

    &__icon-flower {
        path {
            stroke: #555;
        }
    }

    &__icon-phone {
        path {
            fill: #777;
        }
    }
}
