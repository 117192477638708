// Between 500-767px center all content
.page_header,
.main,
.product_desc,
.aside,
.product_tabs {
    max-width: 500px;
    margin: 0 auto em(20px);
}

.page_header {
    margin-bottom: em(25px);
    padding-top: em(30px);
    padding-bottom: 0;

    .product_name {
        font-size: em(39px);
    }
}

.main {
    float: none;
    width: 100%;
    margin-bottom: 60px;
}

.details_photo {
    width: 100%;
    height: auto;
}

.mediaGallery {
    &__container {
        &--video {
            margin-top: 10px;
            width: 100%;
            position: relative;

            &-landscape {
                padding-bottom: 56.25%;
            }

            &-portrait {
                padding-bottom: 177.77%;
            }

            blockquote {
                border: none;
            }

            .video-player {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.mediaGalleryImg {
    float: left;
    cursor: pointer;
    max-width: 19%;
    padding-right: 1%;
    padding-top: 1%;
    overflow: hidden;
    display: none;

    img {
        max-width: 100%;
        height: auto;
    }
}

.mediaGalleryImg.active {
    display: inline;
}

.product_tabs {
    margin-bottom: em(40px);
    border-top: 0;
}

.order {
    margin-bottom: em(30px);
}

.product_info_title {
    display: none;
}

.aside {

    .store {
        margin: em(14px) 0 em(34px);
        padding: em(27px) em(33px);
    }

    .owner_photo {
        width: em(194px);
        height: em(194px);
        margin-right: em(24px);
    }

    .store_info {
        padding-left: 5px;
    }

    .store_name {
        font-size: em(46px);
        margin-bottom: em(7px, 46px);
    }

    .store_florist {
        font-size: em(26px);
        margin-bottom: em(10px, 26px);
        line-height: 1.2;
    }

    .store_rating {
        margin-bottom: em(9px);

        img {
            width: em(120px);
            margin-right: em(8px);
        }

        .rating_reviews {
            font-size: em(15px);
            margin-top: em(3px, 15px);
        }
    }

    .store_link {
        height: 3.35em;
        font-size: em(20px);
        line-height: em(65px, 20px);
        border-radius: 2px;
        padding: 0 em(5px, 20px);
    }
}

.product_desc {
    display: none;
}

// Tab Styles
.tab-pane {
    p {
        font-size: 14px;
        line-height: 25px;
        color: #333;
    }
}
@media (min-width: 420px) {
    .tab-pane {
        p {
            font-size: 14px;
            line-height: 25px;
        }
    }
}

// Product Grid Styles
.product.card {
    width: 46%;
}

// Product Grid Footer
.pagination {
    text-align: center;
}
