@include breakpoint(phablet) {
    .product-categories {
        display: none;
    }

    .page_header {
        margin-bottom: em(28px);
        padding: 0px em(34px);
    }

    #content {
        padding: 0px em(34px);
    }

    .tab-pane {
      font-size: 14px;
      line-height: 22px;

        p {
            font-size: 14px;
            line-height: 22px;
        }
    }
    
    .storeDelegate {
        margin-bottom: em(37px);
        position: relative;
    }

    // Product Grid Footer
    .pagination {
        float: right;
    }
}
