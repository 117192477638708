.suave.cms-page-view {
    * {
        box-sizing: border-box;
    }
    body {
        font-size: 14px;
        line-height: 1.4;
    }
    p {
        line-height: 1.4;
        margin-bottom: 10px;
    }
    &.cms-jobs {
        a.btn {
            padding: 5px;
            color: #fff;
        }
    }
    #layoutContainer {
        max-width: 960px;
        overflow: hidden;
        margin: 0 auto;
        padding: 40px;
    }

    h1, h2 {
        margin: 15px 0;
        font-size: 24px;
        text-align: center;
    }

    #about .btn {
        position: static !important;
    }
}

.std {
    max-width: 960px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.4;

    * {
        box-sizing: border-box;
    }
    > .pressroom {
        max-width: 960px;
        overflow: hidden;
        margin: 0 auto;
        padding: 40px;

        .pressroom-item {
            max-width: 660px;
            margin: 0 auto 30px;
            
            .pressroom-title {
                font-size: 16px;
                font-weight: 800;
                margin-bottom: 10px;
            }
        }
    }
}

.suave .cart-empty {
    margin-bottom: 100px;
}

.cms-page-view .std {
    max-width: none;
}

/*CONTACT PAGE CSS*/
.coverPhoto-wrapper--contact {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 500px;
    margin-left: auto;
    margin-right: auto;
}

.coverPhoto-formWrapper--contact {
    width: 33%;
    min-width: 500px;
    padding-top: 130px;
    padding-bottom: 50px;
    padding-left: 1%;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer .text-control {
    background:none;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer .text-control input {
    background: #fff;
    margin-bottom: 2%;
}

.coverPhoto-formContainer {
    background: rgba(255,255,255,.7);
}

textarea#comment {
    border: none;
    outline: none;
    background: #fff;
    margin: 0px;
    margin-bottom: 2%;
    padding: 0px;
    box-shadow: none;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 16px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
    color: #333;
    width: 100%;
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    padding: 0.67857em 0.35714em 0.82143em 0.82143em;
}

.coverPhoto-formWrapper--contact .coverPhoto-formContainer  .btn-success {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    font-size: 1em;
}

.coverPhoto-formContainer-title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2%;
    font-size: 24px;
    color: #333;
}

.contact-details {
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}

.customer-service {
    width: 33%;
    float: left;
    margin-left: 2%;
}

.contact-info {
    width: 33%;
    float: right;
    margin-right: 2%;
}

.contact-email {
  color: #000;
}

.customer-service-title, .contact-info-title {
  font-size: 1.5em;
    margin-bottom: .5em;
}
.contact-infoLine {
    margin-bottom: 5px;
}
.contact-details .contact-info i {
    text-align: center;
    padding: 1px;
    color: #333;
}
.contact-info .fa-circle-thin {
    font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .noRoute-photo-container .btn-nofill{
      font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
    .coverPhoto-formWrapper--contact {
        width: 100%;
        min-width: 0;
        max-width: 500px;
        padding: 10px;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 540px) {
    .contact-details {
        width: 100%;
        margin: 10px auto;
        padding: 0 15px;
    }
}
