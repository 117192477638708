.dropdown-nav {
  @include screen-box-shadow();

  visibility: hidden;
  box-sizing: initial; // override bootstrap defaults. we do not use bootstrap outside of account management, so we must match its box sizing here.
  transition: all .25s ease-in-out;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 30px;
  left: 47px;
  width: 250px;
  padding: 15px 15px 0 15px;
  background: #fff;
  border: 1px solid #ddd;

  &--visible {
    transition: all .25s ease-in-out;
    visibility: visible;
    opacity: 1;
    z-index: 9999;
  }

  &--account-management {
    top: 40px;
    left: 244px;
  }

  &__line {
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }

  &__arrow-up {
    position: absolute;
    top: -15px;
    right: 25px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #DDD;

    &--overlay {
      position: absolute;
      top: -14px;
      right: 25px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #FFF;
    }
  }

  &__name {
    @include font-bodoni;
    letter-spacing: -.5px;
    font-size: 24px;
  }

  &__list {
    padding: 0 10px;
    font-size: 15px;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
  }

  &__link {
    color: #555;

    .icon {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }

    svg {
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }

    svg path {
      fill: #555;
    }

    span {
      margin-left: 5px;
    }

    &:hover {
      color: $darkPink;
      text-decoration: none;

      svg path {
        fill: $darkPink;
      }
    }
  }
}
