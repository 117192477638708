.topCities {
    padding: 45px 2.42857em 20px;
    background: #fff;
}
.topCities-header {
    font-size: 20px;
    margin-bottom: 25px;
}

h2.topCities-header {
    color: #464646;
    font-family: "Bodoni", serif;
    font-size: 22px;
    line-height: 1.32;
    margin-bottom: 25px;
    text-align: center;
    text-transform: capitalize;
}
@media screen and (min-width: 768px){
    h2.topCities-header {
        font-size: 25px;
    }
}
@media screen and (min-width: 960px){
    h2.topCities-header {
        font-size: 30px;
    }
}

.topCities-cardList {
    text-align: center;
    margin-bottom: 5px;
}
.topCityCardWrapper {
    box-sizing: border-box;
    float: left;
    width: 50%;

    &:nth-child(2n) {
        padding-right: calc($topCityCard-marginBottom / 2);
    }
    &:nth-child(2n-1) {
        padding-left: calc($topCityCard-marginBottom / 2);
    }
    &:first-child {
        padding-left: 0;
    }
}
.topCityCardWrapper--featured {
    width: 100%;
}

.topCityCard {
    height: $topCityCard-height;  // airbnb is 344px
    margin-bottom: $topCityCard-marginBottom;
    background-color: #777;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, transparent 100%);
    border: 1px solid #888;
}
.topCityCard-linkWrapper {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    box-shadow: 0em em 1em 0.1em rgba(29, 30, 35, 0.78);

    &--losangeles {
        background-position-y: -105px;

        @include breakpoint(375px) {
            background-position-y: -140px;
        }

        @include breakpoint(420px) {
            background-position-y: top;
        }

        .topCityCard-text {
            white-space: normal;
        }
    }
}

.topCityCard-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: rgba(0, 0, 0, .35);
    width: 100%;
    border-radius: 0;
    padding: 25px 10px;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px 0 black;
    color: #fff;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }

    @include breakpoint(420px) {
        padding: 25px 15px;
        width: auto;
        border-radius: 3px;
        font-size: 22px;
    }

    @include breakpoint(phablet) {
        padding: 25px 15px;
        white-space: nowrap;
        font-size: 25px;
    }
}

.topCities-toggleLink {
    display: block;
    margin-top: 15px;
    margin-bottom: 30px;
    text-decoration: underline;
    text-align: center;
    font-size: 13px;
    color: #555;
}
.topCities--home .topCities-listWrapper,
#topCities-toggle {
    display: none;
}
.topCities-nameList {
    display: flex;
    margin-bottom: 10px;
    flex-flow: wrap row;
    text-align: left;
    font-size: 14px;
}
.topCity-item {
    box-sizing: border-box;
    padding: 0 13.5px 16px;
    width: 33.333%;
    font-size: 13px;
}
.topCity-itemName {
    @include font-proximanova-regular;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #555;
}

@media (min-width: 420px) {
    .topCityCardWrapper {

        &:first-child {
            padding-left: 0;
            padding-right: calc($topCityCard-marginBottom / 2);
        }
        &:nth-child(2) {
            padding-left: calc($topCityCard-marginBottom / 2);
            padding-right: 0;
        }
    }
    .topCityCardWrapper--featured {
        width: 50%;
    }
    .topCityCard--featured {
        height: $topCityCard-height * 2 + $topCityCard-marginBottom;
    }
    .topCities--home .topCities-listWrapper {
        display: block;
    }
    .topCity-item {
        width: 25%;
    }
}
@include breakpoint(phablet) {
    .topCityCardWrapper--featured {
        width: 100%;
    }
    .topCityCardWrapper {
        width: 36.25%;

        &:nth-child(2) {
            padding-left: 0;
            padding-right: calc($topCityCard-marginBottom / 2);
        }
    }
    .topCities-cardList {
        .topCityCardWrapper--featured {
            width: 27.5%;
            padding-right: $topCityCard-marginBottom;
        }
    }
}
@include breakpoint(tablet) {
    .topCity-item {
        width: 20%;
    }
}
