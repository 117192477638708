.page_header {
    padding: 0;
    margin-bottom: em(17px);
}

#sidebar,
.collapse-info {
    display: none;
}

#main {
    margin: 0;
}

.tab-pane {
    font-size: 14px;
    line-height: 22px;

    p {
        font-size: 14px;
        line-height: 22px;
        color: #333;
    }
}

@media (min-width: 420px) {
    .tab-pane {
        font-size: 14px;
        line-height: 22px;
    }
}

// Product Grid Footer
.pagination {
    text-align: center;
}
