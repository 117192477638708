.filter {
    margin-bottom: em(30px);
}

.filter_header {
    @include font-proximanova-semibold;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    font-weight: 400;
    color: #555555;
}

.filter_header_title {
    a {
        color: #555555;
        display: block;
        position: relative;
        text-decoration: none;
        transition: .2s opacity;

        &:hover {
            opacity: .8;
        }

        &::before {
            @include font-icon;
            font-size: em(29px, 17px);
            color: #cecece;
            position: absolute;
            top: em(-3px, 29px);
            right: em(-12px, 29px);
            content: "\e001";
            transition: .3s transform;
            display: none;
        }

        &.collapsed::before {
            content: "\e00d";
            display: none;
        }
    }

    a[data-toggle="expand"]::before {
        transform: rotate(180deg);
    }
}

.filter_option {
    display: flex;
    font-size: 13px;
    line-height: em(23px, 15px);
    color: #41858c;
    padding-left: em(3px, 15px);
    margin-bottom: em(8px, 15px);

    label[data-checked='true'] a::before {
        content: "x";
        display: inline-block;
        position: absolute;
        left: em(-12px, 15px);
        top: em(-1px, 15px);
        @include font-proximanova-bold;
        color: #555;
    }

    a {
        color: #41858c;
        display: block;
        position: relative;

        input[type='checkbox'] {
            display: none;
        }

        &.checked::after {
            content: "&times;";
            display: inline-block;
            position: absolute;
            left: em(-12px, 15px);
            top: em(-1px, 15px);
            @include font-proximanova-bold;
            color: #555;
        }
    }
}

.products_toolbar {
    position: relative;
}

#product_filter {
    float: right;
}

#pickup-only-filter {
    -webkit-appearance: checkbox;
}
