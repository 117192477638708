@import url("https://fonts.googleapis.com/css?family=Bad+Script&display=swap");
// Bad Practice
.warming_message_wrapper {
  display: none;
  color: #333;

  .orBreak {
    margin: 0 2px;
  }

  .center-block {
    max-width: 960px;
  }

  &.activated {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: fixed;
    padding: 0;
    background: white;
    height: 100%;
    z-index: 15;
    padding-top: 3em;
    left: 0;
    overflow-x: hidden; 
    overflow-y: auto;
  }

  h2 {
    font-size: 2.5em;
    margin-bottom: .5em;
    color: #333;
  }

  h3 {
    font-size: 1.2em;
    font-style: italic;
  }

  .callout {
    @include font-proximanova-light;
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }

  .card {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    margin: 0 auto 120px auto;
    background: rgb(239, 239, 239);
    padding: 4em;
    padding-bottom: 2em;
    overflow: auto;

    img {
      display: none;
    }
  }

  .product-title {
    display: none;
  }

  #card-message {
    box-sizing: border-box;
    width: 100%;
    height: 10em;
    resize: none;
    border: 1px solid #ddd;
    padding: 1em;
    font-size: 1.2em;
    font-family: "Bad Script", cursive, sans-serif;
    font-size: 19px;
  }

  .char-remaining {
    display: none;
    color: #777;
    font-size: .75em;
    margin-top: .3em;
    input {
      background: #efefef;
    }
  }

  .select-control {
    margin-top: 1.5em;
    font-size: 0.7em;
  }

  #occasion_select {
    background: white;
    display: block;
    width: 100%;
  }

  .card-message-action-block-right {
      margin-bottom: .85em;
      width: 100%;
      text-align: center;

      button {
        font-size: 1.4em;
        width: 100%;
        height: 3em;
        margin-top: 1em;
        border-radius: 3px;
        background: #00b551;
        margin-bottom: .2em;

        &:hover {
          background: #03A24A;
        }
      }
  }

  .card-nav,
  .orBreak {
    font-size: 1em;
  }

  .card-nav {
    cursor: pointer;

    a {
      display: inline-block;
      padding-top: 10px;
      font-size: 14px;
      text-decoration: underline;
      color: #333;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .card-footer {
    font-size: 1.3em;
  }


}



@include breakpoint(phablet) {
    .warming_message_wrapper {

      &.activated {
        padding-top: 1em;
      }

      .bouquet {
        float: left;
        width: 22%;
        padding: 1em;
        background: white;
        text-align: center;

        .product-title {
          display: block;
          font-size: 1.1em;
          color: #333;
          margin-top: .5em;
          font-weight: 300;
        }

        img {
          display: block;
          width: 100%;
        }
      }

      h2 {
        font-size: 1.5em;
        margin-top: 1.5em;
      }

      .card-nav,
      .orBreak {
        font-size: .7em;
      }

      .card {
        box-sizing: border-box;
        width: 100%;
        position: relative;
        padding: 1em;
        overflow: auto;
        padding: 2em;
        padding-bottom: .5em;
      }

      .card-nav {
        font-size: 14px;
      }

      .card-message-input {
        width: 100%;
      }

      #card-message {
        font-size: 2.2em;
      }

      .card-message-input,
      .card-message-action-block-left {
          margin-bottom: 3px;
          width: 44%;
          float: left;
      }

      .card-message-action-block-right {
          width: 50%;
          float: right;
          button {
            font-size: .8em;
            margin-top: 1.85em;
          }
      }

      .select-control {
        margin-top: 1em;
      }

      #occasion_select {
        background: white;
        display: block;
        margin-top: .2em;
      }


      .char-remaining {
        display: block;          
      }
      .char_remaining {
          box-shadow: none;
          border: none;
          background: #fff;
      }

      .card-message-box {
        float: right;
        width: 72%;
      }

      .card-message-continue {
          font-size: 2em;
          padding: 5px;
          cursor: pointer;
      }

      .card-footer {
        width: 100%;
        float: right;
      }

      .card-message-action-block-occasion {
          font-size: 0.9em;
      }
      .card-message-continue {
          font-size: 1em;
      }
    }
}