@media (min-width: 450px) {
    .product_photo:hover .product_alert {
        opacity: 1;
    }
    .product_alert {
        opacity: 1;
        height: 40px;
        
        .product_alert_content {
            padding: 10px;
            margin-top: -2px;
            .product_alert_header {
                padding: 0 0 1px 0;
            }
            .product_alert_body {
                padding: 1px 0 0 0;
                opacity: 1;
            }          
        }
    }
}

@media only screen and (min-width: 555px) {
    .product-grid {
        width: 95%;
    }
}