// Fading
.fade {
    opacity: 0;
    transition: opacity .15s linear;

    &.in {
        opacity: 1;
    }
}


// Collapsing
.collapse {
    display: none;
    visibility: hidden;

    &.in {
        display: block;
        visibility: visible;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s linear, visibility .35s linear
}

// Loading fade
@keyframes loading_fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.u-loadingFade {
    animation: loading_fade 2s ease infinite;
}