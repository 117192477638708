.btn {
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;

    &:hover {
        text-decoration: none;
    }
}

.btn--uppercase {
    text-transform: uppercase;
}

.btn-success {
    @include font-proximanova-bold;
    box-sizing: border-box;
    font-size: em(24px);
    color: #fff;
    background: $btnSuccess-color;
    border: solid 1px $btnSuccess-color;

    &:hover {
    	color: #fff;
    	background: #03A24A;
    }
}

.btn-nofill {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border: 1px solid $btnNoFill-color;
    color: $btnNoFill-color;
    text-transform: uppercase;

    &:hover {
        color: #333;
        background: $btnNoFill-color;
    }
}

// Account Buttons
.account-submitBtn,
.fb-submitBtnText {
    @include font-proximanova-regular;

    font-size: 20px;
}
.account-submitBtn {
    display: block;
    width: 100%;
    margin: 5px 0;
    border-radius: 4px;
}
.account-submitBtn--fb {
    position: relative;
    color: #fff;
    width: 100%;
    background: $btnFb-color;

    .fb-icon {
        font-size: 26px;
        position: absolute;
        left: 13px;
        top: 8px;
    }
}