.store.list-item {
    .store_header {
        margin-bottom: em(15px);
        overflow: hidden;
    }

    .store_avatar {
        display: none;

        width: em(63px);
        height: em(63px);
        overflow: hidden;
        border-radius: 50%;
        margin-right: em(10px);

        img {
            height: auto;
            width: 100%;
            background-size: cover;
        }

        @media (min-width: 768px) {
            @include inline-box;
        }
    }

    .store_info {
        @include font-proximanova-regular;
        @include inline-box;

        max-width: 66.66666%;
        color: #666;
        margin-top: em(2.8px);
    }

    .store_name {
        display: inline-block;
        margin-bottom: em(10.79989px, 29px);
        color: #333;
        font-size: 25px;

        &:hover {
            text-decoration: none;
        }
    }

    .store_details {
        li {
            @include inline-box;

            font-size: 14px;
            line-height: 16px;
            margin-right: em(9px, 16px);
            margin-bottom: 5px;
            vertical-align: middle;

            &.storeRating {
                img {
                    height: em(20px, 16px);
                }
                .rating-box {
                    margin-top: 2px;
                }
            }
        }
    }

    .store_storeLink {
        @include font-proximanova-semibold;
        
        float: left;
        padding: em(14px, 16px) em(35px, 16px) em(15px, 16px) em(30px, 16px);
        border: 1px solid #638c1c;

        font-size: 13px;
        text-transform: uppercase;
        color: #638c1c;
        border-radius: 4px;
        margin-top: em(8px, 16px);
        text-decoration: none;

        &:hover {
            color: #FFF;
            background: #638c1c;
        }

        @media (min-width: 500px) {
            float: right;
            max-width: 33.3333%;
        }
        @media (min-width: 768px) {         
            font-size: em(16px);
        }
    }

    .store_products {
        @include grid;
        margin-bottom: em(15px);
    }

    .otherProducts-designs {
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 11px;

        &:hover {
            .otherProducts-countWrapper {
                transform: scale(1.1) translate(-45%, -50%);
            }
        }

        img {
            width: 50%;
            padding: 1px;
            float: left;
            box-sizing: border-box;
        }
    }

    .otherProducts_link {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
    }

    .otherProducts-countWrapper {
        @include font-proximanova-semibold;
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        display: table;
        width: em(114px, 18px);
        height: em(114px, 18px);
        
        font-size: em(12px);        
        color: #666;
        text-align: center;
        text-transform: uppercase;

        background: #fff;
        border-radius: 50%;
        transition: all .2s ease-in-out;
        
        .otherProducts-count {
            display: table-cell;
            vertical-align: middle;
        }

        .otherProducts-countNum {
            display: block;
            font-size: em(25px, 12px);
            position: relative;
            left: em(-5px, 25px);

            &::before {
                content: "+";
                font-size: em(21px, 25px);
                position: relative;
                top: em(-2px, 31px);
            }
        }
    }

    .otherProducts-title {
        @include font-proximanova-semibold;

        text-transform: uppercase;
        color: #638c1c;
        font-size: em(16px);

        a {
            color: #638c1c;
        }
    }

    .feedback_photo {
        width: em(42px);
        float: left;
        margin: em(4px) em(7px) 0px em(3px);

        img {
            width: 100%;
            border-radius: 4px;
        }
    }

    .feedback_desc {
        font-size: em(17px);
        color: #333333;
        overflow: hidden;

        p {
            line-height: em(24px, 17px);
        }
    }
}
