.tabs-nav {
    background: #efefef;
    border: 1px solid #eee;
    border-bottom: 0;

    li {
        @include font-proximanova-semibold;
        float: left;
        width: 33.333333%;
        height: 3.442857em;
        font-size: em(26.8px);
        text-align: center;
        color: #333;
        line-height: 1.2;
        cursor: pointer;

        &.active {
            background: #fff;

            a {
                cursor: pointer;
            }
        }

        &:hover {
            background: #fff;

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }

        a {
            padding: em(19.39992px, 24px) em(39px, 24px) em(19.6px, 24px) em(39px, 24px);
            display: block;
            color: #333;
        }
    }
    
    .badge {
        background: #fff;
        font-size: em(18px, 24px);
        color: #333;
        padding: em(2px, 24px) em(14px, 24px);
        border-radius: 50%;
    }
}

.tab-pane {
    display: none;
    padding: 10px 5px 20px;

    &.active {
        display: block;
    }
}

// Breakpoints
@include breakpoint(phablet) {
    .tabs-nav {
        li {
            font-size: em(16px);
            width: auto;
            text-align: left;
    
            a {
            	padding: em(29px, 24px) em(39px, 24px) em(34px, 24px) em(39px, 24px);
            }
        }
    }
}
@include breakpoint(desktop) {
    .tabs-content {
        padding: em(17px) em(32px);
        border: 1px solid #eee;
    }
}