@include breakpoint(phablet) {

// Uncenter all content
.page_header,
.main,
.product_desc,
.aside,
.product_tabs {
    max-width: none;
}

// Hide mobile product title
.page_header {
    display: none;
}


.product_info_title {
    display: block;
    color: #656565;
    font-size: em(18px);
    text-align: center;
    margin-bottom: em(-3px, 18px);
}

// Product Images
.main {
    float: left;
    width: 49%;
    max-width: 600px;
    margin-bottom: auto;
}
.mediaGalleryImg {
    max-width: 15.6%;
}

.aside {
    float: right;
    width: 49%;

    .store {
        padding: em(17px) em(18px) em(16px) em(18px);
        margin: em(12px) 0 em(30px);
    }

    .owner_photo {
        width: em(120px);
        height: em(120px);
        margin-right: em(14px);
    }

    .store_name {
        font-size: em(25.9px);
    }

    .store_florist {
        font-size: em(16px);
    }

    .reviewCount {
        margin-top: 0.14444em;
        font-size: 0.94286em;
        color: #8e8e8e;
    }

    .store_link {
        height: em(31px, 12px);
        padding: 0 em(3px, 12px);
        border-radius: 4px;
        font-size: em(12px);
        line-height: em(31px, 12px);
    }
}

.product_desc {
    display: block;
    color: #666;
    font-size: em(12px);
    margin-top: 2em;

    h3, h6 {
        @include font-proximanova-semibold;
        margin-bottom: em(8px, 12px);
        font-size: 14px;
    }

    p {
        line-height: em(18px, 12px);
        font-size: 13px;
    }
}

#content {
    padding: 0px em(34px);
}

// Tab Styles
.product_tabs {
    clear: both;
}

#tab-about,
#tab-reviews,
#tab-delivery-information,
#tab-substitution-policy {
    font-size: 1em;
}
.tab-pane {
    p {
        font-size: 14px;
        line-height: 25px;
    }
}

// Product Grid Styles
.product-grid {
    width: 100%;
    text-align: center !important;

    .product.card {
        width: 20%;
        box-sizing: border-box;
        margin: 10px;
    }

    .product.card .product_florist {
        .florist_name {
            font-size: 11px;
        }
        .florist_delivery {
            font-size: 10px;
        }
    }
}

#tab-other-design .product.card .product_name a {
    line-height: 22px;
}

// Product Grid Footer
.pagination {
    float: right;
}

}