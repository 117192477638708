.zip-code-banner {
  display: none;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;

  margin-bottom: 25px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;

  @include breakpoint(tablet) {
    display: block;
  }

  .banner {
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .4);
      border-radius: 5px;
    }

    &__center {
      position: relative;
      width: 70%;
      margin: 0 auto;
      padding: 25px;

      background: #FFF;
      color: #333;
      border-radius: 5px;
    }

    &__header {
      margin-bottom: 20px;
      font-family: Bodoni, serif;
      font-size: 24px;
    }

    &__enter-zip {
      margin-bottom: 20px;
      font-family: 'ProximaNova', sans-serif;
      font-size: 18px;
    }

    &__zip-input {
      .delivery-zip-filter-btn {
        margin-left: 5px;
      }

      #pac-input-two {
        width: 200px;
      }
    }
  }
}