.topShops {
    .shop {
        width: 100%;
        background: white;
        border: 0;
        padding: 0;
        padding-bottom: em(19px);
        box-sizing: border-box;
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
        text-align: center;
        margin-bottom: em(14px);
        
        &:hover {
            box-shadow: 0 2px 3px 0 rgba(0,0,0,0.24);
        }
    }

    .shop_cover {
        margin-bottom: em(21px);

        img {
            width: 100%;
            box-sizing: border-box;
        }
    }

    .shop_name {
        @include font-proximanova-light;
        font-size: 20px;
        color: #205f64;
        margin-bottom: em(15px, 32px);

        a {
            color: #2d848a;
        }
    }

    .shop_location {
        @include font-proximanova-light;
        font-size: em(22px);
        font-style: italic;
        color: #2d848a;

        a {
            color: #555;
        }
    }
}

.topShops {
    @media (min-width: 320px) {
        .shop {
            width: calc-percent(357px, 728px);
        }
    }

    @include breakpoint(phablet) {
        .shop {
            width: calc-percent(370px, 1162px);
            padding-bottom: em(16px);

            &:first-child {
                display: none;
            }
        }

        .shop_cover {
            margin-bottom: em(18px);
        }

        .shop_name {
            font-size: 20px;
            margin-bottom: em(12px, 23px);
        }

        .shop_location {
            font-size: em(16px);
        }
    }

    @include breakpoint(laptop) {
        max-width: 1162px;

        .shop {
            width: calc-percent(271px, 1162px);

            &:first-child {
                display: inline-block;
            }
        }
    }
}

