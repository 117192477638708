/* Account Management */
.settings {
    margin: 40px auto;
}

// Account Management - Head
.settings-head {
    text-align: center;
}
.settings-header {
    @include font-proximanova-light;
    font-size: 32px;
    margin-bottom: 15px;
}
.settingsNav {
    margin-bottom: 35px;
    background: #F3F3F3;
    text-align: center;
}
.settingsNav-link {
    display: inline-block;
    padding: 15px 25px;
    color: #333;
    
    &:hover {
        text-decoration: none;
    }
    &.isActive {
        border-top: 2px solid #77BD3A;
    }
}

// Account Management - Body
.setting {

    .btn-nofill {
        border: 1px solid #21a0a2;
        color: #21a0a2;

        &:hover {
            color: #fff;
            background: #21a0a2;
        }
    }
    .btn-full {
        display: block;
        padding: 15px;
        background: #ddd;
        color: #fff;
    }
    .text-control,
    .select-control {
        padding-bottom: 15px;

        label {
            font-weight: 400;
        }
    }
    .text-control {
        input {
            max-height: 40px;
        }
    }
    .select-controlWrapper {
        box-sizing: border-box;
        font-size: 14px;

        .select-control {  
            display: inline-block;
            width: 100%;
            margin-top: 0;

            select {
                width: 100%;
                height: 40px;
                margin-top: 0;
                border: 1px solid #DCDCDC;
            }
            &::after {
                top: 38%;
            }
        }
        label {
            display: block;
            margin-bottom: 5px;
        }
    }
    .select-controlWrapper--month,
    .select-controlWrapper--year {
        float: left;
        width: 50%;
    }
    .select-controlWrapper--month {
        padding-right: 20px;
    }
}
.setting {
    // display: none;

    &.isActive {
        display: block;
    }
}
.setting-summaryWrapper,
.setting-container {
    box-sizing: border-box;
    width: 50%;
}
.setting-container--orders {
    width: 100%;
}
.setting-summaryWrapper {
    float: left;
    padding-right: 25px;
}
.setting-container {
    float: right;
    border-left: 1px solid #CDCDCD;
    padding-left: 25px;
}
.setting-container--orders {
    padding-left: 0;
    border-left: 0;
}
.setting-header {
    padding-bottom: 5px;
    margin-bottom: 30px;
    border-bottom: 1px solid #CDCDCD;
}
.setting-summaryWrapper {

    .setting-header {
        @include font-proximanova-light;

        font-size: 21px;
        text-transform: uppercase;
    }
}
.setting-container {
    
    .setting-header {
        font-size: 24px;
        font-weight: 400;
    }
}
.settingSummary-list  {
    margin-top: 20px;
}
.selectList {
    border: 1px solid #21a0a2;
    border-radius: 4px;
}
.selectListItem {
    box-sizing: border-box;
    padding: 15px;
    border-bottom: 1px solid #21a0a2;
    color: #333;
    cursor: pointer;

    .selectListItem-iconContainer {
        float: left;
        color: #c5c5c5;
        font-size: 36px;
    }
    .selectListItem-info {
        float: left;
        margin-left: 15px;
    }
    &.isActive {
        color: #fff;
        background: #21a0a2;

        .selectListItem-iconContainer {
            color: #fff;
        }
    }
    &:last-of-type {
        border-bottom: 0;
    }
}
.setting-edit {
    float: right;
}

.newFormSubmit {
    .btn-success,
    .newFormCancelBtn {
        @include font-proximanova-regular;

        padding: 10px 20px;
        font-size: 18px;
    }
    .newFormCancelBtn {
        background: #ccc;
        color: #333;
    }
}

// Orders
.orders-tableHeader,
.orders-tableContent {
    width: 100%;
}
.orders-tableContent td {
    width: 20%;
}
.orders-detailsBtn {
    white-space: nowrap;
    padding: 7px 15px;
    border: 1px solid #4c79bb;
    border-radius: 20px;
    color: #4c79bb;

    &:hover {
        text-decoration: none;
        background: #4c79bb;
        color: #fff;
    }
}

// Profile
.profileRow-header {
    display: inline-block;
    width: 90px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 11px;
    color: #999;
}

// Billing and Delivery
.address-residence,
.creditCard-info {
    display: block;
    margin-bottom: 5px;
}