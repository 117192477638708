.column_title {
    @include font-proximanova-semibold;
    margin-bottom: em(21.25px, 17px);
    color: #333;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
}

.nav_links_item {
    font-size: 14px;
    margin-bottom: em(20px, 13px);

    a {
        color: $footerNav-color;
    }
}

.socialIcon {
    width: 14px;
    text-align: center;
}