.text-control {
    @include font-proximanova-bold;
    padding-bottom: 6px;

    &.m-with-icon {
        position: relative;

        .searchIcon {
            position: absolute;
            top: .85em;
            left: em(34.8px, 38px);
            z-index: 2;
            max-width: 11px;
            color: #ccc;
        }
    }
    
    label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        @include reset-text-input;
        position: relative;
        background: #fff;
        font-family: inherit;
        font-size: 16px;
        line-height: 1.6;
        color: #333;
        width: 100%;
        border: 1px solid $input_border-lightgrey;
        border-radius: 3px;
        padding: em(16px, 28px) em(10px, 28px) em(14px, 28px) em(23px, 28px);

        &:focus,
        &:hover {
            background: #FCFCFC;
        }
    }
}
