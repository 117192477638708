//colors
$bn-notification-color: #3b4146;
$white: #fff;
$close-hover: #707070;

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

#notification-bar {
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: $bn-notification-color;
  color: $white;

  svg {
    transition: transform .5s ease-in-out;
    cursor: pointer;
    height: 24px;
    width: 24px;
    &:hover {
      @include rotate(180);
    }
  }
  svg > path {
    stroke: #3b4146;
  }
  svg:hover > circle {
    fill: $close-hover;
    stroke: $close-hover;
  }
  svg:hover > path {
    stroke: $white;
  }
}

#notification {
  .notification-content {
    ul {
      width: 75%;
      list-style: none;
      margin: 15px auto;
      li {
        margin: 0 auto;
        line-height: 17px;
        word-wrap: normal;

        @include breakpoint(mobile) {
          font-size: 11px;
        }
        @include breakpoint(phablet) {
          font-size: 12px;
        }
        @include breakpoint(tablet) {
          font-size: 13px;
        }
      }
    }
  }
}

.notification-close {
  position: absolute;
  right: 8px;

  @media only screen and (min-width: 550px) {
    right: 20px;
  }

  top: 50%;
  @include transform(translateY(-50%));
  height: 24px;
  width: 24px;

  .btn-remove {
    text-decoration: none;
    height: 24px;
    width: 24px;
  }

  a {
    text-decoration: none;
  }
}
