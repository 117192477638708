#cityDiv {
    padding: 20px;

    h1, h2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
    }

    p.heading-subtext {
        font-size: 16px;
        margin-top: 14px;
        line-height: 1.2;
    }

    div.sitemapInfo {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        margin-top: 24px;
        width: 100%;
    }

    div.col-list-state {
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }

    .state-header {
        display: flex;
        justify-content: space-between;
    }

    .stateName {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
    }

    .state-header i {
        font-size: 22px;
        color: #d8247f;
    }

    ul {
        margin-top: 5px;
    }

    ul > li {
        padding: 4px 0;
    }

    a {
        color: #000;
        font-size: 14px;
        line-height: 1.2;
        white-space: normal;
        width: 100%;
    }

    li.topcities-seemore a {
        padding: 0;
        text-decoration: none;
        transition: border-color .3s ease-in-out, color .3s ease-in-out;
        color: #d8247f;
        display: flex;
        align-items: center;
        gap: 2px;

        .topcities-seemore-arrow {
            font-size: 16px;
            color: #d8247f;
            transition: color .3s ease-in-out;  
        }

        &:hover {
            color: #000;
            text-decoration: none;
            
            .topcities-seemore-arrow {
                color: #000;
            }
        }
    }

    .sitemapInfo.state-cities {
        display: block;
    }

    .col-list-cities ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, calc((100% - (3 * 16px)) / 4)));
        gap: 16px;
    }

    @media screen and (max-width: 910px) {
        .col-list-cities ul {
            grid-template-columns: repeat(auto-fit, minmax(200px, calc((100% - 48px) / 3)));
        }
    }

    @media screen and (max-width: 768px) {
        .col-list-cities ul {
            grid-template-columns: 1fr;
        }
    }

    .col-list-cities ul li {
        border-radius: 10px;
        padding: 14px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }

    .col-list-cities ul li a {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .col-list-cities ul li a:hover {
        text-decoration: none;
        color: #d8247f;
    }

    .col-list-cities ul li a p {
        font-size: 14px;
    }

}
