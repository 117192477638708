.breadcrumbs {
    li {
        font-size: em(15px);
        color: #726c71;
        display: inline-block;

        &::after {
            content: " >";
        }

        &:last-child::after {
            display: none;
        }

        a {
            color: #726c71;
        }
    }
}

.pdp-breadcrumbs-wrapper {
    padding: 10px em(15px) 0 em(15px);
    margin-bottom: 0;
    @include breakpoint(phablet) {
        padding: 0 em(34px);
        margin-bottom: em(-48px);
    }
    @include breakpoint(desktop) {
        padding: 0 em(64px);
    }

    .breadcrumbs {
        max-width: 500px;
        margin: auto;
        display: block;
        position: static;
        padding: 0;

        li {
            font-size: 12px;
        }

        @include breakpoint(phablet) {
            padding: 2em em(34px) 20px em(34px);
            margin: 0;
            max-width: 1040px;
            li {
                font-size: 15px;
            }
        }
        @include breakpoint(desktop) {
            margin: 0 auto;
            max-width: 1040px;
            li {
                font-size: 15px;
            }
        }
    }
}
