@include breakpoint(tablet) {
    .coverPhoto-container--login {
        float: left;
        padding-left: 5%;
    }
    .coverPhoto-container--signup {
        padding-left: 5%;
    }

    .account-page {
        min-height: 700px;
    }

    .account-card {
        margin: 75px auto;
    }
}