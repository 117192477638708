.collapse-info {
    color: #726c71;

    p {
        margin-bottom: em(11.375px, 13.9px);
        font-size: em(13.9px);
        line-height: em(20.3px, 13.9px);
        color: #888;
        display: inline;
    }

    .teaser {
        display: inline;

        .teaser p {
            display: inline-block;
        }
    }

    .full-text {
      padding: 10px 0;
    }

    .toggle-full-text {
        font-size: em(13.9px);
        color: #fff;
        display: inline;
        font-weight: 600;
    }
}