@mixin flex-box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.hero {
    @include flex-box;
    position: relative;
    align-items: center;
    justify-content: space-around;
    height: 475px;

    &__content-wrapper {
        position: relative;
        flex-basis: 1040px;
        flex-shrink: 1;
        padding: 0 10px;
    }

    &__content {
        max-width: 290px;
        margin: 0 auto !important;
        padding: 50px 25px 15px 25px;
        text-align: center;
        color: #464646;

        p {
            @include font-proximanova-regular;
            font-size: 13px;
        }
        h1 {
            @include font-bodoni;
            font-size: 20px;
            letter-spacing: -1px;
            line-height: 1.2;
        }

        @media only screen and (min-width: 415px) {
            padding: 50px 50px 15px 50px;

            h1 {
                font-size: 22px;
            }
        }
    }

    &__notification {
        display: block;
        margin: 10px 0 0 0;
        box-sizing: border-box;
    }

    &__content-header {
        p {
            margin-bottom: 15px;
        }
    }

    &__content-divider {
        width: 33%;
        margin: 20px auto;
        border-bottom: 1px solid #a7a7a7;
    }
}

#content {
    margin-bottom: 50px;
}

.topShops {
    max-width: 1042px;
    padding: 0 5px;
    margin: 0 auto;
}

.search-form_row {
    height: auto;
    margin-bottom: 13.5px;

    label {
        display: block;
        margin: 0 0 18.3px;
        line-height: 1.3;
        text-align: center;
        color: $searchBtn-spring2017BgColor;
        font-size: 20px;
        font-weight: 600;
    }
}

.text-control {
    input {
        padding: 14px 0.65714em 15.9px 32px;
        line-height: 1.2;
        font-size: 11px;
        color: $intro_content-grey;
    }
    .searchIcon {
        top: 14px;
        left: 10px;
    }
}

.page_section {
    margin-bottom: em(51px);
}

.page_section_header {
    padding: 0 em(30px);
    margin: 45px 0;

    .h1 {
        font-size: 22px;
    }
}

.search-btn {
    @include font-proximanova-bold;

    font-size: 18px;
    padding: 10px 0;
    line-height: 1.32;
    border-radius: 2px;
}

@media screen and (min-width: 321px) {
    .text-control {
        input {
            padding: 14px 0.65714em 15.9px 35px;
        }
    }
}
