// Calendar

.datepickr-wrapper {
    display: inline-block;
	width: 100%;
	height: 100%;
}

.datepickr-calendar {
    z-index: 100;
    position: absolute;
    right: 0px;
    display: none;
    padding: 0 15px 15px;
    border: 1px solid #ccc;
    font-size: 14px;
    font-weight: 200;
	background-color: #fff;
	color: #333;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.open .datepickr-calendar {
    display: block;
}

// Hover States
.datepickr-calendar .datepickr-day:hover,
.datepickr-calendar .datepickr-prev-month:hover, 
.datepickr-calendar .datepickr-next-month:hover {
    color: #444;
    background: rgba(0,0,0,0.05);
}

// Months
.datepickr-calendar .datepickr-months {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
    color: #444;
	padding: 2px;
	text-align: center;
    font-size: 120%;
}
.datepickr-calendar .datepickr-prev-month,
.datepickr-calendar .datepickr-next-month {
    padding: 1em;
	text-decoration: none;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	cursor: pointer;
    font-size: 0.9em;
    color: #444;
}
.datepickr-calendar .datepickr-prev-month {
	float: left;
}
.datepickr-calendar .datepickr-next-month {
	float: right;
}
.datepickr-calendar .datepickr-current-month {
    display: block;
    padding: 0.75em;
}

// Table
.datepickr-calendar table {
    width: 100%;
    padding: 0;
	border-collapse: collapse;
    background: #fff;
}
.datepickr-calendar thead {
    font-size: 90%;
}
.datepickr-calendar th,
.datepickr-calendar td {
    width: 14.3%;
}
.datepickr-calendar th {
	text-align: center;
    padding: 5px;
}
.datepickr-calendar td {
	text-align: right;
	padding: 1px;
}

// Day
.datepickr-calendar .datepickr-day {
	display: block;
    padding: 11.2px;
    color: #444;
	background-color: #fff;
    text-align: center;
	cursor: pointer;
    border-radius: 4px;
}

// Selected
.datepickr-calendar .selected .datepickr-day {
    background: #00b551;
    color: #fff;
}

// Disabled
.datepickr-calendar .disabled .datepickr-day, 
.datepickr-calendar .disabled .datepickr-day:hover {
    color: #ccc;
    background: #fff;
    cursor: not-allowed;
    border: 0;
}

// Media Queries
@media (min-width: 768px) {
    .datepickr-calendar .datepickr-day {
        padding: 16px;
        font-size: 17px;
    }
}