.social-icons{
	margin-top: 10px; 
	color: #555; 
	a{
		img{
			display:inline-block; 
		}
	}
}

