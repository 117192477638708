.notification {
  display: none;
  position: relative;
  transition: opacity .25s ease-in-out;
  opacity: 0;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;
  background: $success-light;
  border: 1px solid $success-dark;
  border-radius: 2px;
  box-sizing: border-box;

  &__text {
    width: 90%;
    margin: 0;
    color: #555;
    font-size: 13px;
    line-height: 1.2;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    font-size: 24px;
    color: #777;
    cursor: pointer;
  }

  &--visible {
    display: block;
    transition: opacity .25s ease-in-out;
    opacity: 1;
  }

  &--error {
    background: $error-light;
    border: 1px solid $error-dark;
  }

  &--warning {
    background: $lightYellow;
    border: 1px solid $darkYellow;
  }
}

@include breakpoint(phablet) {
  .notification {
    padding: 14px;
    font-size: 13px;
  }
}
