@import "../modules/header-nav";

#header {
    @include font-proximanova-regular;

    background: #fff;
    padding: 10px 5px;
    font-size: 1.25em;
    color: #555;

    .mobile-logo{
        display: none;
        &.mobile-logo-pdp{
            display: inline;
        }
    }
    .desktop-logo{
        display: inline;
        &.desktop-logo-pdp{
            display: none;
        }
    }

    .logo {
        align-items: flex-start;
        flex-shrink: 0;
        margin-top: 5px;
        max-width: 175px;
        .logo_image {
            height: auto;
            width: 100%;
        }
    }

    .header-nav {
        margin-left: auto;
    }

    .userbar,
    .header-navToggle {
        font-size: 1.86349em;
        float: right;
    }
    .userbar {
        position: relative;
    }
}

.header_content {
    // specificity is a bit off because bootstrap is included after screen.min.css
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

    @include breakpoint(350px) {
        padding: 0 15px;
    }
}

// Checkout Security Insurance
.header-secure-badge {
    padding-left: 10px;

    span {
        color: #bbb;
    }
    .fa-stack {
        font-size: 13px;
    }
    .secure_checkout,
    .secure_checkout-extraText {
        display: none;
    }
}

// Breakpoints
@media (min-width: 420px) {
    #header {
        font-size: 1em;
    }
    .header-secure-badge {
        .fa-stack {
            font-size: 18px;
        }
    }
}

@media (min-width: 568px) {
    .header-secure-badge {
        padding-right: 5px;

        .fa-stack {
            font-size: 22px;
        }
        .secure_checkout,
        .secure_checkout-extraText {
            display: block;
            font-weight: 600 !important;
        }
        .secure_checkout {
            float: right;
            margin-top: 8px;
            margin-right: 5px;
            line-height: 14px;
        }
    }
}

@include breakpoint(phablet) {

    #header {

        .mobile-logo{
            display: none;
            &.mobile-logo-pdp{
                display: none;
            }
        }
        .desktop-logo{
            display: inline;
            &.desktop-logo-pdp{
                display: block;
            }
        }

        padding: 14px;

        .logo {
            max-width: 232px;
            height: 35px;
            margin-top: 0;
        }

        .userbar {
            font-size: 1.28571em;
            float: right;
        }
    }
}