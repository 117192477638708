@mixin center-block($width: $max-width) {
	margin: 0 auto;
	max-width: $width;
}

@mixin reset-btn {
	border: none;
	background: none;
	margin: 0px;
	padding: 0px;
  	cursor: pointer;
}

@mixin reset-text-input {
	border: none;
	background: #fff;
	margin: 0px;
	padding: 0px;
	box-shadow: none;
    box-sizing: border-box;
}

@function em($target, $context: $base-font-size) {
	@if $target == 0px {
		@return 0
	}
	@return calc($target / $context) + 0em;
}

@mixin clearfix {
  	&:after {
    	content: "";
    	display: table;
    	clear: both;
  	}
}

@mixin inline-box($width: false) {
	display: inline-block;
	vertical-align: top;
	@if $width != false {
		width: $width;
	}
}

@mixin grid {
    text-align: justify !important;
    text-justify: distribute-all-lines;

    & > * {
        text-align: left;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}

@mixin grid-cell($width: false) {
    @include inline-box($width);
}

@function calc-percent($target, $context: $base-width) {
	@if $target == 0 {
		@return 0
	}
	@return calc($target / $context) * 100%;
}

@mixin keyframes( $animationName )
{
    @-webkit-keyframes #{$animationname} {
        @content;
    }
    @-moz-keyframes #{$animationname} {
        @content;
    }
    @-o-keyframes #{$animationname} {
        @content;
    }
    @keyframes #{$animationname} {
        @content;
    }
}

@mixin breakpoint($point) {
    @if $point == mobile {
        @media (min-width: 320px) { @content ; }
    }
    @else if $point == phablet {
        @media (min-width: 768px) { @content ; }
    }
    @else if $point == tablet {
        @media (min-width: 960px) { @content ; }
    }
    @else if $point == laptop {
        @media (min-width: 1024px)  { @content ; }
    }
    @else if $point == desktop {
        @media (min-width: 1152px)  { @content ; }
    }
    @else if $point == desktop-hd {
        @media (min-width: 1280px)  { @content ; }
    }
    @else {
        @media (min-width: $point) { @content ; }
    }
}

@mixin border-box {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin screen-box-shadow() {
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
}
