.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.toolbar-bottom {
    clear: both;
    padding-top: 10px;
}

.applied-filters-count {
    font-weight: 700;
}

.mobile-filters-toolbar {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background: #ffffff;

    .mobile-filters-container {
        width: 100%;
        min-height: 100%;
        max-width: 375px;
        padding: 20px 30px;
        position: fixed;
        z-index: 1060;
        background: #FFFFFF;

        .header {
            margin-bottom: 20px;
            height: 30px;

            .title {
                float: left;
                font-size: 20px;
            }

            .close-mobile-filters-container {
                background: transparent;
                border: none;
                float: right;
                opacity: 1;
            }
        }

        .mobile-toolbar-overflow-wrapper {
            overflow-y: scroll;
            height: calc(100vh - 84px - 80px);

            .sorting-option-title {
                color: #555;
                font-size: 16px;
                font-weight: 700 !important;
                line-height: 18px;
                padding-bottom: 20px;
                margin-left: 0;

                .pull-left {
                    font-weight: 700 !important;
                }
            }

            .sidebar-mobile {
                a {
                    color: #333333;
                    text-decoration: none;
                }

                .sorting-option-title {
                    padding-top: 20px;
                    margin-top: 0;
                    margin-right: 13px;
                    border-top: 1px solid #CFCFD4;

                    a {
                        line-height: 16px;
                        font-weight: 700 !important;
                    }

                    &--postal-code {
                        .filter_option {
                            display: flex;
                            gap: 5px;
                            text-decoration: none !important;
                            margin-bottom: 0;

                            & > span:not(.clear) {
                                text-decoration: underline !important;
                            }
                        }
                    }
                }

                .mobile-filter-postal-code {
                    display: grid;
                    grid-template-columns: 70% 1fr;
                    gap: 10px;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    margin-right: 13px;

                    input[type='text'] {
                        margin-bottom: 0;
                        width: 100%;
                    }

                    .error-msg {
                        font-size: 14px;
                        grid-row-start: 2;
                        grid-column: span 2;
                    }
                }

                input[type='text'],
                input[type='date'] {
                    width: 96%;
                    border: 1px solid #CFCFD4;
                    border-radius: 4px;
                    padding: 8px 12px;
                }

                input[type='text'],
                input[type='date'],
                .seemore-text {
                    margin-bottom: 20px;
                }

                @supports (-webkit-touch-callout: none) {
                    input[type='date'] {
                        display: block;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        min-height: 34px;
                        position: relative;
                        width: 96%;
                        background: #fff;

                        &:after {
                            content: '\f073';
                            font-family: 'FontAwesome';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 9px 25px 0 0;
                            width: 1.2em;
                            height: 1.2em;
                            pointer-events: none;
                        }
                    }
                }

                .sorting-options {
                    padding: 0;
                    margin: 0 0 20px 0;

                    li {
                        label {
                            span {
                                color: #464646;
                                font-size: 14px;
                            }

                            input[disabled] ~ span,
                            input[disabled] ~ i,
                            input[disabled] ~ i:before,
                            input[disabled] ~ i:after {
                                opacity: 0.5;
                                cursor: not-allowed;
                            }
                        }

                        label:has(input[type='checkbox']:focus) > i.filterby-on-plp-checkbox::before {
                            outline: 5px auto -webkit-focus-ring-color;
                            outline-offset: 4px;
                        }
                    }

                    label {
                        display: flex;
                        margin: 0 0 20px 0;

                        .fa {
                            width: 16px;
                            margin-right: 14px;
                            margin-top: -1px;
                        }

                        .fa-square-o:before {
                            background-color: transparent;
                            border: 1px solid #aaa;
                            border-radius: 2px;
                            content: "";
                            cursor: pointer;
                            display: inline-block;
                            margin-right: 12px;
                            padding: 7px;
                            position: relative;
                            vertical-align: middle;
                        }

                        .fa-check-square-o:before {
                            background: #d8247f;
                            border: 1px solid #d8247f;
                            border-radius: 2px;
                            content: "";
                            cursor: pointer;
                            display: inline-block;
                            margin-right: 12px;
                            padding: 7px;
                            position: relative;
                            vertical-align: middle;
                        }

                        .fa-check-square-o:after {
                            border: solid #FFFFFF;
                            border-width: 0 2px 2px 0;
                            content: "";
                            display: block;
                            height: 12px;
                            margin-left: 5px;
                            margin-top: -15px;
                            transform: rotate(45deg);
                            width: 6px;
                        }
                    }

                    &--postal-code {
                        .filter_option {
                            font-weight: 700 !important;
                            display: flex;
                            gap: 5px;
                            text-decoration: none !important;
                            margin-bottom: 0;

                            & > span:not(.clear) {
                                text-decoration: underline !important;
                            }
                        }
                    }
                }

                .seemore-text,
                .filter_option {
                    font-size: 14px;
                    text-decoration: underline;
                    padding: 0;
                    text-transform: capitalize;
                    margin-left: 0;
                }

                .filter_option {
                    cursor: pointer;
                }

                button.seemore-text {
                    background: none;
                    border: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        @media only screen and (max-width: 420px) {
            max-width: 100%;
        }
    }

    .mobile-toolbar-footer {
        display: flex;
        padding: 18px 20px;
        gap: 10px;
        position: fixed;
        bottom: 0;
        box-shadow: 0 -4px 4px rgb(171 171 171 / 25%);
        width: 100%;
        max-width: 375px;
        background: #FFFFFF;
        z-index: 1070;
        left: 0;
        @media only screen and (max-width: 420px) {
            max-width: 100%;
        }

        .footer-button-container {
            flex: 1;

            .clear-all-filters,
            .apply-filters {
                cursor: pointer;
                width: 100%;
                display: inline-block;
                font-weight: normal;
                padding: 10px 20px;
                font-size: 16px;
                text-align: center;

                .applied-filters-count {
                    font-weight: normal;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            .clear-all-filters {
                border: 1px solid #d8247f;
                color: #d8247f;
                border-radius: 4px;

                &:hover {
                    border: 1px solid #b51f6d;
                    color: #b51f6d;
                }
            }

            .apply-filters {
                border: 1px solid #d8247f;
                color: #FFFFFF;
                background-color: #d8247f;
                border-radius: 4px;

                &:hover {
                    background-color: #b51f6d;
                    border: 1px solid #b51f6d;
                }
            }
        }
    }

    .mobile-toolbar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100%;
        background: rgba(79, 79, 79, 0.79);
    }

    label.mobile-filters-radio-wrapper {
        .mobile-filters-radio {
            position: relative;
            display: block;
            float: left;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            border: 2px solid #3F3F3F;
            border-radius: 100%;
            -webkit-tap-highlight-color: transparent;

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #3F3F3F;
                transform: scale(0);
                transition: all 0s ease;
                opacity: 0.08;
                pointer-events: none;
            }
        }

        .mobile-filters-label {
            font-weight: 200;
            font-size: 14px;
            line-height: 16px;
        }

        input[type="radio"]:checked + .mobile-filters-radio:after {
            transform: scale(1);
            transition: all 0s cubic-bezier(0.35, 0.9, 0.4, 0.9);
            opacity: 1;
        }
    }
}


#serpA-page,
#page-storefront {
    .sticky {
        margin-top: 0 !important;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .show-filter-container {
        cursor: pointer;
        margin: 10px 0 20px 0;
        padding: 15px 0;
        text-align: center;
        background-color: #FBFBFB;
        border: 1px solid #EEE;

        i {
            font-size: 13px;
            color: #464646;
            margin-left: -5px;
        }

        span {
            font-size: 13px;
            color: #464646;
            cursor: pointer;
            margin: 10px 0 20px 0;
            padding: 15px 0;
            text-align: center;
            background-color: #FBFBFB;
        }
    }
}

/**
 * PLP
 */
#serpA-page {
    @include breakpoint(tablet) {
        .mobile-filter-container,
        .mobile-filter-container.active,
        .show-filter-container,
        .mobile-filter-overlay,
        .mobile-filter-overlay.active {
            display: none;
        }
    }
}

/**
 * Florist About Us Page
 */
#page-storefront {
    @include breakpoint(laptop) {
        .mobile-filter-container,
        .mobile-filter-container.active,
        .show-filter-container,
        .mobile-filter-overlay,
        .mobile-filter-overlay.active {
            display: none;
        }
    }
}
