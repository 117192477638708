.rating-box {
	display: inline-block;
	background: url('../../../../../../../js/jquery/raty/images/star-mini-empty.png') repeat-x;
	background-size: 16px;
	position: relative;
	width: 79px;
    height: 16px;
    margin-right: 3px;
}

.rating-box .rating {
	height: 16px;
	background: url(../../../../../../../js/jquery/raty/images/star-on.png) repeat-x;
	background-size: 16px;
}

.reviewCount {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
}
