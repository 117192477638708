#sidebar,
.breadcrumbs,
.collapse-info,
.headerBorder {
    display: none;
}

#main,
.headerWrapper {
    padding: 0px em(15px);
    margin: 0 auto;
}

.headerWrapper {
    position: relative;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    max-width: 1910px;

    @media (max-width: 768px) {
        display: none;
    }
}

.page_header_title {
    color: #333;
}

.page_header {
    border: 0;
}

.storeList {

    .store.list {
        border: 0;
        padding: 0;
        margin-bottom: 0;
    }
}

.product-categories {
    margin-bottom: em(27px);
}

#products_toolbar_filter {
    float: right;
    width: em(210px);
}

.otherProducts {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Product Grid Styles
.product.card {
    width: 49%;

    &:last-of-type {
        // Fixes leftover width spilling over
        margin-left: 2%;
    }
    .product_florist {
        display: block;
        margin: .4em 0;
        font-size: 13px;
        line-height: 1.25;
        color: #999;
    }
}
// Product Grid Footer
.pagination {
    text-align: center;
}

// Product Grid Footer (SERP Specific)
.products_toolbar,
.list_top,
.list_bottom {
    &::after {
        content: "";
        clear: right;
        display: table;
        width: 100%;
    }
}
.products_toolbar {
    padding: em(32px) 0 em(14px) 0;
    margin-bottom: em(17px);
    font-size: 13px;
    line-height: 1.2;
}
#products_toolbar_filter {
    float: right;
    width: em(210px);
}
.select_control {
    font-size: 0.6em;
}
.products_toolbar_title {
    font-size: 1.22857em;
}
