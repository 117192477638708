@import "../../modules/customer-settings";

html {
    font-size: 100%;
}

.intro--signup {
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
}
// Container
.coverPhoto-container--login,
.coverPhoto-container--signup  {
    padding-top: 5.91429em;

    .intro_title,
    .intro_subtitle {
        color: #333;
    }
    .intro_title {
        text-transform: uppercase;
    }
    .intro_subtitle {
        font-size: 16px;
    }
}
.coverPhoto-container--login {
    .intro_subtitle {
        font-weight: 400;
    }
}
.coverPhoto-container--signup {
    .intro_title {
        color: #FFF;
        font-size:40px;
    }
}
.coverPhoto-container--password {
    .intro_title {
        font-size: 36px;
    }
}

// Form Wrapper
.coverPhoto-formWrapper--signup {
    box-shadow: none;
    width: 35%;
    min-width: 330px;
}
.coverPhoto-formWrapper--login {
    width: 100%;
    box-shadow: 0em -11em 7.57143em 0.1em rgba(243,246,244,1);

    .btn-nofill {
        width: 90%;
    }
}

.account-page {
    padding: 20px;
    height: 600px;
    background-size: cover;
    background-position: center center;
}

.account-card {
    max-width: 400px;
    margin: 25px auto;
    padding: 30px;
    background: rgba(250, 250, 250, .8);
    color: #3d3d3d;

    &__title {
        margin: 15px 0;
        text-align: center;
        font-size: 24px;
        font-weight: 700;

        @media only screen and (min-width: 321px) {
            font-size: 30px;
        }
    }

    &__subtitle {
        margin: 15px 0;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}

.account-form {
    &__input {
        @include border-box;
        width: 100%;
        padding: 20px;
        border: 1px solid #DDD;
        border-top: none;
        font-size: 15px;

        &:first-of-type {
            border-top: 1px solid #DDD;
        }
    }

    &__button {
        @include border-box;
        display: block;
        background: $account-form-button-color;
        width:  100%;
        margin-top: 10px;
        padding: 20px;
        text-align: center;
        color: #FFF;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 600;

        &:hover {
            background: $account-form-button-color--hover;
        }
    }

    &__footer {
        margin-top: 25px;
    }

    &__link {
        font-size: 10px;

        a {
            text-decoration: none;
            color: #3D3D3D;

            &:hover {
                text-decoration: underline;
            }
        }

        @media only screen and (min-width: 321px) {
            font-size: 12px;
        }
    }

    &__left {
        float: left;
    }

    &__right {
        float: right;
    }

    &__password-show {
        float: right;
        margin: 5px;

        span {
            font-size: 12px;
        }

        input {
            -webkit-appearance: checkbox;
        }
    }

    #signupForm {
        .g-recaptcha {
            float: left;
            margin: 5px 0px 13px 0px;
        }
    }

}

.horizontal-line {
    width: 30%;
    margin: 20px auto;
    background: #CCC;
    height: 1px;
}

// Form Styles
.loginForm,
.signupForm,
.passwordForm  {
    .text-control {
        margin-bottom: 8px;
    }
    label {
        @include font-proximanova-regular;
        font-size: 14px;
    }
}