@include breakpoint(tablet) {
    .hero {
        height: 500px;

        &__content-wrapper {
            flex-shrink: 1;
            padding: 0 40px;
            margin: 0 auto;
        }

        &__content {
            max-width: 400px;
            float: right;

            h1 {
                font-size: 29px;
            }
        }
    }

    .search-form_row {
        margin-bottom: 20px;
    }

    .search-form_row label {
        margin-bottom: 21.6px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
    }

    .text-control {
        input {
            font-size: 15px;
            padding: 16.5px 15px 18.5px 47.3px;
        }
        .searchIcon {
            top: 1.05em;
            left: 1.3em;
            max-width: 14px;
        }
    }

    .search-btn {
        padding: 13px 0;
        font-size: 19px;
        line-height: 1.42;
    }

    .page_section_header {
        .h1 {
            font-size: 30px;
        }
    }
}
