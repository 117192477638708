@include breakpoint(phablet) {
	.page_header {
	    display: block;
	}

	.product-categories {
		display: none;
	}

	.products_toolbar {
		padding: 0 0.64286em 0;


		&::after {
			display: none;
		}
	}

	.list_top .pagination,
	.list_bottom .products_toolbar {
		display: none;
	}

	.products_toolbar_title {
		font-size: em(20px);
	}

	#content {
		padding: 0 em(34px);
	}

	.product-grid {
		width: 100%;
	}

  // Product Grid Footer
  .pagination {
      float: right;
  }

  .product_alert {
      height: 40px;
      .product_alert_content {
      	font-size: 13px;
          padding: 8px;
          .product_alert_body {
          	opacity: 1;
          }
      }
  }
}
