// Alerts on the SERP and Product Pages
// Not to be confused with system alerts
.vendorAlert {
    margin: 15px 10px 0;
}
.vendorAlert-innerWrapper {
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #fff;
}
.vendorAlert-imgContainer {
    max-width: 30%;
}
.vendorAlert-img {
    max-width: 100%;
    height: auto;
}
.vendorAlert-body {
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: 13.5px;
    font-weight: 200;
    line-height: 1.45;

    p {
        margin: 0;
    }
}

#page-details .vendorAlert {
    margin: 0;
}
#page-details .vendorAlert-innerWrapper {
    margin-bottom: 10px;
}

.pickup-only-warning {
    text-align: center;
    border-radius: 3px;
    margin-left: 15px;
    background-color: #fffbe8 !important;
    border: solid 1px #ddc337 !important;
    padding: 10px 0 10px 0px !important;
    color: #555;
    font-size: 13px;
    min-height: 36px;
}
.order_delivery table.pickup-only-warning {
    display: table;
    margin: 0;
    width: 100%;
    line-height: 1.42857143;
}

.pickup-only-warning-alert {
    height: 26px;
    font-weight: 200 !important;
    margin-left: 14px;
}

.pickup-only-warning td {
    vertical-align: middle;
}

.pickup-only-text {
    font-weight: 200 !important;
    padding: 12px 8px;
}

.hidden {
    display: none;
}

@media (min-width: 420px) {
    .vendorAlert-body {
        font-size: 15.5px;
    }
}